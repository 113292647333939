import React, { useContext, useEffect, useState } from "react";
import { Alert, Table } from "../../layout";
import { Box, IconButton, Tooltip, Button } from "@mui/material";
import { EditRounded, VisibilityRounded } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../App";
import { connect } from "react-redux";
import * as actions from "../../api/actions/pos";
import { controller, endpoints } from "../../api/actions/api";
import { CSVLink } from "react-csv";
import { useTranslation } from "react-i18next";

const Suppliers = (props) => {
  const { _getById } = props;
  const navigate = useNavigate();
  const { setLoader, selStore, org } = useContext(AppContext);
  const { t } = useTranslation();
  const [tblData, setTblData] = useState([]);
  const [csvHeader, setCsvHeader] = useState([]);
  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    title: "",
    subTitle: "",
  });

  useEffect(() => {
    setCsvHeader([
      { key: "cstmrCode", label: t("customerCode") },
      { key: "firstName", label: t("firstName") },
      { key: "lastName", label: t("lastName") },
      { key: "phone", label: t("phone") },
      { key: "email", label: t("email") },
      { key: "storeCredit", label: t("storeCredit") },
      { key: "hasCard", label: t("hasCard") },
    ]);

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const onComplete = (res) => {
      res && res.status === 200 && setTblData([...res.result]);
      setLoader(false);
    };

    setLoader(true);
    _getById(
      endpoints.Supplier + controller.GetByStore,
      selStore,
      null,
      onComplete
    );
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selStore]);

  const handleAddEdit = (id) => {
    navigate(`supplier-form/${id || ""}`);
  };

  const handleView = (id) => {
    navigate(`/view-supplier/${id}`);
  };

  const ActionComp = (val) => (
    <div className="bs-secondary br-8 w-fit">
      <Tooltip title={t("view")}>
        <IconButton
          onClick={() => handleView(val.id)}
          size="small"
          color="primary"
          className="mr-5 p-5"
        >
          <VisibilityRounded className="fs-18 m-2" />
        </IconButton>
      </Tooltip>
      <Tooltip title={t("edit")}>
        <IconButton
          onClick={() => handleAddEdit(val.id)}
          size="small"
          color="primary"
          className="mr-5 p-5"
        >
          <EditRounded className="fs-18 m-2" />
        </IconButton>
      </Tooltip>
    </div>
  );
  const HeaderComp = () => (
    <div className="d-flex flex-wrap g-5 w-100pr">
      <Button
        onClick={() => handleAddEdit()}
        className="btn-secondary"
        sx={{
          width: { md: "180px", xs: "100%" },
          fontSize: "17px",
          padding: "8px",
        }}
      >
        {t("addSupplier")}
      </Button>
      <Box
        className="d-flex"
        sx={{
          width: { xs: "100%", md: "auto" },
        }}
      >
        <CSVLink
          data={tblData.map((j) => ({
            ...j,
            hasCard: j.cardNumber ? t("yes") : t("no"),
          }))}
          headers={csvHeader}
          filename={`${t("suppliers")} (${new Date().toDateString()}).csv`}
          target="_blank"
          className="d-flex a-center j-center p-15 fs-16 fw-5 tx-upp primary-color bg-primarySM br-15 w-100pr"
        >
          {t("exportCSV")}
        </CSVLink>
      </Box>
    </div>
  );

  return (
    <>
      <Table
        ActionComp={ActionComp}
        HeaderComp={HeaderComp}
        tblHead={[
          { id: "actions", label: t("actions") },
          { id: "splr", label: t("supplier") },
          { id: "contact", label: t("contact") },
          { id: "due", label: t("due") },
        ]}
        tblData={tblData.map((j) => {
          var dueAmt =
            j.purchases &&
            j.purchases
              .map((x) => x.paymentDue)
              .reduce((x, y) => x + y, 0);
          return {
            ...j,
            splrId: `Id: ${j.id}`,
            splr: (
              <div className="d-flex g-15 a-center mb-5 mt-5">
                <div className="d-grid">
                  <span className="">{j.company}</span>
                  <span className="fs-12">
                    {j.firstName + " " + j.lastName} | Id: {j.id}
                  </span>
                </div>
              </div>
            ),
            due: (
              <span
                className={`fs-18 fw-5 ${
                  dueAmt > 0 ? "error-color" : "success-color"
                }`}
              >
                {org.curSymbol + " " + dueAmt.toLocaleString()}
              </span>
            ),
            contact: (
              <div className="d-grid ">
                {j.email && (
                  <a
                    className="fs-14 primary-color c-pointer mb-3"
                    href={`mailto:${j.email}`}
                  >
                    ✉️ {j.email}
                  </a>
                )}
                <a
                  className="fs-14 primary-color c-pointer"
                  href={`tel:1955206146`}
                >
                  📞 {j.phone}
                </a>
              </div>
            ),
            hasCard: (
              <span className="fw-5">{j.cardNumber ? t("yes") : t("no")}</span>
            ),
          };
        })}
      />

      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapActionToProps = {
  _getById: actions.getById,
  _delete: actions.Delete,
};

export default connect(mapStateToProps, mapActionToProps)(Suppliers);
