import React, { useContext, useEffect } from "react";
import { ClearRounded, EditRounded } from "@mui/icons-material";
import { Button, Grid, IconButton, Paper, Tooltip } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import { connect } from "react-redux";
import { Alert, Dialog, Table } from "../../layout";
import * as actions from "../../api/actions/pos";
import { controller, endpoints } from "../../api/actions/api";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import AddEditCurrRate from "./AddEditCurrRate";
import { AppContext } from "../../App";

const CurrRates = (props) => {
  const { _getById, _delete } = props;
  const { t } = useTranslation();
  const { org } = useContext(AppContext);
  const { id } = useParams();
  const [currDtls, setCurrDtls] = useState();
  const [rates, setRates] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [updData, setUpdData] = useState();
  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    title: "",
    subTitle: "",
  });

  useEffect(() => {
    currDtls &&
      _getById(
        endpoints.CurrRate + controller.GetAllByCurr,
        currDtls.id,
        null,
        (res) => setRates([...res.result])
      );

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currDtls]);

  useEffect(() => {
    id &&
      _getById(endpoints.Currency + controller.GetById, id, null, (res) =>
        setCurrDtls(res.result)
      );
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleAddEdit = (val) => {
    setUpdData(val);
    setShowForm(true);
  };

  const handleDlt = (id) => {
    setAlert({
      isOpen: true,
      type: "confirmation",
      title: t("areYouSure"),
      subTitle: t("catDltTitle"),
      onConfirm: () => {
        const onComplete = (res) => {
          if (res.status === 200) {
            setRates((x) => [...x.filter((j) => j.id !== res.result.id)]);
            setAlert({
              isOpen: true,
              type: "success",
              title: t("dltSuccessTitle"),
              subTitle: res.msg,
            });
          } else {
            setAlert({
              isOpen: true,
              type: "warning",
              title: t("warning"),
              subTitle: res.msg,
            });
          }
        };
        _delete(endpoints.CurrRate, id, null, onComplete);
      },
    });
  };

  const ActionComp = (props) => (
    <Box className="d-flex a-center g-10">
      <Tooltip arrow title={t("edit")}>
        <IconButton
          onClick={() => handleAddEdit(props)}
          size="small"
          className="primary-color"
        >
          <EditRounded className="fs-18 m-2" />
        </IconButton>
      </Tooltip>
      <Tooltip arrow title={t("delete")}>
        <IconButton
          onClick={() => handleDlt(props.id)}
          size="small"
          color="error"
        >
          <ClearRounded className="fs-18 m-2" />
        </IconButton>
      </Tooltip>
    </Box>
  );

  const HeaderComp = () => (
    <Button
      onClick={() => handleAddEdit()}
      className="btn-secondary"
      sx={{
        width: { md: "180px", xs: "100%" },
        mb: { xs: "10px", md: "0px" },
        fontSize: "17px",
        padding: "8px",
      }}
    >
      {t("newRate")}
    </Button>
  );

  return (
    <>
      {/* Currency Details */}

      {currDtls && (
        <>
          <Paper className="pt-5 bg-white shadow-xs p-15 mb-30">
            <Grid container spacing={3}>
              <Grid item xs={12} md={4} className="d-grid">
                <span className="tx-xs tx-upp mb-5 gray-color">
                  {t("status")}:
                </span>
                <div className="tx-upp fs-12 fw-5">
                  {currDtls.isActive ? (
                    <span className="primary-color bg-primarySM pl-10 pr-10 pt-5 pb-5 br-15">
                      Active
                    </span>
                  ) : (
                    <span className="dark-color bg-graySM pl-10 pr-10 pt-5 pb-5 br-15">
                      Inactive
                    </span>
                  )}
                </div>
              </Grid>
              <Grid item xs={12} md={4} className="d-grid">
                <span className="tx-xs tx-upp mb-5 gray-color">
                  {t("currency")}:
                </span>
                <span className="fs-16 fw-4">{currDtls.currency}</span>
              </Grid>

              <Grid item xs={12} md={4} className="d-grid">
                <span className="tx-xs tx-upp mb-5 gray-color">
                  {t("symbol")}:
                </span>
                <span className="fs-16 fw-4">{currDtls.symbol}</span>
              </Grid>
              <Grid item xs={12} md={4} className="d-grid">
                <span className="tx-xs tx-upp mb-5 gray-color">
                  {t("name")}:
                </span>
                <span className="fs-16 fw-4">{currDtls.name}</span>
              </Grid>
              <Grid item xs={12} md={4} className="d-grid">
                <span className="tx-xs tx-upp mb-5 gray-color">
                  {t("currencyUnit")}:
                </span>
                <span className="fs-16 fw-4">{currDtls.currUnit}</span>
              </Grid>
              <Grid item xs={12} md={4} className="d-grid">
                <span className="tx-xs tx-upp mb-5 gray-color">
                  {t("currencySubunit")}:
                </span>
                <span className="fs-16 fw-4">{currDtls.currSubunit}</span>
              </Grid>
            </Grid>
          </Paper>

          <Table
            ActionComp={ActionComp}
            HeaderComp={HeaderComp}
            tblHead={[
              { id: "actions", label: t("actions") },
              { id: "addDate", label: t("date") },
              { id: "amt", label: `${org.curName} to ${currDtls.currency}` },
            ]}
            tblData={rates.map((j) => ({
              ...j,
              amt: currDtls.symbol + " " + j.rate,
            }))}
          />
        </>
      )}

      {/* Rate */}

      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />

      {/* Edit Dialog */}
      {showForm && (
        <Dialog show={showForm} onClose={false} maxWidth="sm">
          <AddEditCurrRate
            {...{ currDtls, updData, setShowForm, setRates, setAlert }}
          />
        </Dialog>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapActionToProps = {
  _getById: actions.getById,
  _delete: actions.Delete,
  _clear: actions.clear,
};

export default connect(mapStateToProps, mapActionToProps)(CurrRates);
