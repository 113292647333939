import React, { useContext } from "react";
import { Grid, Paper, Typography } from "@mui/material";
import { SearchBox } from "./";
import * as actions from "../../api/actions/pos";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../App";

const Products = (props) => {
  const { _list, _type, cartItems, setCartItems } = props;
  const { t } = useTranslation();
  const { org } = useContext(AppContext);
  const prodCls = {
    borderTopLeftRadius: "0.8rem",
    borderTopRightRadius: "0.8rem",
    height: "150px",
    width: "100%",
    objectFit: "cover",
    borderBottom: "1px solid var(--grayLight-color)",
  };

  const handleCart = (prod) => {
    const cartVal = cartItems.find((j) => j.productId === prod.product.id);
    if (
      prod.inInventory > 0 &&
      (cartVal === undefined || prod.inInventory > cartVal.qty)
    ) {
      cartVal === undefined
        ? setCartItems((x) => [
            {
              productId: prod.product.id,
              name: prod.product.name,
              price: prod.product.sellPrice,
              tax: prod.product.tax,
              qty: 1,
              itemType: 1,
            },
            ...x,
          ])
        : setCartItems((x) => [
            ...x.map((j) =>
              j.productId === prod.product.id
                ? { ...j, qty: parseInt(j.qty) + 1 }
                : j
            ),
          ]);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <SearchBox type="product" handleCart={handleCart} />
      </Grid>
      {_type === "inventories" &&
        _list.map((inv, i) => (
          <Grid item xs={6} sm={3} xl={2} key={i}>
            <Paper
              onClick={() => handleCart(inv)}
              className="br-8 shadow-xs c-pointer"
            >
              <img
                style={prodCls}
                className="img-fluid obj-contain"
                src={
                  inv.product.imgs.length > 0
                    ? inv.product.imgs[0].imgUrl
                    : `${process.env.PUBLIC_URL}/assets/no-pictures.png`
                }
                alt="prod-img"
              />
              <Typography
                variant="subtitle2"
                sx={{ fontSize: "12px" }}
                className="p-5 gray-color"
              >
                {inv.product.name}
                <div className="d-block">
                  <span className="fs-12 fw-6">
                    {t("price")}:{" "}
                    {(org && org.curSymbol) + " " + inv.product.sellPrice}
                  </span>
                  &nbsp;||&nbsp;
                  <span
                    className={`fs-12 fw-6 ${
                      inv.inInventory <= 0 && " error-color"
                    }`}
                  >
                    {t("inStore")}: {inv.inInventory}
                  </span>
                </div>
              </Typography>
            </Paper>
          </Grid>
        ))}
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  _list: state.pos.list,
  _type: state.pos.type,
  _authUser: state.pos.authUser,
  _store: state.pos.defStore,
});

const mapActionToProps = {
  _setVal: actions.clear,
};

export default connect(mapStateToProps, mapActionToProps)(Products);
