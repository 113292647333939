import { Grid, IconButton } from "@mui/material";
import React, { useState } from "react";
import { Button, Input } from "../../controls";
import { Form } from "../../layout";
import { connect } from "react-redux";
import * as actions from "../../api/actions/pos";
import { useTranslation } from "react-i18next";
import { controller, endpoints } from "../../api/actions/api";
import { CloseRounded } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const ReturnDialog = (props) => {
  const { setShowForm, _getById } = props;
  const { t } = useTranslation();
  const [value, setValue] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (value) {
      setLoading(true);
      _getById(endpoints.Sell + controller.GetById, value, null, (res) => {
        setLoading(false);
        if (res.result) {
          navigate(`/sell_return/${res.result.id}`);
        } else setError(t("invalidInv"));
      });
    } else setError(t("invNoIsReq"));
  };

  const handleClose = () => {
    setShowForm(false);
  };

  const handleInpChg = (e) => {
    setValue(e.target.value);
  };

  return (
    <>
      <Form noValidate onSubmit={handleSubmit}>
        <Grid container spacing={2} className="p-15 bg-grayXS">
          <Grid item xs={12}>
            <div className="d-flex a-center j-between">
              <div>
                <span className="fs-20 gray-color fw-5 tx-upp border-left">
                  {t("sellReturn")}
                </span>
              </div>
              <IconButton onClick={handleClose} size="small">
                <CloseRounded />
              </IconButton>
            </div>
          </Grid>

          <Grid item xs={12}>
            <Input
              label={t("invoiceNo")}
              name="invNo"
              type="number"
              value={value}
              error={error}
              onChange={handleInpChg}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} className="tx-right">
            <Button
              type="submit"
              size="large"
              variant="text"
              loading={loading}
              sx={{ minWidth: "150px" }}
            >
              {t("send")}
            </Button>
          </Grid>
        </Grid>
      </Form>
    </>
  );
};
const mapStateToProps = (state) => ({});

const mapActionToProps = {
  _getById: actions.getById,
};

export default connect(mapStateToProps, mapActionToProps)(ReturnDialog);
