import React from "react";
import { useTranslation } from "react-i18next";
import { Grid, Paper } from "@mui/material";

const SplrDetails = ({ values }) => {
  const { t } = useTranslation();

  return (
    <Paper className="pt-5 bg-white shadow-xs">
      {values ? (
        <Grid container spacing={3} className="p-15 dark-color mb-10">
          <Grid item xs={12} md={4} className="d-grid">
            <span className="tx-xs tx-upp mb-5 gray-color">
              {t("supplierId")}:
            </span>
            <span className="fs-16 fw-4">{values.id}</span>
          </Grid>
          <Grid item xs={12} md={4} className="d-grid">
            <span className="tx-xs tx-upp mb-5 gray-color">
              {t("companyName")}:
            </span>
            <span className="fs-16 fw-4">{values.company}</span>
          </Grid>
          <Grid item xs={12} md={4} className="d-grid">
            <span className="tx-xs tx-upp mb-5 gray-color">
              {t("supplierName")}:
            </span>
            <span className="fs-16 fw-4">
              {values.firstName + " " + values.lastName}
            </span>
          </Grid>
          <Grid item xs={12} md={4} className="d-grid">
            <span className="tx-xs tx-upp mb-5 gray-color">{t("phone")}:</span>
            <a
              className="link fs-16 fw-4 gray-color"
              href={`tel:${values.phone}`}
            >
              {values.phone}
            </a>
          </Grid>
          <Grid item xs={12} md={4} className="d-grid">
            <span className="tx-xs tx-upp mb-5 gray-color">{t("email")}:</span>
            <a
              className="link fs-16 fw-4 gray-color"
              href={`mailto:${values.email}`}
            >
              {values.email}
            </a>
          </Grid>
          <Grid item xs={12}>
            <div className="d-flex a-center mt-20">
              <span
                className="gray-color tx-upp fw-5 fs-14"
                style={{ minWidth: "70px" }}
              >
                {t("address")}:
              </span>
              <div className="bs-secondary w-100pr" />
            </div>
          </Grid>
          <Grid item xs={12} md={4} className="d-grid">
            <span className="tx-xs tx-upp mb-5 gray-color">
              {t("address1")}:
            </span>
            <span className="fs-16 fw-4">{values.address1}</span>
          </Grid>
          <Grid item xs={12} md={4} className="d-grid">
            <span className="tx-xs tx-upp mb-5 gray-color">
              {t("address2")}:
            </span>
            <span className="fs-16 fw-4">{values.address2}</span>
          </Grid>
          <Grid item xs={12} md={4} className="d-grid">
            <span className="tx-xs tx-upp mb-5 gray-color">{t("city")}:</span>
            <span className="fs-16 fw-4">{values.city}</span>
          </Grid>
          <Grid item xs={12} md={4} className="d-grid">
            <span className="tx-xs tx-upp mb-5 gray-color">{t("state")}:</span>
            <span className="fs-16 fw-4">{values.state}</span>
          </Grid>
          <Grid item xs={12} md={4} className="d-grid">
            <span className="tx-xs tx-upp mb-5 gray-color">{t("ZIP")}:</span>
            <span className="fs-16 fw-4">{values.zip}</span>
          </Grid>
          <Grid item xs={12}>
            <div className="d-flex a-center mt-20">
              <span
                className="gray-color tx-upp fw-5 fs-14"
                style={{ minWidth: "150px" }}
              >
                {t("additionalDetails")}
              </span>
              <div className="bs-secondary w-100pr" />
            </div>
          </Grid>
          <Grid item xs={12} md={6} className="d-grid">
            <span className="tx-xs tx-upp mb-5 gray-color">
              {t("productSale")}:
            </span>
            <span className="fs-16 fw-4">{values.productSale}</span>
          </Grid>
          <Grid item xs={12} md={6} className="d-grid">
            <span className="tx-xs tx-upp mb-5 gray-color">{t("note")}:</span>
            <span className="fs-16 fw-4">{values.note}</span>
          </Grid>
        </Grid>
      ) : (
        "Loading..."
      )}
    </Paper>
  );
};

export default SplrDetails;
