import React, { useContext, useEffect, useState } from "react";
import MultCurr from "../../multCurrency/MultCurr";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../../App";
import { InputCheck } from "../../../controls";
import { Divider } from "@mui/material";

const PaymentsSect = ({ needToPayAmt, values, setValues, handleInpCheck }) => {
  const { t } = useTranslation();
  const { org } = useContext(AppContext);
  const [multPays, setMultPays] = useState([]);

  useEffect(() => {
    var _totalMultPayAmt = multPays
      .map((x) => parseFloat(x.mainAmt))
      .reduce((x, y) => x + y, 0);
    setValues((j) => ({
      ...j,
      multPays: multPays,
      multiPayAmt: _totalMultPayAmt,
    }));

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [multPays]);

  const Header = () => (
    <div className="ml-10 ">
      {values.change <= 0 ? (
        <div className="gray-color tx-upp d-flex a-center">
          <span className="fs-18">
            {t("amountToPay")}: &nbsp;&nbsp;
            {org.curSymbol}&nbsp;
          </span>
          <span className="fs-24 fw-6">
            {parseFloat(needToPayAmt).toLocaleString("en-US", {
              maximumFractionDigits: 2,
            })}
          </span>
        </div>
      ) : (
        <div>
          <div className="gray-color tx-upp d-flex a-center">
            <span className="fs-18">
              {t("change")}: &nbsp;&nbsp;
              {org.curSymbol}&nbsp;
            </span>
            <span className="fs-24 fw-6">
              {parseFloat(values.change).toLocaleString("en-US", {
                maximumFractionDigits: 2,
              })}
            </span>
          </div>
          {values.cstmrId && values.change > 0 && (
            <>
              <Divider />
              <InputCheck
                label={t("keepChgAsCredit")}
                size="small"
                className="gray-color"
                onChange={handleInpCheck}
                name="changeAddedCredit"
                checked={values.changeAddedCredit}
              />
            </>
          )}
        </div>
      )}
    </div>
  );

  return (
    <>
      {org && values ? (
        <MultCurr
          {...{
            multPays,
            setMultPays,
            totalAmt: needToPayAmt,
            isCustomeHeader: true,
            Header,
          }}
        />
      ) : (
        "Loading..."
      )}
    </>
  );
};

export default PaymentsSect;
