import React, { useContext } from "react";
import ReactApexChart from "react-apexcharts";
import { Box, Paper, Typography } from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../App";
import { connect } from "react-redux";
import * as actions from "../../api/actions/pos";
import { controller, endpoints } from "../../api/actions/api";
import { ToFormData } from "../../hooks/Method";
import moment from "moment";

const SalesByEmployee = ({ _getByObj }) => {
  const { t } = useTranslation();
  const { org, selStore } = useContext(AppContext);

  const [sellItems, setSellItems] = useState([]);

  useEffect(() => {
    selStore &&
      _getByObj(
        endpoints.HomeReport + controller.SellsByEmployee,
        ToFormData({
          storeId: selStore,
          date: moment().format("MM/YYYY"),
        }),
        null,
        (res) => {
          res.status === 200 && setSellItems([...res.result]);
        }
      );

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selStore]);

  const options = {
    chart: {
      type: "donut",
    },
    plotOptions: {
      pie: {
        startAngle: -90,
        endAngle: 270,
      },
    },
    labels: sellItems.map(
      (j) => j[0].user.firstName + " " + j[0].user.lastName
    ),
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: "gradient",
    },
    legend: {
      formatter: function (val, opts) {
        return (
          val +
          " - " +
          (org && org.curSymbol) +
          opts.w.globals.series[opts.seriesIndex]
        );
      },
      show: true,
      position: "bottom",
      horizontalAlign: "bottom",
      fontSize: "15px",
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: "100%",
          },
        },
      },
    ],
  };

  const series = sellItems.map((j) =>
    j.map((j) => Math.round(j.qty * j.price)).reduce((x, y) => x + y, 0)
  );

  return (
    <Paper className="p-15">
      {/* Chart */}
      <Typography varint="h6" className="fs-18 fw-5 gray-color mb-20">
        {t("salesByEmployees")}
      </Typography>
      <Box>
        <ReactApexChart
          options={options}
          series={series}
          type="donut"
          height={330}
        />
      </Box>
    </Paper>
  );
};

const mapStateToProps = (state) => ({
  _authUser: state.pos.authUser,
  _defStore: state.pos.defStore,
});

const mapActiontoProps = { _getByObj: actions.getByObj };

export default connect(mapStateToProps, mapActiontoProps)(SalesByEmployee);
