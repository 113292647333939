import { Grid, InputAdornment } from "@mui/material";
import React, { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { connect } from "react-redux";
import { ButtonGroup, Input, InputAuto } from "../../../controls";
import * as actions from "../../../api/actions/pos";
import { DropLi, Model, UseForm } from "../../../hooks";
import { controller, endpoints } from "../../../api/actions/api";
import { NestedFormData, PrintDiv, RmvEmptyProp } from "../../../hooks/Method";
import { AppContext } from "../../../App";
import { useTranslation } from "react-i18next";
import CustomerSect from "./CustomerSect";
import PaymentsSect from "./PaymentsSect";

const Checkout = (props) => {
  const {
    subTotal,
    cartItems,
    totalPay,
    setTotalPay,
    tax,
    setReceptData,
    handleClearCart,
    _post,
    _authUser,
    _getById,
    setAlert,
    setTax,
    setTaxPct,
  } = props;
  const { selStore, stores, org, setPageTitle } = useContext(AppContext);
  const { SellMdl } = Model();
  const { t } = useTranslation();
  const { DiscountTypes } = DropLi();
  const {
    values,
    errors,
    setErrors,
    handleInpChg,
    handleInpCheck,
    handleInpDate,
    setValues,
  } = UseForm(SellMdl);
  const [checkoutAmt, setCheckoutAmt] = useState(totalPay || 0);
  const [needToPayAmt, setNeedToPayAmt] = useState(totalPay || 0);
  const [loading, setLoading] = useState(false);
  const [store, setStore] = useState({});
  const [payMethods, setPayMethods] = useState([]);

  useEffect(() => {
    org.id &&
      _getById(
        endpoints.PayMethod + controller.GetAllByOrg,
        org.id,
        null,
        (res) =>
          setPayMethods([...res.result.map((j) => ({ ...j, label: j.title }))])
      );

    setPageTitle(t("checkout"));
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [org]);

  // Get Selected or Default store details
  useEffect(() => {
    setStore(stores.find((j) => j.id === selStore));
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selStore]);

  // Define default data.
  useEffect(() => {
    setValues((j) => ({
      ...j,
      storeId: selStore,
      sellItems: cartItems,
      taxPct: store ? store.tax : 0,
      tax: tax,
      totalPay: checkoutAmt,
      received: "",
      discount: "",
      subtotal: subTotal,

      userId: _authUser.id,
      paymentType: 1,
    }));

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkoutAmt, store]);

  // =======** Calculate Total Amount to Pay **=======
  useEffect(() => {
    var _multPayAmt = parseFloat(values.multiPayAmt || 0);
    var _disAmt = parseFloat(values.disAmt || 0);
    var _dueAmt = parseFloat(values.dueAmt || 0);
    var _creditAmt = parseFloat(values.creditAmt || 0);
    var _received = parseFloat(values.received || 0);

    var _leftAmt = (
      totalPay -
      (_multPayAmt + _disAmt + _dueAmt + _creditAmt + _received)
    ).toFixed(2);
    setNeedToPayAmt(_leftAmt);

    setValues((j) => ({
      ...j,
      totalPay: totalPay - (_disAmt + _dueAmt).toFixed(2),
      change: Math.abs(_leftAmt < 0 ? _leftAmt : 0),
    }));

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    totalPay,
    values.multiPayAmt,
    values.disAmt,
    values.dueAmt,
    values.creditAmt,
    values.received,
  ]);

  // Calculate Discount Amount
  useEffect(() => {
    if (values.discountType === 1) {
      setValues((j) => ({
        ...j,
        disAmt: (parseFloat(totalPay) * parseFloat(values.discount || 0)) / 100,
      }));
    } else if (values.discountType === 2)
      setValues((j) => ({ ...j, disAmt: j.discount }));

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.discountType, values.discount]);

  const validateForm = () => {
    let temp = {};
    temp.dueDate =
      values.sellType === 2 && !values.dueDate ? t("dueDateIsRequired") : "";
    temp.dueAmt =
      values.sellType === 2 && !parseFloat(values.dueAmt || 0)
        ? t("dueAmtIsReq")
        : "";
    temp.received = needToPayAmt > 0 && 1 < needToPayAmt ? t("payMoreAmt") : "";

    setErrors({ ...temp });
    return Object.values(temp).every((x) => x === "");
  };

  const handleSubmit = () => {
    // **** Submit Action ****
    if (values.sellItems.length > 0 && validateForm()) {
      setLoading(true);
      const onComplete = (res) => {
        setLoading(true);
        if (res.status === 200) {
          setReceptData(res.result);
          setTimeout(() => {
            PrintDiv("printSellRecept");
            handleClearCart();
          }, 1500);
        } else {
          setLoading(false);
          setAlert({
            isOpen: true,
            type: "warning",
            title: t("warning"),
            subTitle: res.msg,
          });
        }
      };
      // Differential Amount
      if (needToPayAmt < 1 && needToPayAmt > 0) values.diffAmt = needToPayAmt;
      values.sellItems = values.sellItems.map((j) =>
        j.itemType === 2 ? { ...j, productId: "" } : j
      );
      _post(
        endpoints.Sell,
        NestedFormData(RmvEmptyProp(values)),
        null,
        onComplete
      );
    } else if (values.sellItems.length <= 0) {
      setAlert({
        isOpen: true,
        type: "warning",
        title: t("warning"),
        subTitle: t("noItemInCart"),
      });
    }
  };

  const handleDisType = (val) => {
    setValues((x) => ({ ...x, discountType: val }));
  };

  console.log("values: ", values);

  return (
    <>
      <Grid container spacing={1}>
        {/* Customer Loyalty Section */}
        <Grid item xs={12}>
          <CustomerSect
            {...{
              checkoutAmt,
              values,
              setValues,
              errors,
              handleInpChg,
              handleInpDate,
              handleSubmit,
              loading,
              setTax,
              store,
              totalPay,
              setTotalPay,
              setTaxPct,
            }}
          />
        </Grid>

        {/* Payments Section  */}
        <Grid item xs={12}>
          <PaymentsSect
            {...{
              totalPay,
              checkoutAmt,
              setCheckoutAmt,
              values,
              setValues,
              handleInpCheck,
              needToPayAmt,
            }}
          />
        </Grid>

        {/* Additional Discount */}
        <Grid item xs={12} md={5} className="d-flex a-center">
          <span className="fs-16 fw-5 tx-upp gray-color">
            {t("additionalDiscount")}
          </span>
        </Grid>

        <Grid item xs={12} md={7} className="mb-10 d-flex">
          <Input
            fullWidth
            placeholder={t("enterAmount")}
            name="discount"
            value={values.discount}
            onInput={(e) => (e.target.value > 0 ? e : (e.target.value = ""))}
            onChange={(e) =>
              (parseFloat(e.target.value) <= checkoutAmt ||
                values.discount.length > e.target.value.length) &&
              handleInpChg(e)
            }
            className="bg-white rmv-arrow br-8"
            variant="outlined"
            type="number"
            size="small"
            onClick={(e) => e.target.select()}
          />
          <ButtonGroup
            activeVal={values.discountType || 0}
            onChange={handleDisType}
            list={DiscountTypes}
          />
        </Grid>

        {/* Payment Received */}
        <Grid item xs={12} md={5} className="d-flex a-center">
          <span className="fs-24 tx-upp fw-5 gray-color">
            {t("paymentReceived")}
          </span>
        </Grid>

        <Grid item xs={12} md={7}>
          <div className="d-flex g-5">
            <Input
              fullWidth
              placeholder={t("enterAmount")}
              name="received"
              value={values.received}
              error={errors.received}
              onChange={handleInpChg}
              className="bg-white rmv-arrow br-8"
              variant="outlined"
              size="small"
              type="number"
              onInput={(e) => (e.target.value > 0 ? e : (e.target.value = ""))}
              onClick={(e) => e.target.select()}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <span className="fs-18"> {org && org.curSymbol}</span>
                  </InputAdornment>
                ),
              }}
            />
            <InputAuto
              variant="outlined"
              name="payMethodId"
              size="small"
              value={values.payMethodId}
              error={errors.payMethodId}
              onChange={handleInpChg}
              options={payMethods}
              sx={{ minWidth: "130px" }}
            />
          </div>
        </Grid>

        {/* Note */}
        <Grid item xs={12} className="mt-10">
          <Input
            fullWidth
            placeholder={t("keepNoteIfYouWant")}
            name="note"
            value={values.note}
            onChange={handleInpChg}
            className="bg-white rmv-arrow br-8"
            variant="outlined"
            multiline
            rows={3}
          />
        </Grid>
      </Grid>
    </>
  );
};
const mapStateToProps = (state) => ({
  _type: state.pos.type,
  _store: state.pos.defStore,
  _authUser: state.pos.authUser,
});

const mapActionToProps = {
  _post: actions.post,
  _getById: actions.getById,
};

export default connect(mapStateToProps, mapActionToProps)(Checkout);
