import { Divider, Grid, Paper } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Button, Input, InputAuto } from "../../controls";
import { Model, UseForm } from "../../hooks";
import { Alert, Form } from "../../layout";
import { Currencies } from "../../hooks/Currencies";
import { Countries } from "../../hooks/Countries";
import { AppContext } from "../../App";
import { RmvEmptyProp, ToFormData } from "../../hooks/Method";
import { connect } from "react-redux";
import { controller, endpoints } from "../../api/actions/api";
import * as actions from "../../api/actions/pos";
import { useTranslation } from "react-i18next";
import InputSwitch from "../../controls/InputSwitch";

const OrgForm = (props) => {
  const { _put, _getById } = props;
  const { t } = useTranslation();
  const { OrgMdl } = Model();
  const { org, setOrg } = useContext(AppContext);
  const {
    values,
    setValues,
    errors,
    setErrors,
    handleInpChg,
    resetFormControls,
    handleInpCheck,
  } = UseForm(OrgMdl);
  const [currs, setCurrs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    title: "",
    subTitle: "",
  });

  useEffect(() => {
    if (org && org.id) {
      setValues((x) => ({ ...x, ...RmvEmptyProp(org) }));
      _getById(
        endpoints.Currency + controller.GetAllByOrg,
        org.id,
        null,
        (res) =>
          setCurrs([
            ...res.result.map((j) => ({
              ...j,
              label: `${j.currency} (${j.symbol}) - ${j.name}`,
            })),
          ])
      );
    }
    return () => {
      resetFormControls();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [org]);

  const validateForm = () => {
    let temp = {};
    temp.name = values.name !== "" ? "" : t("nameIsRequired");
    temp.email = values.email !== "" ? "" : t("emailIsRequired.");
    temp.phone = values.phone !== "" ? "" : t("phoneIsRequired.");
    temp.currId = values.currId !== "" ? "" : t("currencyIsRequired.");

    setErrors({ ...temp });
    return Object.values(temp).every((x) => x === "");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      const onComplete = (res) => {
        if (res && res.status === 200) {
          if (res.result) {
            setValues((x) => ({ ...x, ...res.result }));
            // Update Global Value
            var _org = res.result;
            var currency = Currencies.find((j) => j.id === _org.currency);
            setOrg({
              ..._org,
              curSymbol: currency && currency.symbol_native,
            });
          }
          setAlert({
            isOpen: true,
            type: "success",
            title: t("success"),
            subTitle: t("organizationDetailsUpdatedSuccessfully"),
          });
        } else
          setAlert({
            isOpen: true,
            type: "error",
            title: t("error"),
            subTitle: res.msg,
          });
        setLoading(false);
      };
      _put(endpoints.Org, ToFormData(RmvEmptyProp(values)), null, onComplete);
    }
  };

  return (
    <>
      <Form onSubmit={handleSubmit} noValidate>
        <Paper>
          <div className="mb-10 d-flex j-between a-center pt-10 pl-10 pr-10">
            <div>
              <h1 className="form-title ml-10 fw-4 gray-color tx-upp border-left">
                {t("editOrganizationDetails")}
              </h1>
            </div>

            <div>
              <Button type="submit" loading={loading}>
                {t("saveChanges")}
              </Button>
            </div>
          </div>
          <Divider />
          <div className="pl-15 pr-15 pb-15 mt-30">
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Input
                  label={t("organizationName")}
                  name="name"
                  required={true}
                  value={values.name}
                  error={errors.name}
                  onChange={handleInpChg}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Input
                  label={t("localeOrgName")}
                  name="nameLocale"
                  value={values.nameLocale}
                  error={errors.nameLocale}
                  onChange={handleInpChg}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Input
                  label={t("email")}
                  name="email"
                  required={true}
                  value={values.email}
                  error={errors.email}
                  onChange={handleInpChg}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Input
                  label={t("phone")}
                  name="phone"
                  required={true}
                  value={values.phone}
                  error={errors.phone}
                  onChange={handleInpChg}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <InputAuto
                  label={t("country")}
                  name="country"
                  value={values.country}
                  error={errors.country}
                  onChange={handleInpChg}
                  options={Countries}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Input
                  label={t("city")}
                  name="city"
                  value={values.city}
                  error={errors.city}
                  onChange={handleInpChg}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Input
                  label={t("state")}
                  name="state"
                  value={values.state}
                  error={errors.state}
                  onChange={handleInpChg}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Input
                  label={t("ZIP")}
                  name="zip"
                  value={values.zip}
                  error={errors.zip}
                  onChange={handleInpChg}
                />
              </Grid>
              <Grid item xs={12}>
                <Input
                  label={t("address")}
                  name="address"
                  multiline
                  rows={2}
                  value={values.address}
                  error={errors.address}
                  onChange={handleInpChg}
                />
              </Grid>
              <Grid item xs={12} className="mt-30">
                <div className="d-flex a-center g-15">
                  <div>
                    <span className="border-left tx-upp fs-20 gray-color">
                      Currency
                    </span>
                  </div>

                  <div
                    style={{
                      width: "100%",
                      border: "2px solid var(--graySM-color)",
                      borderRadius: "30px",
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <InputAuto
                  label={t("currency")}
                  name="currId"
                  required={true}
                  value={values.currId}
                  error={errors.currId}
                  onChange={handleInpChg}
                  options={currs}
                />
              </Grid>
              <Grid item xs={12} md={6} className="d-flex a-center">
                <InputSwitch
                  label="Active Multicurrency"
                  name="enableMultCurr"
                  checked={values.enableMultCurr}
                  onChange={handleInpCheck}
                />
              </Grid>
            </Grid>
          </div>
        </Paper>
      </Form>
      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />
    </>
  );
};

const mapStateToProps = (state) => ({
  _authUser: state.pos.authUser,
});

const mapActionToProps = {
  _put: actions.put,
  _getById: actions.getById,
};

export default connect(mapStateToProps, mapActionToProps)(OrgForm);
