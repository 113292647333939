import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  lng: "en", // Default language
  resources: {
    en: {
      translation: {
        taxExempt: "Tax Exempt",
        cedulaRIFCode: "Cedula RIF code",
        vatTax: "VAT TAX",
        specialTaxpayer: "Special Taxpayer",
        purchaseReturns: "Purchase Returns",
        printReceipt: "Print Receipt",
        revDiscount: "Reverse Discount",
        refundTax: "Refund Tax",
        taxAndDiscount: "Tax & Discount",
        sellable: "Sellable",
        unsellable: "Unsellable",
        totalReturn: "Total Return",
        cashierAndRefundDate: "Cashier & Refund Date",
        totalSellable: "Total Sellable",
        totalUnsellable: "Total Unsellable",
        totalInvoiceAmount: "Total Invoice Amount",
        sellReturns: "Sell Returns",
        returnId: "Return Id",
        refundAmount: "Refund Amount",
        receiptOrDue: "Receipt / Due",
        sellReturnId: "Sell Return Id",
        refundDate: "Refund Date",
        youWantToDoThisAction:
          "You want to do this action. This is not reversible.",
        payableRefund: "Payable Refund",
        refundTaxAmt: "Refund Tax Amount",
        minusDiscountAmount: "Minus Discount Amount",
        isSellable: "Is Sellable",
        returnQty: "Return Qty",
        returnQuantity: "Return Quantity",
        returnSubtotal: "Return Subtotal",
        sellQuantity: "Sell Quantity",
        businessLocation: "Business Location",
        creditAndDebit: "Credit / Debit",
        youHaveSuccessfullyMadeThePayment:
          "You have successfully made the payment",
        multiCurrencyPayment: "Multi-Currency Payment",
        received: "Received",
        totalReceived: "Total Received",
        invoiceAmt: "Invoice Amount",
        payAmount: "Pay Amount",
        totalBill: "Total Bill",
        supplierDetails: "Supplier Details",
        totalPayableAmt: "Total Payable Amount",
        paidDate: "Paid Date",
        totalInvAmt: "Total Invoice Amount",
        paidAmtIsReq: "Paid Amount is Required",
        status: "Status",
        supplierId: "Supplier Id",
        dueAmtIsReq: "Due Amount is Required",
        plzEnterDueAmt: "Please Enter Due Amount",
        apply: "Apply",
        multiPayments: "Multi-Payments",
        leaveNotes: "Leave Notes...",
        closeReg: "Close Register",
        totalMultCurrency: "Total Multi-Currency",
        inMultCurrency: "In Multi-Currency",
        expenseDetails: "Expense Details",
        totalPaid: "Total Paid",
        multiCurrencies: "Multi-Currencies",
        paidAmt: "Paid Amount",
        multiCurrency: "Multi-Currency",
        paymentDueReadonly: "Payment Due (Readonly)",
        nextPayDate: "Next Pay Date",
        totalShiftTransactionsBalance: "Total Shift Transactions Balance",
        paymentMethod: "Payment Method",
        youHaveSuccessfullyDeletedThePayMethod:
          "You have successfully deleted the payment method",
        createDate: "Create Date",
        updateDate: "Update Date",
        title: "Title",
        subtitle: "Subtitle",
        createdUser: "Created User",
        titleIsReq: "Title is required",
        addPaymentMethod: "Add Payment Method",
        method: "Method",
        methodDltTitle: "You want to delete this method",
        payMethodIsReq: "Payment method is required",
        keepChgAsCredit: "Keep Change as Credit",
        payMoreAmt: "You have to pay more amount",
        remainingAmt: "Remaining Amount",
        totalDiffAmt: "Total Differential Amount",
        diffAmt: "Differential Amount",
        creditBalance: "Credit Balance",
        addtlCharge: "Additional Charge",
        addtlChargeTitle: "Additional Charge Title",
        charge: "Charge",
        addtlChargeIsReq: "Charge is required",
        addtlChargeTitleIsReq: "Title is required",
        sellReturn: "Sell Return",
        sellReturnForm: "Sell Return Form",
        invNoIsReq: "Invoice number is required",
        invalidInv: "Invalid Invoice Number",
        selectReason: "Select Reason",
        mindChange: "Mind Change",

        localAmount: "Local Amount",
        summary: "Summary",
        ok: "ok",
        dataNotAvailable: "Data Not Available",
        multPay: "Multi-Currency Pay",
        multPayment: "Multi-Currency Pay",
        pleaseEnterDueDate: "Please enter due date",
        pleaseFirstSelectAmt: "Please First Select Amount",
        amountToBePaid: " Amount to be Paid",
        multcurrency: "Mult-Currency",
        paidLocal: "Paid Local",
        addPayment: "Add Payment",
        rate: "Rate",
        currentRate: "Current Rate",
        rateIsRequired: "Rate is required",
        addDateIsReq: "Add Date is Required",
        thisFieldIsReq: "This field is required",
        newRate: "New Rate",
        currencyIsRequired: "Currency is required",
        symbolIsRequired: "Symbol is required",
        currencies: "Currencies",
        addCurrency: "Add Currency",
        symbol: "Symbol",
        lastUpdate: "Last Update",
        currencyUnit: "Currency Unit",
        currencySubunit: "Currency Subunit",
        usedCredit: "Used Credit",

        sale: "Sale",
        keepNoteIfYouWant: "Keep note if you want...",
        addChangeAsCreditBalance: "Add Change as Credit Balance",
        additionalDiscount: "Additional Discount",
        amountToPay: "Amount to Pay",
        enterAmount: "Enter Amount",
        useCredit: "Use Credit",
        storeCredit: "Store Credit",
        selectCustomer: "Select Customer",
        dueDateIsRequired: "Due date is required",
        cstmrNotHaveAccBal: "The customer doesn't have account balance.",
        pleaseFirstSelectCustomer: "Please first select customer.",
        checkout: "Checkout",
        dueAmountIsRequired: "Due amount is required",
        nextDueDateIsRequired: "Next due date is required",
        youHavePutedWrongAmount: "You have puted wrong amount",
        dueAmount: "Due Amount",
        details: "Details",
        receipt: "Receipt",
        transaction: "Transaction",
        settings: "Settings",
        loading: "Loading",
        account: "Account",
        customerId: "Customer Id",
        accountBalanceType: "Account Balance Type",
        id: "Id",
        importExcel: "Import Excel",
        exportCSV: "Export CSV",
        cstmrDltTitle: "You want to delete this customer profile?",
        customer: "Customer",
        customers: "Customers",
        addCustomer: "Add Customer",
        yesWithNoBalanceLimit: "Yes, With no balance limit",
        yesButWithABalanceLimit: "Yes, But with a balance limit",
        customerCode: "Customer Code",
        hasCard: "Has Card",
        contact: "Contact",
        card: "Credit/Debit Card",
        generalFields: "General Fields",
        addressFields: "Address Fields",
        additionalFields: "Additional Fields",
        generateCustomerId: "Generate Customer Id",
        allowAnOnAccountBalance: "Allow an on account balance",
        balanceLimit: "Balance Limit",
        country: "Country",
        address1: "Address Line 1",
        address2: "Address Line 2",
        twitter: "Twitter",
        website: "Website",
        profilePicture: "Profile Picture",
        nameOnCard: "Name on Card",
        cardNumber: "Card Number",
        expiryDate: "Expiry Date",
        securityCode: "Security Code",
        balance: "Balance",
        totalSpent: "Total Spent",
        last12Months: "Last 12 months",
        history: "History",
        customerScince: "Customer scince",
        personalDetails: "Personal Details",
        birthday: "Birthday",
        yearsOld: "Years old",
        cardPayment: "Card",
        debit: "Debit",
        accountBalance: "Account Balance:",
        accounts: "Accounts",
        dueReceipt: "Due Receipt",
        sellType: "Sell Type",
        totalPayment: "Total Payment",
        dueDate: "Due Date",
        makePayment: "Make Payment",

        //===============
        totalSales: "Total Sales",
        sales: "Sales",
        sells: "Sells",
        purchase: "Purchase",
        purchases: "Purchases",
        purchasesReturn: "Purchases Return",
        suppliers: "Suppliers",
        purchaseProducts: "Purchase Products",
        addNewProduct: "Add New Product",
        profit: "Profit",
        expense: "Expense",
        monthlySalesReports: "Monthly Sales Reports",
        yearlySales: "Yearly Sales",
        monthlySales: "Monthly Sales",
        weeklySales: "Weekly Sales",
        dailySales: "Daily Sales",
        numberOfItemSold: "Number of item sold",
        sellPrice: "Sell Price",
        purchasePrice: "Purchase Price",
        totalExpense: "Total Expense",
        profitBreakdown: "Profit Breakdown",
        purchaseSellsReports: "Purchase & Sells Reports",
        salesByEmployees: "Sales By Employees",
        storeExpenses: " Store Expenses",
        inStoreInventoryValue: "In Store Inventory Value",
        top20SellingProducts: "Top 20 Selling Products",
        productName: "Product Name",
        unitSell: "Unit Sell",
        unitCost: "Unit Cost",
        unitProfit: "Unit Profit",
        totalOnStock: "Total On Stock",
        top10ProfitMakingItems: "Top 10 Profit Making Items",
        inventoryValue: "Inventory Value",
        inInventory: "In Inventory",
        newStockIsRequired: "New Stock is required.",
        quantity: "Quantity",
        subtotal: "Subtotal",
        unitPrice: "Unit Price",
        store: "Store",
        updated: "Updated",
        added: "Added",
        printBarcode: "Print Barcode",
        edit: "Edit",
        delete: "Delete",
        addProduct: "Add Product",
        generateBarcode: "Generate barcode",
        actions: "Actions",
        name: "Name",
        barcode: "Barcode",
        category: "Category",
        subcategory: "Subcategory",
        print: "Print",
        addNew: "Add New",
        categoryName: "Category Name",
        seeYouAgain: "See you again!",
        thanksForYourPurchase: "Thanks for your purchase",
        qty: "Qty",
        unitType: "Unit Type",
        tax: "Tax",
        totalPay: "Total Pay",
        pay: "Pay",
        paymentReceived: "Payment Received",
        now: "Now",
        profitMargin: "Profit Margin",
        alertQty: "Alert Qty",
        nameIsRequired: "Name is required.",
        barcodeIsRequired: "Barcode is required.",
        unitTypeIsTequired: "Unit type is required.",
        taxIsRequired: "Tax is required.",
        purchasePriceIsIequired: "Purchase Price is required.",
        sellPriceIsRequired: "Sell Price is required.",
        productDescription: "Product Description",
        productImages: "Product Images",
        categoryNameIsRequired: "Category Name is required.",
        productDetails: "Product Details",
        companyName: "Company Name",
        expenseNameIsRequired: "Expense Name is required.",
        expenseCategory: "Expense Category",
        expenseName: "Expense Name",
        expenseDescription: "Expense Description",
        product: "Product",
        add: "Add",
        update: "Update",
        view: "View",
        close: "Close",
        saveAndExit: "Save and Exit",
        saveAndAddAnother: "Save And Add Another",
        saveChanges: "Save Changes",
        addCategory: "Add Category",
        description: "Description",
        parentCategory: "Parent Category",
        submit: "Submit",
        addInventory: "Add Inventory",
        searchByBarcode: "Search By Barcode",
        newStock: "New Stock",
        productId: "Product Id",
        inStock: "In Stock",
        addedDetails: "Added Details",
        userName: "User Name",
        inventoryAdded: "Inventory Added",
        date: "Date",
        addPurchases: "Add Purchases",
        referenceNo: "Reference No",
        supplier: "Supplier",
        totalItem: "Total Item",
        netAmount: "Net Amount",
        amount: "Amount",
        totalPurchase: "Total Purchase",
        purchaseDate: "Purchase Date",
        recept: "Recept",
        viewRecept: "View Receipt",
        paymentType: "Payment Type",
        paymentDue: "Payment Due",
        discount: "Discount",
        uploadReceipt: "Upload Receipt",
        purchaseDetails: "Purchase Details",
        supplierIsRequired: "Supplier is required.",
        paymentTypeIsRequired: "Payment type is required.",
        emptyPurchaseList: "Empty Purchase List",
        purchWarning:
          "You can't submit your request. Because your purchase list is empty.",

        searchByNameOrBarcode: "Search by Name or Barcode",
        itemName: "Item Name",
        price: "Price",
        purchaseUser: "Purchase User",
        purchaseRecept: "Purchase Recept",
        total: "Total",
        totalPrice: "Total Price",
        dateOfBirth: "Date Of Birth",
        gender: "Gender",
        phone: "Phone",
        userRole: "User Role",
        inStore: "In Store",
        role: "Role",
        isActive: "Is Active",
        login: "Login",
        back: "Back",
        password: "Password",
        verificationCode: "Verification Code",
        confirmPassword: "Confirm Password",
        forgotPassword: "Forgot Password",
        userNameIsRequired: "User Name is required",
        passwordAndConfirmPasswordNotMatch:
          "Password and Confirm password not match",
        sendVerificationCode: "Send Verification Code",
        confirmPasswordIsRequired: "Confirm password is required",
        salaryScale: "Salary Scale",
        salaryType: "Salary Type",
        changePassword: "Change Password",
        salary: "Salary",
        welcome: "Welcome",
        SSN: "SSN",
        successfullySetAsADefaultStore: "Successfully Set as a Default store",
        somethingIsWrongPleaseTryAgain: "Something is wrong. Please try again.",
        myStores: "My Stores",
        submitToDefaultStore: "Submit To Default Store",
        switchToAnotherStore: "Switch To Another Store",
        storeDltWarning:
          "Currently, you are in this store. Please first switch to another store then try again.",

        invalidReferenceNo: "Invalid Reference No",
        referenceNoRsRequired: "Reference No is required.",
        emptyReturnList: "Empty Return List",
        returnPurchWarning:
          "You can't submit your request. Because your return list is empty.",
        purchaseReturn: "Purchase Return",
        returnDate: "Return Date",
        returnPurchases: "Return Purchases",
        returnReason: "Return Reason",
        addPurchaseReturn: "Add Purchase Return",
        returnValue: "Return Value",
        returnItemDetails: "Return Item Details",
        returnUser: "Return User",
        firstNameIsRequired: "First Name is required.",
        lastNameIsRequired: "Last Name is required.",
        phoneIsRequired: "Phone is required.",
        customerCodeIsRequired: "Customer code is required.",
        companyNameIsRequired: "Company name is required.",
        emailIsNotValid: "Email is not valid.",
        emailIsRequired: "Email is required.",
        prefixIsRequired: "Prefix is required.",
        genderIsRequired: "Gender is required.",
        languageIsRequired: "Language is required.",
        roleIsRequired: "Role is required.",
        isActiveIsRequired: "Status is required.",
        passwordIsRequired: "Password is required",
        confirmPassIsRequired: "Confirm password is required",
        firstName: "First Name",
        lastName: "Last Name",
        email: "Email",
        productsSale: "Products Sale",
        city: "City",
        state: "State",
        ZIP: "ZIP",
        address: "Address",
        additionalDetails: "Additional Details",
        note: "Note",
        itsTooDangerous: "It's too dangerous",
        addSupplier: "Add Supplier",
        contractPerson: "Contract person",
        addExpense: "Add Expense",
        transectionDate: "Transection Date",
        invoice: "Invoice",
        paymenReceived: "Paymen Received",
        change: "Change",
        clear: "Clear",
        cashier: "Cashier",
        saleDetails: "Sale Details",
        amountIsRequired: "Amount is required.",
        cashInDrawer: "Cash In Drawer",
        transferDateIsRequired: "Transfer date is required.",
        fromLocationIsRequired: "From location is required.",
        toLocationIsRequired: "To location is required.",
        outOfStock: "Out Of Stock",
        outOfStockWarning:
          "You can't transfer this product. Because it's not available in your inventory.",
        stockTransfer: "Stock Transfer",
        transferDate: "Transfer Date",
        locationFrom: "Location (From)",
        locationTo: "Location (To)",
        transferDetails: "Transfer Details",
        addTransfer: "Add Transfer",
        transferItems: "Transfer Items",
        employeeName: "Employee Name",
        totalSale: "Total Sale",
        voidSale: "Void Sale",
        refund: "Refund",
        itemCancel: "Item Cancel",
        paidBy: "Paid By",
        paid: "Paid",
        detailsNote: "Details Note",
        expenseType: "Expense Type",
        lastMonthSale: "Last Month Sale",
        currentMonthSale: "Current Month Sale",
        lottery: "Lottery",

        hight: "hight",
        noTax: "No Tax",
        notes: "Notes",
        low: "Low",
        totalSold: "Total Sold",
        purchaseBy: "Purchase By",
        supplierName: "Supplier Name",
        craditSale: "Cradit Sale",
        cashSale: "Cash Sale",
        cashierName: "Cashier Name",
        closeTime: "Close Time",
        openTime: "Open Time",
        returnBy: "Return By",
        searchByMonth: "Search By Month",
        grossProfit: "Gross Profit",
        totalSellPrice: "Total Sell Price",
        totalPurchasePrice: "Total Purchase Price",
        netProfit: "Net Profit",
        grossSell: "Gross Sell",
        totalPurchese: "Total Purchese",
        totalExpences: "Total Expences",
        openingInventoryValue: "Opening Inventory Value",
        newInventoryAdded: "New Inventory Added",
        inventorySold: "Inventory Sold",
        currentInventoryValue: "Current Inventory Value",
        storeDetails: "Store Details",
        totalItemSold: "Total Item Sold",
        totalItemCancelledFromCart: "Total Item Cancelled From Cart",
        totalRefund: "Total Refund",
        totalVoidSale: "Total Void Sale",
        items: "Items",
        expenses: "Expenses",
        closeRegister: "Close Register",
        inventory: "Inventory",
        itemSold: "Item Sold",
        registerDetails: "Register Details",
        cashierDetails: "Cashier Details",
        storeName: "Store Name",
        saleDate: "Sale Date",
        timeIn: "Time In",
        expenseTypeIsRequired: "Expense type is required",
        timeOut: "Time Out",
        credit: "Credit",
        productsSoldByCategory: "Products Sold by Category",
        totalCustomers: "Total Customers",
        averagePerCustomer: "Average Per Customer",
        totalSaleRefund: "Total Sale Refund",
        totalVoidSells: "Total Void Sells",
        totalItemCanceled: "Total Item Canceled",
        saleReport: "Sale Report",
        cancel: "Cancel",
        totalCashSale: "Total Cash Sale",
        totalCreditSale: "Total Credit Sale",
        otherPaymentReceived: "Other Payment Received",
        sellDetails: "Sell Details",
        totalGrossSale: "Total Gross Sale",
        totalTaxItemSold: "Total Tax Item Sold",
        totalNonTaxItemSold: "Total Non Tax Item Sold",
        totalTaxReceived: "Total Tax Received",
        money: "Money",
        shiftTransactionsBalance: "Shift Transactions Balance",
        addUser: "Add User",
        activeStatus: "Active Status",
        userNameIsAlreadyExist: "User Name is already exist",
        user: "User",
        userInformation: "User Information",
        prefix: "Prefix",
        language: "Language",
        rolesAndPermissions: "Roles and Permissions",
        payTypeIsRequired: "Pay Type is required.",
        totalIsRequired: "Total is required.",
        bill: "Bill",
        payType: "Pay Type",
        addPayBill: "Add Pay Bill",
        hireDate: "Hire Date",
        totalSalary: "Total Salary",
        totalWorkHour: "Total Work Hour",
        payPeriod: "Pay Period",
        fromDateIsRequired: "From-Date is required.",
        toDateIsRequired: "To-Date is required.",
        totalSalaryIsRequired: "Total salary is required.",
        payDateIsRequired: "Pay-Date is required.",
        salaryPaymentForm: "Salary Payment Form",
        errors: "Errors",
        searchEmployeeByNameOrPhone: "Search Employee by Name or Phone",
        fromDate: "From Date",
        toDate: "To Date",
        totalHours: "Total Hours",
        payDate: "Pay Date",
        allItems: "All Items",
        storeNameIsRequired: "Store Name is required.",
        taxPercentageIsRequired: "Tax percentage is required.",
        managerDetails: "Manager Details",
        taxPercentage: "Tax Percentage",
        logo: "Logo",
        addStore: "Add Store",
        managerName: "Manager Name",
        organizationDetailsUpdatedSuccessfully:
          "Organization Details Updated Successfully",
        editOrganizationDetails: "Edit Organization Details",
        organizationName: "Organization Name",
        localeOrgName: "Locale Org Name",
        currency: "Currency",
        notFound: "Not Found",
        dashboard: "Dashboard",
        products: "Products",
        categories: "Categories",
        storeReport: "Store Report",
        productReport: "Product Report",
        purchaseReport: "Purchase Report",
        registerReport: "Register Report",
        expenseReport: "Expense Report",
        return: "Return",
        topSelling: "Top Selling",
        employeeReport: "Employee Report",
        productsSellVolume: "Products Sell Volume",
        HR: "HR",
        payBills: "Pay Bills",
        salaryPayment: "Salary Payment",
        salaryHistory: "Salary History",
        employeeHistory: "Employee History",
        reports: "Reports",
        master: "Master",
        organization: "Organization",
        defaultStore: "Default Store",
        POSItem: "POS Item",
        logout: "Logout",
        invoiceNo: "Invoice No",
        POS: "POS",
        sellDate: "Sell Date",
        verificationCodeIsRequired: "Verification code is required.",
        enterVerificationCode: "Enter Verification Code",
        resendCode: "Resend Code",

        verificationCodeSentTitle:
          "The verification code has been resent successfully.",
        storeDltTitle:
          "Would you really want to delete this store? If you delete this store then all data in this store will be deleted.",
        successfully: "Successfully",
        enter: "Enter",
        voidSell: "Void Sell",
        printLastSale: "Print Last Sale",
        home: "Home",
        successful: "Successful",
        youHaveSuccessfullyVoidThisSale:
          "You have successfully void this sale.",
        youWantToVoidThisSell: "You want to void this sell?",
        thereIsNoLastSaleData: "There is no last-sale data.",
        cashRegisterReport: "Cash Register Report",
        others: "Others",
        detailsOfProductsSold: "Details of Products Sold",
        invoiceID: "Invoice Id",
        refundNow: "Refund Now",
        enterNameOrScanBarcode: "Enter name or scan barcode",
        error: "Error",
        verify: "Verify",
        warning: "Warning",
        success: "Success",
        youHaveSuccessfullyRefund: "You have successfully refund.",
        wrongAmount:
          "You have entered the wrong amount. Please enter the correct amount.",
        paymentFieldRequired: "Payment received field is required",
        regCloseWarning:
          "You can't close your register. Because you can't open your register.",
        noItemInCart:
          "There are no items in the cart. Please first add some items to the cart.",
        regClosedSuccessTitle: "You have successfully closed your register.",
        areYouSure: "Are you sure!",
        dltTitle: "You want to delete this record?",
        prodDltTitle: "You want to delete this product?",
        dltSuccessTitle: "Deleted Successfully",
        prodAddTitle: "The product has been successfully added.",
        catDltTitle: "You want to delete this category?",
        invAddedTitle: "Inventory added successfully.",
        invProdError:
          "There are no products availale. Please find a product first.",
        productNotFound: "Product not found.",
        splrDltTitle:
          "Would you really want to delete this supplier? If you delete this supplier then all the reference purchases will delete.",
        stockTransferDltTitle: "You want to delete this transfer record?",
        userDltUser: "You want to delete this user?",
        warningMsg: "Something is wrong. Please try again.",
        userNotSelectedError:
          "There is no user selected. Please, First select a user.",
        userDltTitle: "You want to delete this user?",
        userDltSuccessTitle: "You have successfully deleted the user",
        frgtCodeSentTitle:
          "6 digit verification code has been sent to this email",
        refundInvIdReq: "Invoice ID is required. Please enter the invoice ID.",

        // Dropdown List
        male: "Male",
        female: "Female",
        other: "Other",
        today: "Today",
        thisWeek: "This Week",
        thisMonth: "This Month",
        thisYear: "This Year",
        box: "Box",
        piece: "Piece",
        fullPaid: "Full Paid",
        due: "Due",
        cash: "cash",
        yes: "Yes",
        no: "No",
        dateExpire: "Date Expire",
        noSale: "No Sale",
        extra: "Extra",
        damage: "Damage",
        badItem: "Bad Item",
        mr: "Mr.",
        mrs: "Mrs.",
        miss: "Miss",
        daily: "Daily",
        monthly: "Monthly",
        yearly: "Yearly",
        admin: "Admin",
        manager: "Manager",
        brands: "Brands",
      },
    },
    bn: {
      translation: {
        multPay: "মাল্টি-কারেন্সি পে",
        multPayment: "মাল্টি-কারেন্সি পে",
        pleaseEnterDueDate: "দয়া করে নির্ধারণ তারিখ লিখুন",
        pleaseFirstSelectAmt: "দয়া করে প্রথমে পরিমাণ নির্বাচন করুন",
        amountToBePaid: "পরিশোধ করতে মোট পরিমাণ",
        multcurrency: "মাল্টি-কারেন্সি",
        paidLocal: "স্থানীয় পেমেন্ট",
        addPayment: "পেমেন্ট যোগ করুন",
        rate: "হার",
        currentRate: "বর্তমান হার",
        rateIsRequired: "হার প্রয়োজন",
        addDateIsReq: "তারিখ যোগ করা প্রয়োজন",
        thisFieldIsReq: "এই ক্ষেত্রটি প্রয়োজন",
        newRate: "নতুন হার",
        currencyIsRequired: "মুদ্রা প্রয়োজন",
        symbolIsRequired: "প্রতীক প্রয়োজন",
        currencies: "মুদ্রা",
        addCurrency: "মুদ্রা যোগ করুন",
        symbol: "প্রতীক",
        lastUpdate: "সর্বশেষ হালনাগাদ",
        currencyUnit: "মুদ্রা একক",
        currencySubunit: "মুদ্রা সাবইউনিট",
        sale: "বিক্রয়",
        keepNoteIfYouWant: "আপনি চাইলে নোট রাখতে পারেন...",
        addChangeAsCreditBalance: "ক্রেডিট ব্যালেন্স হিসেবে পরিবর্তন করুন",
        additionalDiscount: "অতিরিক্ত ডিসকাউন্ট",
        amountToPay: "প্রদানের পরিমাণ",
        enterAmount: "পরিমাণ লিখুন",
        useCredit: "ক্রেডিট ব্যবহার করুন",
        storeCredit: "স্টোর ক্রেডিট",
        selectCustomer: "কাস্টমার নির্বাচন করুন",
        dueDateIsRequired: "মেয়াদ তারিখ প্রয়োজন",
        cstmrNotHaveAccBal: "কাস্টমারের একাউন্ট ব্যালেন্স নেই।",
        pleaseFirstSelectCustomer: "দয়া করে প্রথমে কাস্টমার নির্বাচন করুন।",
        checkout: "চেকআউট",
        dueAmountIsRequired: "বাকি পরিমাণ প্রয়োজন",
        nextDueDateIsRequired: "পরবর্তী মেয়াদ তারিখ প্রয়োজন",
        youHavePutedWrongAmount: "আপনি ভুল পরিমাণ দিয়েছেন",
        dueAmount: "বাকি পরিমাণ",
        details: "বিস্তারিত",
        receipt: "রসিদ",
        transaction: "লেনদেন",
        settings: "সেটিংস",
        loading: "লোড হচ্ছে",
        account: "হিসাব",
        customerId: "কাস্টমার আইডি",
        accountBalanceType: "হিসাব ব্যালেন্স টাইপ",
        id: "আইডি",
        importExcel: "ইমপোর্ট করুন Excel",
        exportCSV: "এক্সপোর্ট করুন CSV",
        cstmrDltTitle: "আপনি কি এই কাস্টমার প্রোফাইলটি মুছতে চান?",
        customer: "কাস্টমার",
        customers: "কাস্টমারগণ",
        addCustomer: "কাস্টমার যোগ করুন",
        yesWithNoBalanceLimit: "হ্যাঁ, কোন ব্যালেন্স সীমা ছাড়া",
        yesButWithABalanceLimit: "হ্যাঁ, তবে একটি ব্যালেন্স সীমা থাকতে হবে",
        customerCode: "কাস্টমার কোড",
        hasCard: "কার্ড আছে",
        contact: "যোগাযোগ",
        card: "ক্রেডিট/ডেবিট কার্ড",
        generalFields: "সাধারিত ক্ষেত্র",
        addressFields: "ঠিকানা ক্ষেত্র",
        additionalFields: "অতিরিক্ত ক্ষেত্র",
        generateCustomerId: "কাস্টমার আইডি তৈরি করুন",
        allowAnOnAccountBalance: "একটি অ্যাকাউন্ট ব্যালেন্স অনুমতি দিন",
        balanceLimit: "ব্যালেন্স সীমা",
        country: "দেশ",
        address1: "ঠিকানা লাইন 1",
        address2: "ঠিকানা লাইন 2",
        twitter: "টুইটার",
        website: "ওয়েবসাইট",
        profilePicture: "প্রোফাইল ছবি",
        nameOnCard: "কার্ডে নাম",
        cardNumber: "কার্ড নম্বর",
        expiryDate: "মেয়াদ উত্তীর্ণের তারিখ",
        securityCode: "সিকিউরিটি কোড",
        balance: "ব্যালেন্স",
        totalSpent: "মোট খরচ",
        last12Months: "শেষ 12 মাস",
        history: "ইতিহাস",
        customerScince: "কাস্টমার সিন্স",
        personalDetails: "ব্যক্তিগত তথ্য",
        birthday: "জন্মদিন",
        yearsOld: "বছরের বয়স্ক",
        cardPayment: "কার্ড পেমেন্ট",
        debit: "ডেবিট",
        accountBalance: "হিসাব ব্যালেন্স:",
        accounts: "হিসাব",
        dueReceipt: "বাকি রসিদ",
        sellType: "বিক্রয়ের ধরণ",
        totalPayment: "মোট পেমেন্ট",
        dueDate: "বাকির তারিখ",
        makePayment: "পেমেন্ট করুন",

        totalSales: "মোট বিক্রয়",
        sales: "বিক্রয়",
        sells: "বিক্রি",
        purchase: "ক্রয়",
        purchases: "ক্রয়",
        purchasesReturn: "ক্রয় ফেরত",
        suppliers: "সরবরাহকারী",
        purchaseProducts: "পণ্য ক্রয়",
        addNewProduct: "নতুন পণ্য যোগ করুন",
        profit: "লাভ",
        expense: "ব্যয়",
        monthlySalesReports: "মাসিক বিক্রয় রিপোর্ট",
        yearlySales: "বার্ষিক বিক্রয়",
        monthlySales: "মাসিক বিক্রয়",
        weeklySales: "সাপ্তাহিক বিক্রয়",
        dailySales: "দৈনিক বিক্রয়",
        numberOfItemSold: "বিক্রিত আইটেমের সংখ্যা",
        sellPrice: "বিক্রয় মূল্য",
        purchasePrice: "ক্রয় মূল্য",
        totalExpense: "মোট ব্যয়",
        profitBreakdown: "লাভের বিবরণ",
        purchaseSellsReports: "ক্রয় এবং বিক্রয় রিপোর্ট",
        salesByEmployees: "কর্মচারীদের দ্বারা বিক্রয়",
        storeExpenses: " স্টোর ব্যয়",
        inStoreInventoryValue: "স্টোর ইনভেন্টরির মান",
        top20SellingProducts: "শীর্ষ 20 বিক্রয় করা পণ্য",
        productName: "পণ্যের নাম",
        unitSell: "ইউনিট বিক্রয়",
        unitCost: "ইউনিট মূল্য",
        unitProfit: "ইউনিট লাভ",
        totalOnStock: "মোট স্টকে",
        top10ProfitMakingItems: "শীর্ষ 10 লাভযোগ্য আইটেম",
        inventoryValue: "ইনভেন্টরি মান",
        inInventory: "ইনভেন্টরিতে",
        newStockIsRequired: "নতুন স্টক প্রয়োজন",
        quantity: "পরিমাণ",
        subtotal: "সাবটোটাল",
        unitPrice: "ইউনিট মূল্য",
        store: "স্টোর",
        updated: "হালনাগাদ",
        added: "যোগ করা হয়েছে",
        printBarcode: "বারকোড মুদ্রণ",
        edit: "সম্পাদনা",
        delete: "মুছে ফেলুন",
        addProduct: "পণ্য যোগ করুন",
        generateBarcode: "বারকোড তৈরি করুন",
        actions: "ক্রিয়াকলাপ",
        name: "নাম",
        barcode: "বারকোড",
        category: "বিভাগ",
        subcategory: "সাবক্যাটেগরি",
        print: "মুদ্রণ",
        addNew: "নতুন যোগ করুন",
        categoryName: "বিভাগের নাম",
        seeYouAgain: "আবার দেখা হবে!",
        thanksForYourPurchase: "আপনার কেনার জন্য ধন্যবাদ",
        qty: "পরিমাণ",
        unitType: "ইউনিট টাইপ",
        tax: "কর",
        totalPay: "মোট পেই",
        pay: "পে",
        paymentReceived: "পেমেন্ট প্রাপ্ত",
        now: "এখন",
        profitMargin: "লাভের মার্জিন",
        alertQty: "সতর্কতা পরিমাণ",
        nameIsRequired: "নাম প্রয়োজন",
        barcodeIsRequired: "বারকোড প্রয়োজন",
        unitTypeIsTequired: "ইউনিট প্রকার প্রয়োজন",
        taxIsRequired: "কর প্রয়োজন",
        purchasePriceIsIequired: "ক্রয় মূল্য প্রয়োজন",
        sellPriceIsRequired: "বিক্রয় মূল্য প্রয়োজন",
        productDescription: "পণ্যের বিবরণ",
        productImages: "পণ্যের চিত্র",
        categoryNameIsRequired: "বিভাগের নাম প্রয়োজন",
        productDetails: "পণ্যের বিবরণ",
        companyName: "কোম্পানি নাম",
        expenseNameIsRequired: "ব্যয়ের নাম প্রয়োজন",
        expenseCategory: "ব্যয়ের বিভাগ",
        expenseName: "ব্যয়ের নাম",
        expenseDescription: "ব্যয়ের বিবরণ",
        product: "পণ্য",
        add: "যোগ করুন",
        update: "আপডেট করুন",
        view: "দেখুন",
        close: "বন্ধ করুন",
        saveAndExit: "সংরক্ষণ এবং প্রস্থান",
        saveAndAddAnother: "সংরক্ষণ এবং আরেকটি যোগ করুন",
        saveChanges: "পরিবর্তন সংরক্ষণ করুন",
        addCategory: "বিভাগ যোগ করুন",
        description: "বিবরণ",
        parentCategory: "মূল বিভাগ",
        submit: "জমা দিন",
        addInventory: "ইনভেন্টরি যোগ করুন",
        searchByBarcode: "বারকোড দ্বারা অনুসন্ধান করুন",
        newStock: "নতুন স্টক",
        productId: "পণ্য আইডি",
        inStock: "স্টকে",
        addedDetails: "যোগ করা বিশদ",
        userName: "ব্যবহারকারীর নাম",
        inventoryAdded: "ইনভেন্টরি যোগ করা হয়েছে",
        date: "তারিখ",
        addPurchases: "ক্রয় যোগ করুন",
        referenceNo: "রেফারেন্স নম্বর",
        supplier: "সরবরাহকারী",
        totalItem: "মোট আইটেম",
        netAmount: "নেট পরিমাণ",
        amount: "পরিমাণ",
        totalPurchase: "মোট ক্রয়",
        purchaseDate: "ক্রয়ের তারিখ",
        recept: "রসীদ",
        viewRecept: "রসীদ দেখুন",
        paymentType: "পেমেন্ট টাইপ",
        paymentDue: "পেমেন্ট বাকি",
        discount: "ডিসকাউন্ট",
        uploadReceipt: "রসীদ আপলোড করুন",
        purchaseDetails: "ক্রয়ের বিবরণ",
        supplierIsRequired: "সরবরাহকারী প্রয়োজন",
        paymentTypeIsRequired: "পেমেন্ট টাইপ প্রয়োজন",
        emptyPurchaseList: "খালি ক্রয় তালিকা",
        purchWarning:
          "আপনি আপনার অনুরোধ জমা দিতে পারবেন না। কারণ আপনার ক্রয় তালিকা খালি।",

        searchByNameOrBarcode: "নাম বা বারকোড দ্বারা অনুসন্ধান করুন",
        itemName: "আইটেমের নাম",
        price: "মূল্য",
        purchaseUser: "ক্রয় ব্যবহারকারী",
        purchaseRecept: "ক্রয় রসীদ",
        total: "মোট",
        totalPrice: "মোট মূল্য",
        dateOfBirth: "জন্ম তারিখ",
        gender: "লিঙ্গ",
        phone: "ফোন",
        userRole: "ব্যবহারকারী ভূমিকা",
        inStore: "স্টোরে",
        role: "ভূমিকা",
        isActive: "সক্রিয় আছে",
        login: "লগইন",
        back: "পিছনে",
        password: "পাসওয়ার্ড",
        verificationCode: "যাচাই কোড",
        confirmPassword: "পাসওয়ার্ড নিশ্চিত করুন",
        forgotPassword: "পাসওয়ার্ড ভুলে গিয়েছেন?",
        userNameIsRequired: "ব্যবহারকারীর নাম প্রয়োজন",
        passwordAndConfirmPasswordNotMatch:
          "পাসওয়ার্ড এবং নিশ্চিত পাসওয়ার্ড মিলছে না",
        sendVerificationCode: "যাচাই কোড পাঠান",
        confirmPasswordIsRequired: "নিশ্চিত পাসওয়ার্ড প্রয়োজন",
        salaryScale: "বেতন স্কেল",
        salaryType: "বেতন প্রকার",
        changePassword: "পাসওয়ার্ড পরিবর্তন",
        salary: "বেতন",
        welcome: "স্বাগতম",
        SSN: "এসএসএন",
        successfullySetAsADefaultStore:
          "সাধারিত ডিফল্ট স্টোর হিসেবে সেট করা হয়েছে",
        somethingIsWrongPleaseTryAgain:
          "কিছু ভুল হয়েছে। দয়া করে আবার চেষ্টা করুন।",
        myStores: "আমার স্টোরগুলি",
        submitToDefaultStore: "ডিফল্ট স্টোরে জমা দিন",
        switchToAnotherStore: "অন্য স্টোরে সুইচ করুন",
        storeDltWarning:
          "বর্তমানে, আপনি এই স্টোরে আছেন। দয়া করে আগে অন্য স্টোরে সুইচ করুন এবং পরে আবার চেষ্টা করুন।",
        invalidReferenceNo: "অবৈধ রেফারেন্স নম্বর",
        referenceNoRsRequired: "রেফারেন্স নম্বর প্রয়োজন",
        emptyReturnList: "খালি রিটার্ন তালিকা",
        returnPurchWarning:
          "আপনি আপনার অনুরোধ জমা দিতে পারবেন না। কারণ আপনার রিটার্ন তালিকা খালি রয়েছে।",
        purchaseReturn: "ক্রয় ফেরত",
        returnDate: "ফেরতের তারিখ",
        returnPurchases: "ফেরত ক্রয়",
        returnReason: "ফেরতের কারণ",
        addPurchaseReturn: "ক্রয় ফেরত যোগ করুন",
        returnValue: "ফেরতের মান",
        returnItemDetails: "ফেরত আইটেমের বিবরণ",
        returnUser: "ফেরত ব্যবহারকারী",
        firstNameIsRequired: "প্রথম নাম প্রয়োজন",
        lastNameIsRequired: "পদবি প্রয়োজন",
        phoneIsRequired: "ফোন প্রয়োজন",
        companyNameIsRequired: "কোম্পানির নাম প্রয়োজন",
        emailIsNotValid: "ইমেইল বৈধ নয়",
        emailIsRequired: "ইমেইল প্রয়োজন",
        prefixIsRequired: "উপসর্গ প্রয়োজন",
        genderIsRequired: "লিঙ্গ প্রয়োজন",
        languageIsRequired: "ভাষা প্রয়োজন",
        roleIsRequired: "ভূমিকা প্রয়োজন",
        isActiveIsRequired: "স্থিতি প্রয়োজন",
        passwordIsRequired: "পাসওয়ার্ড প্রয়োজন",
        confirmPassIsRequired: "পাসওয়ার্ড নিশ্চিত করুন প্রয়োজন",
        firstName: "প্রথম নাম",
        lastName: "পদবি",
        email: "ইমেইল",
        productsSale: "পণ্য বিক্রয়",
        city: "শহর",
        state: "রাজ্য",
        ZIP: "জিপ",
        address: "ঠিকানা",
        note: "নোট",
        itsTooDangerous: "এটা খুব ভয়াঙ্কর",
        addSupplier: "সাপ্লাইয়ার যোগ করুন",
        contractPerson: "চুক্তি প্রকার",
        addExpense: "খরচ যোগ করুন",
        transectionDate: "লেনদেন তারিখ",
        invoice: "চালান",
        paymenReceived: "পেমেন্ট প্রাপ্ত",
        change: "পরিবর্তন",
        clear: "পরিষ্কার",
        cashier: "ক্যাশিয়ার",
        saleDetails: "বিক্রয় বিশদ",
        amountIsRequired: "পরিমাণ প্রয়োজন",
        cashInDrawer: "ড্রয়ায় নগদ",
        transferDateIsRequired: "স্থানান্তর তারিখ প্রয়োজন",
        fromLocationIsRequired: "স্থান হতে প্রয়োজন",
        toLocationIsRequired: "স্থান প্রয়োজন",
        outOfStock: "স্টক শেষ",
        outOfStockWarning:
          "আপনি এই পণ্যটি স্থানান্তর করতে পারবেন না। কারণ এটি আপনার ইনভেন্টরিতে পাওয়া যায়নি।",
        stockTransfer: "স্টক স্থানান্তর",
        transferDate: "স্থানান্তর তারিখ",
        locationFrom: "স্থান (থেকে)",
        locationTo: "স্থান (প্রয়োজন)",
        transferDetails: "স্থানান্তরের বিবরণ",
        addTransfer: "স্থানান্তর যোগ করুন",
        transferItems: "স্থানান্তর আইটেম",
        employeeName: "কর্মচারীর নাম",
        totalSale: "মোট বিক্রয়",
        voidSale: "ভয়ড বিক্রয়",
        refund: "ফেরত",
        itemCancel: "আইটেম বাতিল",
        paidBy: "দ্বারা প্রদান হয়েছে",
        paid: "পেইড",
        detailsNote: "বিবরণ নোট",
        expenseType: "খরচের ধরণ",
        lastMonthSale: "গত মাসের বিক্রয়",
        currentMonthSale: "বর্তমান মাসের বিক্রয়",
        lottery: "লটারি",
        hight: "উচ্চতা",
        noTax: "কোন কর নেই",
        notes: "নোট",
        low: "নিম্ন",
        totalSold: "মোট বিক্রয়",
        purchaseBy: "ক্রয় করেছে",
        supplierName: "সাপ্লায়ারের নাম",
        craditSale: "ক্রেডিট বিক্রয়",
        cashSale: "নগদ বিক্রয়",
        cashierName: "ক্যাশিয়ারের নাম",
        closeTime: "বন্ধের সময়",
        openTime: "খোলার সময়",
        returnBy: "ফিরিয়েছে",
        searchByMonth: "মাসের মাধ্যমে অনুসন্ধান করুন",
        grossProfit: "মোট লাভ",
        totalSellPrice: "মোট বিক্রয় মূল্য",
        totalPurchasePrice: "মোট ক্রয় মূল্য",
        netProfit: "নেট লাভ",
        grossSell: "মোট বিক্রয়",
        totalPurchese: "মোট ক্রয়",
        totalExpences: "মোট খরচ",
        openingInventoryValue: "খোলার ইনভেন্টরি মান",
        newInventoryAdded: "নতুন ইনভেন্টরি যোগ হয়েছে",
        inventorySold: "ইনভেন্টরি বিক্রি",
        currentInventoryValue: "বর্তমান ইনভেন্টরি মান",
        storeDetails: "স্টোরের বিবরণ",
        totalItemSold: "মোট আইটেম বিক্রি",
        totalItemCancelledFromCart: "কার্ট থেকে মোট আইটেম বাতিল",
        totalRefund: "মোট ফেরত",
        totalVoidSale: "মোট বোয়াইড বিক্রয়",
        items: "আইটেম",
        expenses: "খরচ",
        closeRegister: "রেজিস্টার বন্ধ করুন",
        inventory: "ইনভেন্টরি",
        itemSold: "আইটেম বিক্রি",
        registerDetails: "রেজিস্টার বিবরণ",
        cashierDetails: "ক্যাশিয়ারের বিবরণ",
        storeName: "স্টোরের নাম",
        saleDate: "বিক্রয়ের তারিখ",
        timeIn: "সময় ইন",
        expenseTypeIsRequired: "খরচের ধরণ প্রয়োজন",
        timeOut: "সময় আউট",
        credit: "ক্রেডিট",
        productsSoldByCategory: "বিভাগ অনুসারে বিক্রয়কৃত পণ্য",
        totalCustomers: "মোট গ্রাহক",
        averagePerCustomer: "গ্রাহক প্রতি গড়",
        totalSaleRefund: "মোট বিক্রয় ফেরত",
        totalVoidSells: "মোট বোয়াইড বিক্রয়",
        totalItemCanceled: "মোট আইটেম বাতিল",
        saleReport: "বিক্রয় রিপোর্ট",
        cancel: "বাতিল",
        totalCashSale: "মোট নগদ বিক্রয়",
        totalCreditSale: "মোট ক্রেডিট বিক্রয়",
        otherPaymentReceived: "অন্যান্য পেমেন্ট প্রাপ্ত",
        sellDetails: "বিক্রয়ের বিবরণ",
        totalGrossSale: "মোট গ্রস বিক্রয়",
        totalTaxItemSold: "মোট করযুক্ত আইটেম বিক্রয়",
        totalNonTaxItemSold: "মোট করবিহীন আইটেম বিক্রয়",
        totalTaxReceived: "মোট কর প্রাপ্ত",
        money: "টাকা",
        shiftTransactionsBalance: "শিফট লেনদেন ব্যালেন্স",
        addUser: "ব্যবহারকারী যোগ করুন",
        activeStatus: "সক্রিয় স্থিতি",
        userNameIsAlreadyExist: "ব্যবহারকারীর নাম ইতিমধ্যে বিদ্যমান",
        user: "ব্যবহারকারী",
        userInformation: "ব্যবহারকারী তথ্য",
        prefix: "উপসর্গ",
        language: "ভাষা",
        rolesAndPermissions: "ভূমিকা এবং অনুমতিসমূহ",
        payTypeIsRequired: "পে টাইপ প্রয়োজন",
        totalIsRequired: "মোট প্রয়োজন",
        bill: "বিল",
        payType: "পে টাইপ",
        addPayBill: "পে বিল যোগ করুন",
        hireDate: "নিয়োগের তারিখ",
        totalSalary: "মোট বেতন",
        totalWorkHour: "মোট কাজের ঘণ্টা",
        payPeriod: "পে পিরিয়ড",
        fromDateIsRequired: "তারিখ প্রয়োজন",
        toDateIsRequired: "তারিখ প্রয়োজন",
        totalSalaryIsRequired: "মোট বেতন প্রয়োজন",
        payDateIsRequired: "পে তারিখ প্রয়োজন",
        salaryPaymentForm: "বেতন প্রদান ফরম",
        errors: "ত্রুটি",
        searchEmployeeByNameOrPhone:
          "নাম বা ফোনের মাধ্যমে কর্মচারী অনুসন্ধান করুন",
        fromDate: "তারিখ হইতে",
        toDate: "তারিখ পর্যন্ত",
        totalHours: "মোট ঘণ্টা",
        payDate: "পে তারিখ",
        allItems: "সমস্ত আইটেম",
        storeNameIsRequired: "স্টোরের নাম প্রয়োজন",
        taxPercentageIsRequired: "কর শতাংশ প্রয়োজন",
        managerDetails: "ম্যানেজারের বিবরণ",
        taxPercentage: "কর শতাংশ",
        logo: "লোগো",
        addStore: "স্টোর যোগ করুন",
        managerName: "ম্যানেজারের নাম",
        organizationDetailsUpdatedSuccessfully:
          "প্রতিষ্ঠানের বিবরণ সফলভাবে আপডেট হয়েছে",
        editOrganizationDetails: "প্রতিষ্ঠানের বিবরণ সম্পাদনা করুন",
        organizationName: "প্রতিষ্ঠানের নাম",
        localeOrgName: "লোকেল প্রতিষ্ঠান নাম",
        currency: "মুদ্রা",
        notFound: "পাওয়া যায়নি",
        dashboard: "ড্যাশবোর্ড",
        products: "পণ্য",
        categories: "বিভাগ",
        storeReport: "স্টোর রিপোর্ট",
        productReport: "পণ্য রিপোর্ট",
        purchaseReport: "ক্রয় রিপোর্ট",
        registerReport: "রেজিস্টার রিপোর্ট",
        expenseReport: "খরচ রিপোর্ট",
        return: "ফেরত",
        topSelling: "সবচেয়ে বেশি বিক্রয়",
        employeeReport: "কর্মচারী রিপোর্ট",
        productsSellVolume: "পণ্য বিক্রয় মাত্রা",
        HR: "এইচআর",
        payBills: "বিল পরিশোধ",
        salaryPayment: "বেতন প্রদান",
        salaryHistory: "বেতন ইতিহাস",
        employeeHistory: "কর্মচারী ইতিহাস",
        reports: "রিপোর্টস",
        master: "মাস্টার",
        organization: "প্রতিষ্ঠান",
        defaultStore: "ডিফল্ট স্টোর",
        POSItem: "POS আইটেম",
        logout: "লগ আউট",
        invoiceNo: "ইনভয়েস নং",
        POS: "পস",
        sellDate: "বিক্রয়ের তারিখ",
        verificationCodeIsRequired: "যাচাই কোড প্রয়োজন",
        enterVerificationCode: "যাচাই কোড দিন",
        resendCode: "কোড পুনরায় পাঠান",
        verificationCodeSentTitle:
          "যাচাই কোডটি সফলভাবে পুনরায় প্রেরণ করা হয়েছে।",
        storeDltTitle:
          "আপনি কি সত্যিই এই স্টোরটি মুছতে চান? যদি আপনি এই স্টোরটি মুছেন তবে এই স্টোরের সমস্ত তথ্য মোছা হবে।",
        successfully: "সফলভাবে",
        enter: "প্রবেশ",
        voidSell: "বোয়াইড বিক্রয়",
        printLastSale: "সর্বশেষ বিক্রয় মুদ্রণ",
        home: "হোম",
        successful: "সফল",
        youHaveSuccessfullyVoidThisSale:
          "আপনি সফলভাবে এই বিক্রয়টি বোয়াইড করেছেন।",
        youWantToVoidThisSell: "আপনি কি এই বিক্রয়টি বোয়াইড করতে চান?",
        thereIsNoLastSaleData: "সর্বশেষ বিক্রয়ের ডেটা নেই।",
        cashRegisterReport: "নগদ রেজিস্টার রিপোর্ট",
        others: "অন্যান্য",
        detailsOfProductsSold: "বিক্রয় হয়েছে পণ্যের বিবরণ",
        invoiceID: "চালান আইডি",
        refundNow: "এখনি ফেরত নিন",
        enterNameOrScanBarcode: "নাম বা স্ক্যান বারকোড দিন",
        error: "ত্রুটি",
        verify: "যাচাই করুন",
        warning: "সতর্কতা",
        success: "সাফল্য",
        youHaveSuccessfullyRefund: "আপনি সফলভাবে ফেরত নিয়েছেন।",
        wrongAmount: "আপনি ভুল পরিমাণ দিয়েছেন। অনুগ্রহ করে সঠিক পরিমাণ দিন।",
        paymentFieldRequired: "পেমেন্ট প্রাপ্ত ক্ষেত্রটি প্রয়োজন",
        regCloseWarning:
          "আপনি আপনার রেজিস্টার বন্ধ করতে পারবেন না কারণ আপনি আপনার রেজিস্টার খোলতে পারবেন না।",
        noItemInCart:
          "কার্টে কোনও আইটেম নেই। দয়া করে প্রথমে কার্টে কিছু আইটেম যোগ করুন।",
        regClosedSuccessTitle: "আপনি সফলভাবে আপনার রেজিস্টার বন্ধ করেছেন।",
        areYouSure: "আপনি কি নিশ্চিত!",
        dltTitle: "আপনি কি এই রেকর্ডটি মুছতে চান?",
        prodDltTitle: "আপনি কি এই পণ্যটি মুছতে চান?",
        dltSuccessTitle: "সফলভাবে মোছা হয়েছে",
        prodAddTitle: "পণ্যটি সফলভাবে যোগ হয়েছে।",
        catDltTitle: "আপনি কি এই বিভাগটি মুছতে চান?",
        invAddedTitle: "ইনভেন্টরি সফলভাবে যোগ হয়েছে।",
        invProdError: "কোনও পণ্য উপলব্ধ নেই। দয়া করে প্রথমে একটি পণ্য খুঁজুন।",
        productNotFound: "পণ্য পাওয়া যায়নি।",
        splrDltTitle:
          "আপনি কি এই সরবরাহকারীটি মুছতে চান? যদি আপনি এই সরবরাহকারীটি মুছেন তবে সমস্ত সংদর্ভ ক্রয়গুলি মোছা হবে।",
        stockTransferDltTitle: "আপনি কি এই স্টক স্থানান্তর রেকর্ডটি মুছতে চান?",
        userDltUser: "আপনি কি এই ব্যবহারকারীটি মুছতে চান?",
        warningMsg: "কিছু ভুল হয়েছে। অনুগ্রহ করে আবার চেষ্টা করুন।",
        userNotSelectedError:
          "কোনও ব্যবহারকারী নির্বাচিত হয়নি। দয়া করে, প্রথমে একটি ব্যবহারকারী নির্বাচন করুন।",
        userDltTitle: "আপনি কি এই ব্যবহারকারীটি মুছতে চান?",
        userDltSuccessTitle: "আপনি সফলভাবে ব্যবহারকারীটি মোছেছেন",
        frgtCodeSentTitle: "এই ইমেলে 6 ডিজিট যাচাই কোড পাঠানো হয়েছে",
        refundInvIdReq: "চালান আইডি প্রয়োজন। দয়া করে চালান আইডি দিন।",

        // Dropdown List
        male: "পুরুষ",
        female: "মহিলা",
        other: "অন্যান্য",
        today: "আজ",
        thisWeek: "এই সপ্তাহ",
        thisMonth: "এই মাস",
        thisYear: "এই বছর",
        box: "বক্স",
        piece: "টুকরা",
        fullPaid: "সম্পূর্ণ পরিশোধ",
        due: "বাকি",
        cash: "নগদ",
        yes: "হ্যাঁ",
        no: "না",
        dateExpire: "তারিখ মেয়াদ উত্তীর্ণ",
        noSale: "কোন বিক্রয় হয়নি",
        extra: "অতিরিক্ত",
        damage: "ক্ষতি",
        badItem: "মন্দ আইটেম",
        mr: "মিস্টার",
        mrs: "মিসেস",
        miss: "মিস",
        daily: "প্রতিদিন",
        monthly: "মাসিক",
        yearly: "বার্ষিক",
        admin: "অ্যাডমিন",
        manager: "ম্যানেজার",
        brands: "ব্র্যান্ড",
      },
    },
    ar: {
      translation: {
        multPay: "الدفع بعدة عملات",
        multPayment: "الدفع بعدة عملات",
        pleaseEnterDueDate: "الرجاء إدخال تاريخ الاستحقاق",
        pleaseFirstSelectAmt: "الرجاء تحديد المبلغ أولاً",
        amountToBePaid: "المبلغ المراد دفعه",
        multcurrency: "العملة المتعددة",
        paidLocal: "الدفع المحلي",
        addPayment: "إضافة الدفع",
        rate: "السعر",
        currentRate: "السعر الحالي",
        rateIsRequired: "السعر مطلوب",
        addDateIsReq: "تاريخ الإضافة مطلوب",
        thisFieldIsReq: "هذا الحقل مطلوب",
        newRate: "سعر جديد",
        currencyIsRequired: "العملة مطلوبة",
        symbolIsRequired: "الرمز مطلوب",
        currencies: "العملات",
        addCurrency: "إضافة العملة",
        symbol: "الرمز",
        lastUpdate: "آخر تحديث",
        currencyUnit: "وحدة العملة",
        currencySubunit: "وحدة فرعية للعملة",

        sale: "البيع",
        keepNoteIfYouWant: "احتفظ بملاحظة إذا كنت ترغب...",
        addChangeAsCreditBalance: "إضافة التغيير كرصيد ائتمان",
        additionalDiscount: "خصم إضافي",
        amountToPay: "المبلغ المطلوب دفعه",
        enterAmount: "أدخل المبلغ",
        useCredit: "استخدام الائتمان",
        storeCredit: "رصيد المتجر",
        selectCustomer: "اختر العميل",
        dueDateIsRequired: "تاريخ الاستحقاق مطلوب",
        cstmrNotHaveAccBal: "العميل ليس لديه رصيد في الحساب.",
        pleaseFirstSelectCustomer: "الرجاء اختيار العميل أولاً.",
        checkout: "الدفع",
        dueAmountIsRequired: "المبلغ المستحق مطلوب",
        nextDueDateIsRequired: "تاريخ الاستحقاق التالي مطلوب",
        youHavePutedWrongAmount: "لقد وضعت مبلغًا خاطئًا",
        dueAmount: "المبلغ المستحق",
        details: "التفاصيل",
        receipt: "الإيصال",
        transaction: "المعاملة",
        settings: "الإعدادات",
        loading: "جار التحميل",
        account: "الحساب",
        customerId: "معرف العميل",
        accountBalanceType: "نوع رصيد الحساب",
        id: "المعرف",
        importExcel: "استيراد من Excel",
        exportCSV: "تصدير إلى CSV",
        cstmrDltTitle: "هل تريد حقًا حذف ملف تعريف العميل هذا؟",
        customer: "العميل",
        customers: "العملاء",
        addCustomer: "إضافة عميل",
        yesWithNoBalanceLimit: "نعم، بدون حدود رصيد",
        yesButWithABalanceLimit: "نعم، ولكن مع حد رصيد",
        customerCode: "كود العميل",
        hasCard: "يمتلك بطاقة",
        contact: "الاتصال",
        card: "بطاقة الائتمان/الخصم",
        generalFields: "الحقول العامة",
        addressFields: "حقول العنوان",
        additionalFields: "حقول إضافية",
        generateCustomerId: "إنشاء معرف العميل",
        allowAnOnAccountBalance: "السماح برصيد في الحساب",
        balanceLimit: "حد الرصيد",
        country: "الدولة",
        address1: "العنوان الأول",
        address2: "العنوان الثاني",
        twitter: "تويتر",
        website: "الموقع الإلكتروني",
        profilePicture: "صورة الملف الشخصي",
        nameOnCard: "الاسم على البطاقة",
        cardNumber: "رقم البطاقة",
        expiryDate: "تاريخ الانتهاء",
        securityCode: "رمز الأمان",
        balance: "الرصيد",
        totalSpent: "إجمالي المصاريف",
        last12Months: "آخر 12 شهراً",
        history: "التاريخ",
        customerScince: "العميل منذ",
        personalDetails: "التفاصيل الشخصية",
        birthday: "تاريخ الميلاد",
        yearsOld: "سنة",
        cardPayment: "دفع بالبطاقة",
        debit: "بطاقة الخصم",
        accountBalance: "رصيد الحساب:",
        accounts: "الحسابات",
        dueReceipt: "إيصال الديون",
        sellType: "نوع البيع",
        totalPayment: "إجمالي المدفوعات",
        dueDate: "تاريخ الاستحقاق",
        makePayment: "إجراء الدفع",

        totalSales: "إجمالي المبيعات",
        sales: "مبيعات",
        sells: "المبيعات",
        purchase: "الشراء",
        purchases: "المشتريات",
        purchasesReturn: "إرجاع المشتريات",
        suppliers: "الموردين",
        purchaseProducts: "شراء المنتجات",
        addNewProduct: "إضافة منتج جديد",
        profit: "ربح",
        expense: "نفقة",
        monthlySalesReports: "تقارير المبيعات الشهرية",
        yearlySales: "المبيعات السنوية",
        monthlySales: "المبيعات الشهرية",
        weeklySales: "المبيعات الأسبوعية",
        dailySales: "المبيعات اليومية",
        numberOfItemSold: "عدد القطع المباعة",
        sellPrice: "سعر البيع",
        purchasePrice: "سعر الشراء",
        totalExpense: "إجمالي المصروفات",
        profitBreakdown: "تفاصيل الربح",
        purchaseSellsReports: "تقارير الشراء والمبيعات",
        salesByEmployees: "المبيعات حسب الموظفين",
        storeExpenses: "مصروفات المتجر",
        inStoreInventoryValue: "قيمة المخزون في المتجر",
        top20SellingProducts: "أفضل 20 منتجًا مباعًا",
        productName: "اسم المنتج",
        unitSell: "سعر الوحدة للبيع",
        unitCost: "تكلفة الوحدة",
        unitProfit: "ربح الوحدة",
        totalOnStock: "إجمالي المخزون",
        top10ProfitMakingItems: "أفضل 10 منتجات ربحية",
        inventoryValue: "قيمة المخزون",
        inInventory: "في المخزون",
        newStockIsRequired: "المخزون الجديد مطلوب",
        quantity: "الكمية",
        subtotal: "المجموع الفرعي",
        unitPrice: "سعر الوحدة",
        store: "المتجر",
        updated: "تم التحديث",
        added: "تمت الإضافة",
        printBarcode: "طباعة الباركود",
        edit: "تعديل",
        delete: "حذف",
        addProduct: "إضافة منتج",
        generateBarcode: "إنشاء باركود",
        actions: "الإجراءات",
        name: "الاسم",
        barcode: "الباركود",
        category: "الفئة",
        subcategory: "الفئة الفرعية",
        print: "طباعة",
        addNew: "إضافة جديدة",
        categoryName: "اسم الفئة",
        seeYouAgain: "أراك مرة أخرى!",
        thanksForYourPurchase: "شكرًا لشرائك",
        qty: "الكمية",
        unitType: "نوع الوحدة",
        tax: "الضريبة",
        totalPay: "المبلغ الإجمالي",
        pay: "دفع",
        paymentReceived: "الدفع المستلم",
        now: "الآن",
        profitMargin: "هامش الربح",
        alertQty: "تحذير الكمية",
        nameIsRequired: "الاسم مطلوب",
        barcodeIsRequired: "الباركود مطلوب",
        unitTypeIsTequired: "نوع الوحدة مطلوب",
        taxIsRequired: "الضريبة مطلوبة",
        purchasePriceIsIequired: "سعر الشراء مطلوب",
        sellPriceIsRequired: "سعر البيع مطلوب",
        productDescription: "وصف المنتج",
        productImages: "صور المنتج",
        categoryNameIsRequired: "اسم الفئة مطلوب",
        productDetails: "تفاصيل المنتج",
        companyName: "اسم الشركة",
        expenseNameIsRequired: "اسم المصروف مطلوب",
        expenseCategory: "فئة المصروف",
        expenseName: "اسم المصروف",
        expenseDescription: "وصف المصروف",
        product: "المنتج",
        add: "إضافة",
        update: "تحديث",
        view: "عرض",
        close: "إغلاق",
        saveAndExit: "حفظ والخروج",
        saveAndAddAnother: "حفظ وإضافة آخر",
        saveChanges: "حفظ التغييرات",
        addCategory: "إضافة فئة",
        description: "الوصف",
        parentCategory: "الفئة الرئيسية",
        submit: "إرسال",
        addInventory: "إضافة المخزون",
        searchByBarcode: "البحث بواسطة الباركود",
        newStock: "مخزون جديد",
        productId: "معرف المنتج",
        inStock: "في المخزون",
        addedDetails: "تفاصيل المضافة",
        userName: "اسم المستخدم",
        inventoryAdded: "تمت إضافة المخزون",
        date: "التاريخ",
        addPurchases: "إضافة مشتريات",
        referenceNo: "الرقم المرجعي",
        supplier: "المورد",
        totalItem: "إجمالي العناصر",
        netAmount: "المبلغ الصافي",
        amount: "المبلغ",
        totalPurchase: "إجمالي الشراء",
        purchaseDate: "تاريخ الشراء",
        recept: "الفاتورة",
        viewRecept: "عرض الفاتورة",
        paymentType: "نوع الدفع",
        paymentDue: "الدفع المستحق",
        discount: "الخصم",
        uploadReceipt: "تحميل الفاتورة",
        purchaseDetails: "تفاصيل الشراء",
        supplierIsRequired: "المورد مطلوب",
        paymentTypeIsRequired: "نوع الدفع مطلوب",
        emptyPurchaseList: "قائمة الشراء فارغة",
        purchWarning: "لا يمكنك تقديم طلبك. لأن قائمة الشراء فارغة.",
        searchByNameOrBarcode: "البحث بالاسم أو الباركود",
        itemName: "اسم العنصر",
        price: "السعر",
        purchaseUser: "مستخدم الشراء",
        purchaseRecept: "إيصال الشراء",
        total: "الإجمالي",
        totalPrice: "السعر الإجمالي",
        dateOfBirth: "تاريخ الميلاد",
        gender: "الجنس",
        phone: "الهاتف",
        userRole: "دور المستخدم",
        inStore: "في المتجر",
        role: "الدور",
        isActive: "نشط",
        login: "تسجيل الدخول",
        back: "رجوع",
        password: "كلمة المرور",
        verificationCode: "كود التحقق",
        confirmPassword: "تأكيد كلمة المرور",
        forgotPassword: "نسيت كلمة المرور",
        userNameIsRequired: "اسم المستخدم مطلوب",
        passwordAndConfirmPasswordNotMatch:
          "كلمة المرور وتأكيد كلمة المرور غير متطابقين",
        sendVerificationCode: "إرسال كود التحقق",
        confirmPasswordIsRequired: "تأكيد كلمة المرور مطلوب",
        salaryScale: "مقياس الراتب",
        salaryType: "نوع الراتب",
        changePassword: "تغيير كلمة المرور",
        salary: "الراتب",
        welcome: "مرحبًا",
        SSN: "الرقم القومي",
        successfullySetAsADefaultStore: "تم تعيينه بنجاح كمتجر افتراضي",
        somethingIsWrongPleaseTryAgain: "هناك خطأ. يرجى المحاولة مرة أخرى.",
        myStores: "متاجري",
        submitToDefaultStore: "تقديم إلى المتجر الافتراضي",
        switchToAnotherStore: "التبديل إلى متجر آخر",
        storeDltWarning:
          "حاليًا، أنت في هذا المتجر. يرجى التبديل أولاً إلى متجر آخر ثم حاول مرة أخرى.",

        invalidReferenceNo: "رقم المرجع غير صالح",
        referenceNoRsRequired: "رقم المرجع مطلوب",
        emptyReturnList: "قائمة الإرجاع فارغة",
        returnPurchWarning: "لا يمكنك تقديم طلبك. لأن قائمة الإرجاع فارغة.",
        purchaseReturn: "إرجاع الشراء",
        returnDate: "تاريخ الإرجاع",
        returnPurchases: "إرجاع المشتريات",
        returnReason: "سبب الإرجاع",
        addPurchaseReturn: "إضافة إرجاع الشراء",
        returnValue: "قيمة الإرجاع",
        returnItemDetails: "تفاصيل العنصر المرجع",
        returnUser: "مستخدم الإرجاع",
        firstNameIsRequired: "الاسم الأول مطلوب",
        lastNameIsRequired: "الاسم الأخير مطلوب",
        phoneIsRequired: "رقم الهاتف مطلوب",
        companyNameIsRequired: "اسم الشركة مطلوب",
        emailIsNotValid: "البريد الإلكتروني غير صالح",
        emailIsRequired: "البريد الإلكتروني مطلوب",
        prefixIsRequired: "البادئة مطلوبة",
        genderIsRequired: "الجنس مطلوب",
        languageIsRequired: "اللغة مطلوبة",
        roleIsRequired: "الدور مطلوب",
        isActiveIsRequired: "الحالة مطلوبة",
        passwordIsRequired: "كلمة المرور مطلوبة",
        confirmPassIsRequired: "تأكيد كلمة المرور مطلوب",
        firstName: "الاسم الأول",
        lastName: "الاسم الأخير",
        email: "البريد الإلكتروني",
        productsSale: "مبيعات المنتجات",
        city: "المدينة",
        state: "الولاية",
        ZIP: "الرمز البريدي",
        address: "العنوان",
        note: "ملاحظة",
        itsTooDangerous: "إنه خطير للغاية",
        addSupplier: "إضافة مورد",
        contractPerson: "شخص العقد",
        addExpense: "إضافة مصروف",
        transectionDate: "تاريخ العملية",
        invoice: "الفاتورة",
        paymenReceived: "الدفع المستلم",
        change: "تغيير",
        clear: "مسح",
        cashier: "أمين الصندوق",
        saleDetails: "تفاصيل البيع",
        amountIsRequired: "المبلغ مطلوب",
        cashInDrawer: "النقود في الدرج",
        transferDateIsRequired: "تاريخ النقل مطلوب",
        fromLocationIsRequired: "من الموقع مطلوب",
        toLocationIsRequired: "إلى الموقع مطلوب",
        outOfStock: "نفاذ الكمية",
        outOfStockWarning:
          "لا يمكنك نقل هذا المنتج. لأنه غير متوفر في المخزون الخاص بك.",
        stockTransfer: "نقل المخزون",
        transferDate: "تاريخ النقل",
        locationFrom: "الموقع (من)",
        locationTo: "الموقع (إلى)",
        transferDetails: "تفاصيل النقل",
        addTransfer: "إضافة نقل",
        transferItems: "عناصر النقل",
        employeeName: "اسم الموظف",
        totalSale: "إجمالي المبيعات",
        voidSale: "إلغاء البيع",
        refund: "استرداد",
        itemCancel: "إلغاء العنصر",
        paidBy: "الدفع بواسطة",
        paid: "تم الدفع",
        detailsNote: "ملاحظات التفاصيل",
        expenseType: "نوع المصروف",
        lastMonthSale: "مبيعات الشهر الماضي",
        currentMonthSale: "مبيعات الشهر الحالي",
        lottery: "اليانصيب",
        hight: "ارتفاع",
        noTax: "بدون ضريبة",
        notes: "ملاحظات",
        low: "منخفض",
        totalSold: "الإجمالي المباع",
        purchaseBy: "الشراء بواسطة",
        supplierName: "اسم المورد",
        craditSale: "بيع بالائتمان",
        cashSale: "بيع نقدي",
        cashierName: "اسم الكاشير",
        closeTime: "وقت الإغلاق",
        openTime: "وقت الفتح",
        returnBy: "الإرجاع بواسطة",
        searchByMonth: "البحث حسب الشهر",
        grossProfit: "الربح الإجمالي",
        totalSellPrice: "إجمالي سعر البيع",
        totalPurchasePrice: "إجمالي سعر الشراء",
        netProfit: "صافي الربح",
        grossSell: "البيع الإجمالي",
        totalPurchese: "إجمالي الشراء",
        totalExpences: "إجمالي المصروفات",
        openingInventoryValue: "قيمة المخزون الابتدائي",
        newInventoryAdded: "تمت إضافة مخزون جديد",
        inventorySold: "المخزون المباع",
        currentInventoryValue: "قيمة المخزون الحالي",
        storeDetails: "تفاصيل المتجر",
        totalItemSold: "إجمالي العناصر المباعة",
        totalItemCancelledFromCart: "إجمالي العناصر الملغاة من السلة",
        totalRefund: "إجمالي المرتجع",
        totalVoidSale: "إجمالي إلغاء البيع",
        items: "العناصر",
        expenses: "المصروفات",
        closeRegister: "إغلاق الكاشير",
        inventory: "المخزون",
        itemSold: "العناصر المباعة",
        registerDetails: "تفاصيل الكاشير",
        cashierDetails: "تفاصيل الكاشير",
        storeName: "اسم المتجر",
        saleDate: "تاريخ البيع",
        timeIn: "وقت البدء",
        expenseTypeIsRequired: "نوع المصروف مطلوب",
        timeOut: "وقت الانتهاء",
        credit: "الائتمان",
        productsSoldByCategory: "المنتجات المباعة حسب الفئة",
        totalCustomers: "إجمالي العملاء",
        averagePerCustomer: "المتوسط لكل عميل",
        totalSaleRefund: "إجمالي استرداد المبيعات",
        totalVoidSells: "إجمالي إلغاء البيع",
        totalItemCanceled: "إجمالي العناصر الملغاة",
        saleReport: "تقرير البيع",
        cancel: "إلغاء",
        totalCashSale: "إجمالي المبيعات النقدية",
        totalCreditSale: "إجمالي المبيعات بالائتمان",
        otherPaymentReceived: "استلامات أخرى",
        sellDetails: "تفاصيل البيع",
        totalGrossSale: "إجمالي البيع الإجمالي",
        totalTaxItemSold: "إجمالي العناصر المباعة بالضريبة",
        totalNonTaxItemSold: "إجمالي العناصر المباعة بدون ضريبة",
        totalTaxReceived: "إجمالي الضرائب المستلمة",
        money: "المبلغ",
        shiftTransactionsBalance: "رصيد معاملات الوردية",
        addUser: "إضافة مستخدم",
        activeStatus: "حالة النشاط",
        userNameIsAlreadyExist: "اسم المستخدم موجود بالفعل",
        user: "المستخدم",
        userInformation: "معلومات المستخدم",
        prefix: "البادئة",
        language: "اللغة",
        rolesAndPermissions: "الأدوار والصلاحيات",
        payTypeIsRequired: "نوع الدفع مطلوب",
        totalIsRequired: "الإجمالي مطلوب",
        bill: "الفاتورة",
        payType: "نوع الدفع",
        addPayBill: "إضافة فاتورة دفع",
        hireDate: "تاريخ التوظيف",
        totalSalary: "إجمالي الراتب",
        totalWorkHour: "إجمالي ساعات العمل",
        payPeriod: "فترة الدفع",
        fromDateIsRequired: "تاريخ البداية مطلوب",
        toDateIsRequired: "تاريخ الانتهاء مطلوب",
        totalSalaryIsRequired: "إجمالي الراتب مطلوب",
        payDateIsRequired: "تاريخ الدفع مطلوب",
        salaryPaymentForm: "نموذج دفع الراتب",
        errors: "أخطاء",
        searchEmployeeByNameOrPhone: "البحث عن الموظف بالاسم أو الهاتف",
        fromDate: "من تاريخ",
        toDate: "إلى تاريخ",
        totalHours: "إجمالي الساعات",
        payDate: "تاريخ الدفع",
        allItems: "كل العناصر",
        storeNameIsRequired: "اسم المتجر مطلوب",
        taxPercentageIsRequired: "نسبة الضريبة مطلوبة",
        managerDetails: "تفاصيل المدير",
        taxPercentage: "نسبة الضريبة",
        logo: "شعار",
        addStore: "إضافة متجر",
        managerName: "اسم المدير",
        organizationDetailsUpdatedSuccessfully: "تم تحديث تفاصيل المنظمة بنجاح",
        editOrganizationDetails: "تعديل تفاصيل المنظمة",
        organizationName: "اسم المنظمة",
        localeOrgName: "اسم المنظمة باللغة المحلية",
        currency: "العملة",
        notFound: "غير موجود",
        dashboard: "لوحة التحكم",
        products: "المنتجات",
        categories: "الفئات",
        storeReport: "تقرير المتجر",
        productReport: "تقرير المنتج",
        purchaseReport: "تقرير الشراء",
        registerReport: "تقرير الكاشير",
        expenseReport: "تقرير المصروفات",
        return: "الإرجاع",
        topSelling: "الأكثر مبيعًا",
        employeeReport: "تقرير الموظف",
        productsSellVolume: "حجم مبيعات المنتجات",
        HR: "الموارد البشرية",
        payBills: "دفع الفواتير",
        salaryPayment: "دفع الراتب",
        salaryHistory: "تاريخ الراتب",
        employeeHistory: "تاريخ الموظف",
        reports: "التقارير",
        master: "الرئيسي",
        organization: "المنظمة",
        defaultStore: "المتجر الافتراضي",
        POSItem: "عنصر نقاط البيع",
        logout: "تسجيل الخروج",
        invoiceNo: "رقم الفاتورة",
        POS: "نقاط البيع",
        sellDate: "تاريخ البيع",
        verificationCodeIsRequired: "كود التحقق مطلوب",
        enterVerificationCode: "أدخل كود التحقق",
        resendCode: "إعادة إرسال الكود",
        verificationCodeSentTitle: "تم إعادة إرسال رمز التحقق بنجاح.",
        storeDltTitle:
          "هل ترغب حقًا في حذف هذا المتجر؟ إذا قمت بحذف هذا المتجر ، فستتم حذف جميع البيانات في هذا المتجر.",
        successfully: "بنجاح",
        enter: "إدخال",
        voidSell: "إلغاء بيع",
        printLastSale: "طباعة آخر بيع",
        home: "الرئيسية",
        successful: "ناجح",
        youHaveSuccessfullyVoidThisSale: "لقد قمت بإلغاء هذا البيع بنجاح.",
        youWantToVoidThisSell: "هل تريد إلغاء هذا البيع؟",
        thereIsNoLastSaleData: "لا توجد بيانات لآخر بيع.",
        cashRegisterReport: "تقرير الكاشير",
        others: "آخرون",
        detailsOfProductsSold: "تفاصيل المنتجات المباعة",
        invoiceID: "رقم الفاتورة",
        refundNow: "استرداد الآن",
        enterNameOrScanBarcode: "أدخل الاسم أو مسح الباركود",
        error: "خطأ",
        verify: "تحقق",
        warning: "تحذير",
        success: "نجاح",
        youHaveSuccessfullyRefund: "لقد قمت بإسترداد بنجاح.",
        wrongAmount: "لقد أدخلت المبلغ الخطأ. يرجى إدخال المبلغ الصحيح.",
        paymentFieldRequired: "حقل استلام الدفع مطلوب",
        regCloseWarning: "لا يمكنك إغلاق الكاشير. لأنه لا يمكنك فتح الكاشير.",
        noItemInCart:
          "لا توجد عناصر في السلة. يرجى إضافة بعض العناصر أولاً إلى السلة.",
        regClosedSuccessTitle: "لقد قمت بإغلاق الكاشير بنجاح.",
        areYouSure: "هل أنت متأكد!",
        dltTitle: "هل تريد حذف هذا السجل؟",
        prodDltTitle: "هل تريد حذف هذا المنتج؟",
        dltSuccessTitle: "تم الحذف بنجاح",
        prodAddTitle: "تمت إضافة المنتج بنجاح.",
        catDltTitle: "هل تريد حذف هذه الفئة؟",
        invAddedTitle: "تمت إضافة المخزون بنجاح.",
        invProdError: "لا تتوفر منتجات. الرجاء البحث عن منتج أولاً.",
        productNotFound: "المنتج غير موجود.",
        splrDltTitle:
          "هل تريد حقًا حذف هذا المورد؟ إذا قمت بحذف هذا المورد ، فسيتم حذف جميع مشتريات الإشارة.",
        stockTransferDltTitle: "هل تريد حذف هذا السجل؟",
        userDltUser: "هل تريد حذف هذا المستخدم؟",
        warningMsg: "هناك خطأ. يرجى المحاولة مرة أخرى.",
        userNotSelectedError: "لا يوجد مستخدم محدد. يرجى تحديد مستخدم أولاً.",
        userDltTitle: "هل تريد حذف هذا المستخدم؟",
        userDltSuccessTitle: "لقد قمت بحذف المستخدم بنجاح",
        frgtCodeSentTitle:
          "تم إرسال رمز التحقق المكون من 6 أرقام إلى هذا البريد الإلكتروني",
        refundInvIdReq: "رقم الفاتورة مطلوب. الرجاء إدخال رقم الفاتورة.",

        // Dropdown List
        male: "ذكر",
        female: "أنثى",
        other: "آخر",
        today: "اليوم",
        thisWeek: "هذا الأسبوع",
        thisMonth: "هذا الشهر",
        thisYear: "هذا العام",
        box: "صندوق",
        piece: "قطعة",
        fullPaid: "مدفوع بالكامل",
        due: "مستحق",
        cash: "نقداً",
        yes: "نعم",
        no: "لا",
        dateExpire: "تاريخ الانتهاء",
        noSale: "لا بيع",
        extra: "زيادة",
        damage: "تالف",
        badItem: "عنصر سيء",
        mr: "السيد",
        mrs: "السيدة",
        miss: "الآنسة",
        daily: "يوميًا",
        monthly: "شهريًا",
        yearly: "سنويًا",
        admin: "المشرف",
        manager: "مدير",
        brands: "العلامات التجارية",
      },
    },
    hi: {
      translation: {
        multPay: "मल्टी-करेंसी पे",
        multPayment: "मल्टी-करेंसी पे",
        pleaseEnterDueDate: "कृपया समय सीमा दर्ज करें",
        pleaseFirstSelectAmt: "कृपया पहले राशि का चयन करें",
        amountToBePaid: "भुगतान करने के लिए राशि",
        multcurrency: "मल्टी-करेंसी",
        paidLocal: "स्थानीय भुगतान",
        addPayment: "भुगतान जोड़ें",
        rate: "मूल्य",
        currentRate: "वर्तमान मूल्य",
        rateIsRequired: "मूल्य आवश्यक है",
        addDateIsReq: "जोड़ने की तारीख आवश्यक है",
        thisFieldIsReq: "यह क्षेत्र आवश्यक है",
        newRate: "नया मूल्य",
        currencyIsRequired: "मुद्रा आवश्यक है",
        symbolIsRequired: "प्रतीक आवश्यक है",
        currencies: "मुद्राएं",
        addCurrency: "मुद्रा जोड़ें",
        symbol: "प्रतीक",
        lastUpdate: "अंतिम अद्यतन",
        currencyUnit: "मुद्रा इकाई",
        currencySubunit: "मुद्रा उपइकाई",

        sale: "बिक्री",
        keepNoteIfYouWant: "अगर आप चाहें तो नोट रखें...",
        addChangeAsCreditBalance: "क्रेडिट बैलेंस के रूप में परिवर्तन जोड़ें",
        additionalDiscount: "अतिरिक्त छूट",
        amountToPay: "भुगतान करने के लिए राशि",
        enterAmount: "राशि दर्ज करें",
        useCredit: "क्रेडिट का उपयोग करें",
        storeCredit: "स्टोर क्रेडिट",
        selectCustomer: "ग्राहक का चयन करें",
        dueDateIsRequired: "नियत तारीख की आवश्यकता है",
        cstmrNotHaveAccBal: "ग्राहक के पास खाता शेष नहीं है।",
        pleaseFirstSelectCustomer: "कृपया पहले ग्राहक का चयन करें।",
        checkout: "चेकआउट",
        dueAmountIsRequired: "देय राशि आवश्यक है",
        nextDueDateIsRequired: "अगली देय तिथि आवश्यक है",
        youHavePutedWrongAmount: "आपने गलत राशि डाली है",
        dueAmount: "देय राशि",
        details: "विवरण",
        receipt: "रसीद",
        transaction: "लेन-देन",
        settings: "सेटिंग्स",
        loading: "लोड हो रहा है",
        account: "खाता",
        customerId: "ग्राहक आईडी",
        accountBalanceType: "खाता संतुलन प्रकार",
        id: "आईडी",
        importExcel: "एक्सेल आयात करें",
        exportCSV: "सीएसवी निर्यात करें",
        cstmrDltTitle: "क्या आप वाकई इस ग्राहक प्रोफ़ाइल को हटाना चाहते हैं?",
        customer: "ग्राहक",
        customers: "ग्राहकों",
        addCustomer: "ग्राहक जोड़ें",
        yesWithNoBalanceLimit: "हां, कोई संतुलन सीमा नहीं है",
        yesButWithABalanceLimit: "हां, लेकिन संतुलन सीमा के साथ",
        customerCode: "ग्राहक कोड",
        hasCard: "कार्ड है",
        contact: "संपर्क",
        card: "क्रेडिट/डेबिट कार्ड",
        generalFields: "सामान्य क्षेत्र",
        addressFields: "पते के क्षेत्र",
        additionalFields: "अतिरिक्त क्षेत्र",
        generateCustomerId: "ग्राहक आईडी बनाएं",
        allowAnOnAccountBalance: "खाता संतुलन की अनुमति दें",
        balanceLimit: "संतुलन सीमा",
        country: "देश",
        address1: "पता पंक्ति 1",
        address2: "पता पंक्ति 2",
        twitter: "ट्विटर",
        website: "वेबसाइट",
        profilePicture: "प्रोफाइल चित्र",
        nameOnCard: "कार्ड पर नाम",
        cardNumber: "कार्ड नंबर",
        expiryDate: "समाप्ति तिथि",
        securityCode: "सुरक्षा कोड",
        balance: "संतुलन",
        totalSpent: "कुल खर्च",
        last12Months: "पिछले 12 महीने",
        history: "इतिहास",
        customerScince: "ग्राहक से",
        personalDetails: "व्यक्तिगत विवरण",
        birthday: "जन्मदिन",
        yearsOld: "वर्ष पुराना",
        cardPayment: "कार्ड पेमेंट",
        debit: "डेबिट",
        accountBalance: "खाता संतुलन:",
        accounts: "खाताएं",
        dueReceipt: "देय रसीद",
        sellType: "बिक्री प्रकार",
        totalPayment: "कुल भुगतान",
        dueDate: "देय तिथि",
        makePayment: "भुगतान करें",

        totalSales: "कुल बिक्री",
        sales: "बिक्री",
        sells: "बिक्री",
        purchase: "खरीद",
        purchases: "खरीदें",
        purchasesReturn: "खरीद की वापसी",
        suppliers: "आपूर्तिकर्ताएँ",
        purchaseProducts: "उत्पाद खरीदें",
        addNewProduct: "नया उत्पाद जोड़ें",
        profit: "लाभ",
        expense: "व्यय",
        monthlySalesReports: "मासिक बिक्री रिपोर्टें",
        yearlySales: "वार्षिक बिक्री",
        monthlySales: "मासिक बिक्री",
        weeklySales: "साप्ताहिक बिक्री",
        dailySales: "दैनिक बिक्री",
        numberOfItemSold: "बेचे गए आइटम की संख्या",
        sellPrice: "बिक्री मूल्य",
        purchasePrice: "खरीद मूल्य",
        totalExpense: "कुल व्यय",
        profitBreakdown: "लाभ विवरण",
        purchaseSellsReports: "खरीद और बिक्री रिपोर्टें",
        salesByEmployees: "कर्मचारियों के द्वारा बिक्री",
        storeExpenses: "स्टोर व्यय",
        inStoreInventoryValue: "स्टोर में इन्वेंटरी मूल्य",
        top20SellingProducts: "शीर्ष 20 बिक्री विक्रेता",
        productName: "उत्पाद का नाम",
        unitSell: "यूनिट बिक्री",
        unitCost: "यूनिट लागत",
        unitProfit: "यूनिट लाभ",
        totalOnStock: "कुल स्टॉक पर",
        top10ProfitMakingItems: "शीर्ष 10 लाभदायक आइटम",
        inventoryValue: "इन्वेंटरी मूल्य",
        inInventory: "इन्वेंटरी में",
        newStockIsRequired: "नया स्टॉक आवश्यक है।",
        quantity: "मात्रा",
        subtotal: "कुल योग",
        unitPrice: "यूनिट मूल्य",
        store: "स्टोर",
        updated: "अपडेट किया गया",
        added: "जोड़ा गया",
        printBarcode: "बारकोड प्रिंट करें",
        edit: "संपादित करें",
        delete: "हटाएं",
        addProduct: "उत्पाद जोड़ें",
        generateBarcode: "बारकोड बनाएं",
        actions: "क्रियाएँ",
        name: "नाम",
        barcode: "बारकोड",
        category: "श्रेणी",
        subcategory: "उप-श्रेणी",
        print: "प्रिंट",
        addNew: "नया जोड़ें",
        categoryName: "श्रेणी का नाम",
        seeYouAgain: "फिर मिलें!",
        thanksForYourPurchase: "आपकी खरीद के लिए धन्यवाद",
        qty: "मात्रा",
        unitType: "इकाई प्रकार",
        tax: "कर",
        totalPay: "कुल भुगतान",
        pay: "भुगतान",
        paymentReceived: "भुगतान प्राप्त",
        now: "अब",
        profitMargin: "लाभ मार्जिन",
        alertQty: "चेतावनी मात्रा",
        nameIsRequired: "नाम आवश्यक है।",
        barcodeIsRequired: "बारकोड आवश्यक है।",
        unitTypeIsTequired: "इकाई प्रकार आवश्यक है।",
        taxIsRequired: "कर आवश्यक है।",
        purchasePriceIsIequired: "खरीद मूल्य आवश्यक है।",
        sellPriceIsRequired: "बिक्री मूल्य आवश्यक है।",
        productDescription: "उत्पाद विवरण",
        productImages: "उत्पाद छवियाँ",
        categoryNameIsRequired: "श्रेणी का नाम आवश्यक है।",
        productDetails: "उत्पाद विवरण",
        companyName: "कंपनी का नाम",
        expenseNameIsRequired: "व्यय का नाम आवश्यक है।",
        expenseCategory: "व्यय श्रेणी",
        expenseName: "व्यय का नाम",
        expenseDescription: "व्यय विवरण",
        product: "उत्पाद",
        add: "जोड़ें",
        update: "अपडेट",
        view: "देखें",
        close: "बंद करें",
        saveAndExit: "सहेजें और बाहर निकलें",
        saveAndAddAnother: "सहेजें और एक और जोड़ें",
        saveChanges: "परिवर्तन सहेजें",
        addCategory: "श्रेणी जोड़ें",
        description: "विवरण",
        parentCategory: "मुख्य श्रेणी",
        submit: "प्रस्तुत",
        addInventory: "इन्वेंटरी जोड़ें",
        searchByBarcode: "बारकोड से खोजें",
        newStock: "नया स्टॉक",
        productId: "उत्पाद आईडी",
        inStock: "स्टॉक में",
        addedDetails: "जोड़ा गया विवरण",
        userName: "उपयोगकर्ता नाम",
        inventoryAdded: "इन्वेंटरी जोड़ी गई",
        date: "तारीख",
        addPurchases: "खरीदें जोड़ें",
        referenceNo: "संदर्भ संख्या",
        supplier: "आपूर्तिकर्ता",
        totalItem: "कुल आइटम",
        netAmount: "नेट राशि",
        amount: "राशि",
        totalPurchase: "कुल खरीद",
        purchaseDate: "खरीद तिथि",
        recept: "पावती",
        viewRecept: "पावती देखें",
        paymentType: "भुगतान प्रकार",
        paymentDue: "भुगतान की जरूरत",
        discount: "छूट",
        uploadReceipt: "पावती अपलोड करें",
        purchaseDetails: "खरीद विवरण",
        supplierIsRequired: "आपूर्तिकर्ता आवश्यक है।",
        paymentTypeIsRequired: "भुगतान प्रकार आवश्यक है।",
        emptyPurchaseList: "खाली खरीद सूची",
        purchWarning:
          "आप अपना अनुरोध सबमिट नहीं कर सकते हैं। क्योंकि आपकी खरीद सूची खाली है।",

        searchByNameOrBarcode: "नाम या बारकोड से खोजें",
        itemName: "आइटम का नाम",
        price: "मूल्य",
        purchaseUser: "खरीद उपयोगकर्ता",
        purchaseRecept: "खरीद पावती",
        total: "कुल",
        totalPrice: "कुल मूल्य",
        dateOfBirth: "जन्म तिथि",
        gender: "लिंग",
        phone: "फ़ोन",
        userRole: "उपयोगकर्ता भूमिका",
        inStore: "स्टोर में",
        role: "भूमिका",
        isActive: "सक्रिय है",
        login: "लॉगिन",
        back: "वापस",
        password: "पासवर्ड",
        verificationCode: "सत्यापन कोड",
        confirmPassword: "पासवर्ड की पुष्टि करें",
        forgotPassword: "पासवर्ड भूल गए",
        userNameIsRequired: "उपयोगकर्ता नाम आवश्यक है",
        passwordAndConfirmPasswordNotMatch:
          "पासवर्ड और पासवर्ड की पुष्टि मेल नहीं खाते",
        sendVerificationCode: "सत्यापन कोड भेजें",
        confirmPasswordIsRequired: "पासवर्ड की पुष्टि आवश्यक है",
        salaryScale: "वेतन स्केल",
        salaryType: "वेतन प्रकार",
        changePassword: "पासवर्ड बदलें",
        salary: "वेतन",
        welcome: "स्वागत है",
        SSN: "एसएसएन",
        successfullySetAsADefaultStore:
          "सफलतापूर्वक एक डिफ़ॉल्ट स्टोर के रूप में सेट किया गया",
        somethingIsWrongPleaseTryAgain: "कुछ गड़बड़ है, कृपया पुनः प्रयास करें",
        myStores: "मेरी स्टोर्स",
        submitToDefaultStore: "डिफ़ॉल्ट स्टोर में सबमिट करें",
        switchToAnotherStore: "दूसरे स्टोर में स्विच करें",
        storeDltWarning:
          "वर्तमान में, आप इस स्टोर में हैं। कृपया पहले किसी अन्य स्टोर में स्विच करें और फिर पुनः प्रयास करें।",

        invalidReferenceNo: "अवैध संदर्भ संख्या",
        referenceNoRsRequired: "संदर्भ संख्या आवश्यक है।",
        emptyReturnList: "खाली रिटर्न सूची",
        returnPurchWarning:
          "आप अपना अनुरोध सबमिट नहीं कर सकते हैं। क्योंकि आपकी रिटर्न सूची खाली है।",
        purchaseReturn: "खरीद रिटर्न",
        returnDate: "रिटर्न तिथि",
        returnPurchases: "रिटर्न खरीदें",
        returnReason: "रिटर्न कारण",
        addPurchaseReturn: "खरीद रिटर्न जोड़ें",
        returnValue: "रिटर्न मूल्य",
        returnItemDetails: "रिटर्न आइटम विवरण",
        returnUser: "रिटर्न उपयोगकर्ता",
        firstNameIsRequired: "पहला नाम आवश्यक है।",
        lastNameIsRequired: "उपनाम आवश्यक है।",
        phoneIsRequired: "फ़ोन आवश्यक है।",
        companyNameIsRequired: "कंपनी का नाम आवश्यक है।",
        emailIsNotValid: "ईमेल वैध नहीं है।",
        emailIsRequired: "ईमेल आवश्यक है।",
        prefixIsRequired: "पूर्वसर्ग आवश्यक है।",
        genderIsRequired: "लिंग आवश्यक है।",
        languageIsRequired: "भाषा आवश्यक है।",
        roleIsRequired: "भूमिका आवश्यक है।",
        isActiveIsRequired: "स्थिति आवश्यक है।",
        passwordIsRequired: "पासवर्ड आवश्यक है",
        confirmPassIsRequired: "पासवर्ड की पुष्टि आवश्यक है",
        firstName: "पहला नाम",
        lastName: "उपनाम",
        email: "ईमेल",
        productsSale: "उत्पाद बिक्री",
        city: "शहर",
        state: "राज्य",
        ZIP: "पिन कोड",
        address: "पता",
        note: "टिप्पणी",
        itsTooDangerous: "यह बहुत खतरनाक है",
        addSupplier: "आपूर्तिकर्ता जोड़ें",
        contractPerson: "संविदान व्यक्ति",
        addExpense: "व्यय जोड़ें",
        transectionDate: "लेन-देन तिथि",
        invoice: "चालान",
        paymenReceived: "प्राप्ति",
        change: "बदलें",
        clear: "स्पष्ट",
        cashier: "कैशियर",
        saleDetails: "बिक्री विवरण",
        amountIsRequired: "राशि आवश्यक है।",
        cashInDrawer: "ड्रॉयर में नकदी",
        transferDateIsRequired: "स्थानांतरण तिथि आवश्यक है।",
        fromLocationIsRequired: "स्थान (से) आवश्यक है।",
        toLocationIsRequired: "स्थान (को) आवश्यक है।",
        outOfStock: "स्टॉक से बाहर",
        outOfStockWarning:
          "आप इस उत्पाद को स्थानांतरित नहीं कर सकते हैं। क्योंकि यह आपके इन्वेंटरी में उपलब्ध नहीं है।",
        stockTransfer: "स्टॉक स्थानांतरण",
        transferDate: "स्थानांतरण तिथि",
        locationFrom: "स्थान (से)",
        locationTo: "स्थान (को)",
        transferDetails: "स्थानांतरण विवरण",
        addTransfer: "स्थानांतरण जोड़ें",
        transferItems: "स्थानांतरण आइटम",
        employeeName: "कर्मचारी का नाम",
        totalSale: "कुल बिक्री",
        voidSale: "वॉयड सेल",
        refund: "रिफंड",
        itemCancel: "आइटम रद्द",
        paidBy: "द्वारा भुगतान किया गया",
        paid: "भुगतान किया गया",
        detailsNote: "विवरण नोट",
        expenseType: "व्यय प्रकार",
        lastMonthSale: "पिछले महीने की बिक्री",
        currentMonthSale: "इस महीने की बिक्री",
        lottery: "लॉटरी",
        hight: "ऊचाई",
        noTax: "कोई कर नहीं",
        notes: "टिप्पणियां",
        low: "कम",
        totalSold: "कुल बिक्री",
        purchaseBy: "खरीद द्वारा",
        supplierName: "आपूर्ति वितरक का नाम",
        craditSale: "क्रेडिट बिक्री",
        cashSale: "नकद बिक्री",
        cashierName: "कैशियर का नाम",
        closeTime: "बंद करने का समय",
        openTime: "खोलने का समय",
        returnBy: "वापसी द्वारा",
        searchByMonth: "माह के अनुसार खोजें",
        grossProfit: "कुल लाभ",
        totalSellPrice: "कुल बिक्री मूल्य",
        totalPurchasePrice: "कुल खरीद मूल्य",
        netProfit: "नेट लाभ",
        grossSell: "कुल बिक्री",
        totalPurchese: "कुल खरीद",
        totalExpences: "कुल व्यय",
        openingInventoryValue: "आरंभिक इन्वेंटरी मूल्य",
        newInventoryAdded: "नई इन्वेंटरी जोड़ी गई",
        inventorySold: "इन्वेंटरी बिक गई",
        currentInventoryValue: "वर्तमान इन्वेंटरी मूल्य",
        storeDetails: "स्टोर विवरण",
        totalItemSold: "कुल आइटम बिक गए",
        totalItemCancelledFromCart: "कुल कार्ट से रद्द हुए आइटम",
        totalRefund: "कुल रिफंड",
        totalVoidSale: "कुल वॉयड बिक्री",
        items: "आइटम्स",
        expenses: "व्यय",
        closeRegister: "रजिस्टर बंद करें",
        inventory: "इन्वेंटरी",
        itemSold: "आइटम बिक गया",
        registerDetails: "रजिस्टर विवरण",
        cashierDetails: "कैशियर विवरण",
        storeName: "स्टोर का नाम",
        saleDate: "बिक्री तिथि",
        timeIn: "समय में",
        expenseTypeIsRequired: "व्यय प्रकार आवश्यक है",
        timeOut: "समय बाहर",
        credit: "क्रेडिट",
        productsSoldByCategory: "श्रेणी द्वारा बिक्री हुई उत्पाद",
        totalCustomers: "कुल ग्राहक",
        averagePerCustomer: "प्रति ग्राहक औसत",
        totalSaleRefund: "कुल बिक्री रिफंड",
        totalVoidSells: "कुल वॉयड बिक्री",
        totalItemCanceled: "कुल आइटम रद्द हो गए",
        saleReport: "बिक्री रिपोर्ट",
        cancel: "रद्द करें",
        totalCashSale: "कुल नकद बिक्री",
        totalCreditSale: "कुल क्रेडिट बिक्री",
        otherPaymentReceived: "अन्य भुगतान प्राप्त हुआ",
        sellDetails: "बिक्री विवरण",
        totalGrossSale: "कुल कुल बिक्री",
        totalTaxItemSold: "कुल कर आइटम बिक गए",
        totalNonTaxItemSold: "कुल गैर-कर आइटम बिक गए",
        totalTaxReceived: "कुल कर प्राप्त हुआ",
        money: "पैसा",
        shiftTransactionsBalance: "शिफ्ट लेन-देन संतुलन",
        addUser: "उपयोगकर्ता जोड़ें",
        activeStatus: "सक्रिय स्थिति",
        userNameIsAlreadyExist: "उपयोगकर्ता नाम पहले से मौजूद है",
        user: "उपयोगकर्ता",
        userInformation: "उपयोगकर्ता जानकारी",
        prefix: "प्रिफ़िक्स",
        language: "भाषा",
        rolesAndPermissions: "भूमिकाएँ और अनुमतियाँ",
        payTypeIsRequired: "भुगतान प्रकार आवश्यक है।",
        totalIsRequired: "कुल आवश्यक है।",
        bill: "बिल",
        payType: "भुगतान प्रकार",
        addPayBill: "भुगतान बिल जोड़ें",
        hireDate: "नौकरी दिनांक",
        totalSalary: "कुल वेतन",
        totalWorkHour: "कुल काम का समय",
        payPeriod: "भुगतान अवधि",
        fromDateIsRequired: "तारीख से आवश्यक है।",
        toDateIsRequired: "तारीख तक आवश्यक है।",
        totalSalaryIsRequired: "कुल वेतन आवश्यक है।",
        payDateIsRequired: "भुगतान तिथि आवश्यक है।",
        salaryPaymentForm: "वेतन भुगतान फॉर्म",
        errors: "त्रुटियाँ",
        searchEmployeeByNameOrPhone: "नाम या फोन द्वारा कर्मचारी खोजें",
        fromDate: "तारीख से",
        toDate: "तारीख तक",
        totalHours: "कुल घंटे",
        payDate: "भुगतान तिथि",
        allItems: "सभी आइटम",
        storeNameIsRequired: "स्टोर का नाम आवश्यक है।",
        taxPercentageIsRequired: "कर प्रतिशत आवश्यक है।",
        managerDetails: "मैनेजर विवरण",
        taxPercentage: "कर प्रतिशत",
        logo: "लोगो",
        addStore: "स्टोर जोड़ें",
        managerName: "मैनेजर का नाम",
        organizationDetailsUpdatedSuccessfully:
          "संगठन विवरण सफलता पूर्वक अपडेट किए गए",
        editOrganizationDetails: "संगठन विवरण संपादित करें",
        organizationName: "संगठन का नाम",
        localeOrgName: "स्थानीय संगठन नाम",
        currency: "मुद्रा",
        notFound: "नहीं मिला",
        dashboard: "डैशबोर्ड",
        products: "उत्पाद",
        categories: "श्रेणियाँ",
        storeReport: "स्टोर रिपोर्ट",
        productReport: "उत्पाद रिपोर्ट",
        purchaseReport: "खरीद रिपोर्ट",
        registerReport: "रजिस्टर रिपोर्ट",
        expenseReport: "व्यय रिपोर्ट",
        return: "वापसी",
        topSelling: "शीर्ष बिक्री",
        employeeReport: "कर्मचारी रिपोर्ट",
        productsSellVolume: "उत्पाद बिक्री आयाम",
        HR: "इच्छा",
        payBills: "बिल भुगतान",
        salaryPayment: "वेतन भुगतान",
        salaryHistory: "वेतन इतिहास",
        employeeHistory: "कर्मचारी इतिहास",
        reports: "रिपोर्टें",
        master: "मास्टर",
        organization: "संगठन",
        defaultStore: "डिफ़ॉल्ट स्टोर",
        POSItem: "POS आइटम",
        logout: "लॉग आउट",
        invoiceNo: "चालान नंबर",
        POS: "पीओएस",
        sellDate: "बिक्री तिथि",
        verificationCodeIsRequired: "सत्यापन कोड आवश्यक है।",
        enterVerificationCode: "सत्यापन कोड डालें",
        resendCode: "कोड पुनः भेजें",
        verificationCodeSentTitle: "सत्यापन कोड सफलता पूर्वक पुनः भेजा गया है।",
        storeDltTitle:
          "क्या आप वाकई इस स्टोर को हटाना चाहते हैं? यदि आप इस स्टोर को हटा देते हैं तो इस स्टोर में सभी डेटा हटा दिया जाएगा।",
        successfully: "सफलता पूर्वक",
        enter: "दर्ज करें",
        voidSell: "वॉयड बिक्री",
        printLastSale: "पिछली बिक्री प्रिंट करें",
        home: "होम",
        successful: "सफल",
        youHaveSuccessfullyVoidThisSale:
          "आपने इस बिक्री को सफलता पूर्वक वॉयड किया है।",
        youWantToVoidThisSell: "क्या आप इस बिक्री को वॉयड करना चाहते हैं?",
        thereIsNoLastSaleData: "पिछली बिक्री डेटा उपलब्ध नहीं है।",
        cashRegisterReport: "नकद रजिस्टर रिपोर्ट",
        others: "अन्य",
        detailsOfProductsSold: "बिक्री हुए उत्पादों का विवरण",
        invoiceID: "चालान आईडी",
        refundNow: "अब रिफंड करें",
        enterNameOrScanBarcode: "नाम दर्ज करें या बारकोड स्कैन करें",
        error: "त्रुटि",
        verify: "सत्यापित करें",
        warning: "चेतावनी",
        success: "सफलता",
        youHaveSuccessfullyRefund: "आपने सफलता पूर्वक रिफंड किया है।",
        wrongAmount: "आपने गलत राशि दर्ज की है। कृपया सही राशि दर्ज करें।",
        paymentFieldRequired: "भुगतान प्राप्त क्षेत्र आवश्यक है",
        regCloseWarning:
          "आप अपना रजिस्टर बंद नहीं कर सकते। क्योंकि आप अपना रजिस्टर खोल नहीं सकते।",
        noItemInCart:
          "कार्ट में कोई आइटम नहीं है। कृपया पहले कुछ आइटम कार्ट में जोड़ें।",
        regClosedSuccessTitle: "आपने अपना रजिस्टर सफलता पूर्वक बंद किया है।",
        areYouSure: "क्या आप सुनिश्चित हैं!",
        dltTitle: "क्या आप इस रिकॉर्ड को हटाना चाहते हैं?",
        prodDltTitle: "क्या आप इस उत्पाद को हटाना चाहते हैं?",
        dltSuccessTitle: "सफलता पूर्वक हटा दिया गया",
        prodAddTitle: "उत्पाद सफलता पूर्वक जोड़ा गया है।",
        catDltTitle: "क्या आप इस श्रेणी को हटाना चाहते हैं?",
        invAddedTitle: "इन्वेंटरी सफलता पूर्वक जोड़ी गई है।",
        invProdError:
          "कोई उत्पाद उपलब्ध नहीं हैं। कृपया पहले कुछ उत्पाद खोजें।",
        productNotFound: "उत्पाद नहीं मिला।",
        splrDltTitle:
          "क्या आप वाकई इस आपूर्ति वितरक को हटाना चाहते हैं? यदि आप इस आपूर्ति वितरक को हटा देते हैं तो सभी संदर्भ खरीदी जाएंगी।",
        stockTransferDltTitle:
          "क्या आप इस स्टॉक स्थानांतरण रिकॉर्ड को हटाना चाहते हैं?",
        userDltUser: "क्या आप इस उपयोगकर्ता को हटाना चाहते हैं?",
        warningMsg: "कुछ गड़बड़ है। कृपया पुनः प्रयास करें।",
        userNotSelectedError:
          "कोई उपयोगकर्ता चयनित नहीं है। कृपया, पहले एक उपयोगकर्ता का चयन करें।",
        userDltTitle: "क्या आप इस उपयोगकर्ता को हटाना चाहते हैं?",
        userDltSuccessTitle: "आपने सफलता पूर्वक उपयोगकर्ता को हटा दिया है",
        frgtCodeSentTitle: "6 अंकों का सत्यापन कोड इस ईमेल पर भेजा गया है",
        refundInvIdReq: "चालान आईडी आवश्यक है। कृपया चालान आईडी दर्ज करें।",

        // Dropdown List
        male: "पुरुष",
        female: "महिला",
        other: "अन्य",
        today: "आज",
        thisWeek: "इस सप्ताह",
        thisMonth: "इस महीने",
        thisYear: "इस साल",
        box: "बॉक्स",
        piece: "टुकड़ा",
        fullPaid: "पूरा भुगतान",
        due: "बकाया",
        cash: "नकद",
        yes: "हाँ",
        no: "नहीं",
        dateExpire: "समाप्ति तिथि",
        noSale: "कोई बिक्री नहीं",
        extra: "अतिरिक्त",
        damage: "क्षति",
        badItem: "बुरा आइटम",
        mr: "श्री",
        mrs: "सुश्री",
        miss: "कुमारी",
        daily: "रोज़",
        monthly: "मासिक",
        yearly: "वार्षिक",
        admin: "एडमिन",
        manager: "मैनेजर",
        brands: "ब्रांड्स",
      },
    },
    zh: {
      translation: {
        multPay: "多币种支付",
        multPayment: "多币种支付",
        pleaseEnterDueDate: "请输入截止日期",
        pleaseFirstSelectAmt: "请先选择金额",
        amountToBePaid: "要支付的金额",
        multcurrency: "多币种",
        paidLocal: "本地支付",
        addPayment: "添加支付",
        rate: "汇率",
        currentRate: "当前汇率",
        rateIsRequired: "汇率是必需的",
        addDateIsReq: "添加日期是必需的",
        thisFieldIsReq: "此字段为必填项",
        newRate: "新汇率",
        currencyIsRequired: "货币是必需的",
        symbolIsRequired: "符号是必需的",
        currencies: "货币",
        addCurrency: "添加货币",
        symbol: "符号",
        lastUpdate: "最后更新",
        currencyUnit: "货币单位",
        currencySubunit: "货币子单位",

        sale: "销售",
        keepNoteIfYouWant: "如果您想要，可以记下...",
        addChangeAsCreditBalance: "将零钱添加为信用余额",
        additionalDiscount: "额外折扣",
        amountToPay: "支付金额",
        enterAmount: "输入金额",
        useCredit: "使用信用",
        storeCredit: "商店信用",
        selectCustomer: "选择客户",
        dueDateIsRequired: "到期日必填",
        cstmrNotHaveAccBal: "客户没有账户余额。",
        pleaseFirstSelectCustomer: "请先选择客户。",
        checkout: "结账",
        dueAmountIsRequired: "到期金额必填",
        nextDueDateIsRequired: "下次到期日必填",
        youHavePutedWrongAmount: "您输入了错误的金额",
        dueAmount: "到期金额",
        details: "详情",
        receipt: "收据",
        transaction: "交易",
        settings: "设置",
        loading: "加载中",
        account: "帐户",
        customerId: "客户编号",
        accountBalanceType: "帐户余额类型",
        id: "编号",
        importExcel: "导入Excel",
        exportCSV: "导出CSV",
        cstmrDltTitle: "您真的要删除此客户档案吗？",
        customer: "客户",
        customers: "客户",
        addCustomer: "添加客户",
        yesWithNoBalanceLimit: "是的，无余额限制",
        yesButWithABalanceLimit: "是的，但有余额限制",
        customerCode: "客户编号",
        hasCard: "有卡",
        contact: "联系方式",
        card: "信用/借记卡",
        generalFields: "常规字段",
        addressFields: "地址字段",
        additionalFields: "附加字段",
        generateCustomerId: "生成客户编号",
        allowAnOnAccountBalance: "允许账户余额",
        balanceLimit: "余额限制",
        country: "国家",
        address1: "地址1",
        address2: "地址2",
        twitter: "推特",
        website: "网站",
        profilePicture: "个人资料图片",
        nameOnCard: "卡上的名字",
        cardNumber: "卡号",
        expiryDate: "到期日期",
        securityCode: "安全码",
        balance: "余额",
        totalSpent: "总花费",
        last12Months: "过去12个月",
        history: "历史",
        customerScince: "客户自",
        personalDetails: "个人详情",
        birthday: "生日",
        yearsOld: "岁",
        cardPayment: "卡付款",
        debit: "借记",
        accountBalance: "账户余额：",
        accounts: "帐户",
        dueReceipt: "到期收据",
        sellType: "销售类型",
        totalPayment: "总付款",
        dueDate: "到期日期",
        makePayment: "付款",
        totalSales: "总销售额",
        sales: "销售",
        sells: "销售",
        purchase: "购买",
        purchases: "购买",
        purchasesReturn: "购买退货",
        suppliers: "供应商",
        purchaseProducts: "购买产品",
        addNewProduct: "添加新产品",
        profit: "利润",
        expense: "费用",
        monthlySalesReports: "月度销售报告",
        yearlySales: "年度销售",
        monthlySales: "月度销售",
        weeklySales: "每周销售",
        dailySales: "每日销售",
        numberOfItemSold: "售出的商品数量",
        sellPrice: "销售价格",
        purchasePrice: "购买价格",
        totalExpense: "总费用",
        profitBreakdown: "利润分析",
        purchaseSellsReports: "购买与销售报告",
        salesByEmployees: "员工销售",
        storeExpenses: "店铺费用",
        inStoreInventoryValue: "店内库存价值",
        top20SellingProducts: "畅销前20产品",
        productName: "产品名称",
        unitSell: "单品销售",
        unitCost: "单品成本",
        unitProfit: "单品利润",
        totalOnStock: "总库存",
        top10ProfitMakingItems: "前10利润最高的产品",
        inventoryValue: "库存价值",
        inInventory: "库存中",
        newStockIsRequired: "新库存是必需的。",
        quantity: "数量",
        subtotal: "小计",
        unitPrice: "单价",
        store: "商店",
        updated: "已更新",
        added: "已添加",
        printBarcode: "打印条形码",
        edit: "编辑",
        delete: "删除",
        addProduct: "添加产品",
        generateBarcode: "生成条形码",
        actions: "操作",
        name: "名称",
        barcode: "条形码",
        category: "类别",
        subcategory: "子类别",
        print: "打印",
        addNew: "添加新",
        categoryName: "类别名称",
        seeYouAgain: "再见!",
        thanksForYourPurchase: "感谢您的购买",
        qty: "数量",
        unitType: "单位类型",
        tax: "税",
        totalPay: "总支付",
        pay: "支付",
        paymentReceived: "已收款",
        now: "现在",
        profitMargin: "利润率",
        alertQty: "警告数量",
        nameIsRequired: "名称是必需的。",
        barcodeIsRequired: "条形码是必需的。",
        unitTypeIsTequired: "单位类型是必需的。",
        taxIsRequired: "税是必需的。",
        purchasePriceIsIequired: "购买价格是必需的。",
        sellPriceIsRequired: "销售价格是必需的。",
        productDescription: "产品描述",
        productImages: "产品图片",
        categoryNameIsRequired: "类别名称是必需的。",
        productDetails: "产品详情",
        companyName: "公司名称",
        expenseNameIsRequired: "费用名称是必需的。",
        expenseCategory: "费用类别",
        expenseName: "费用名称",
        expenseDescription: "费用描述",
        product: "产品",
        add: "添加",
        update: "更新",
        view: "查看",
        close: "关闭",
        saveAndExit: "保存并退出",
        saveAndAddAnother: "保存并添加另一个",
        saveChanges: "保存更改",
        addCategory: "添加类别",
        description: "描述",
        parentCategory: "父类别",
        submit: "提交",
        addInventory: "添加库存",
        searchByBarcode: "按条形码搜索",
        newStock: "新库存",
        productId: "产品ID",
        inStock: "库存中",
        addedDetails: "已添加的详情",
        userName: "用户名",
        inventoryAdded: "库存已添加",
        date: "日期",
        addPurchases: "添加购买",
        referenceNo: "参考编号",
        supplier: "供应商",
        totalItem: "总商品数",
        netAmount: "净金额",
        amount: "金额",
        totalPurchase: "总购买",
        purchaseDate: "购买日期",
        recept: "收据",
        viewRecept: "查看收据",
        paymentType: "付款方式",
        paymentDue: "到期付款",
        discount: "折扣",
        uploadReceipt: "上传收据",
        purchaseDetails: "购买详情",
        supplierIsRequired: "供应商是必需的。",
        paymentTypeIsRequired: "付款方式是必需的。",
        emptyPurchaseList: "购买清单为空",
        purchWarning: "您无法提交请求。因为您的购买清单为空。",

        searchByNameOrBarcode: "按名称或条形码搜索",
        itemName: "商品名称",
        price: "价格",
        purchaseUser: "购买用户",
        purchaseRecept: "购买收据",
        total: "总计",
        totalPrice: "总价",
        dateOfBirth: "出生日期",
        gender: "性别",
        phone: "电话",
        userRole: "用户角色",
        inStore: "在店内",
        role: "角色",
        isActive: "是否激活",
        login: "登录",
        back: "返回",
        password: "密码",
        verificationCode: "验证码",
        confirmPassword: "确认密码",
        forgotPassword: "忘记密码",
        userNameIsRequired: "用户名是必需的",
        passwordAndConfirmPasswordNotMatch: "密码和确认密码不匹配",
        sendVerificationCode: "发送验证码",
        confirmPasswordIsRequired: "确认密码是必需的",
        salaryScale: "工资刻度",
        salaryType: "工资类型",
        changePassword: "更改密码",
        salary: "薪水",
        welcome: "欢迎",
        SSN: "社会安全号码",
        successfullySetAsADefaultStore: "已成功设置为默认商店",
        somethingIsWrongPleaseTryAgain: "出现错误，请重试。",
        myStores: "我的商店",
        submitToDefaultStore: "提交到默认商店",
        switchToAnotherStore: "切换到另一个商店",
        storeDltWarning: "您当前位于此商店。请先切换到另一个商店，然后重试。",

        invalidReferenceNo: "无效的参考编号",
        referenceNoRsRequired: "参考编号是必需的。",
        emptyReturnList: "退货清单为空",
        returnPurchWarning: "您无法提交请求。因为您的退货清单为空。",
        purchaseReturn: "购买退货",
        returnDate: "退货日期",
        returnPurchases: "退货购买",
        returnReason: "退货原因",
        addPurchaseReturn: "添加购买退货",
        returnValue: "退货价值",
        returnItemDetails: "退货商品详情",
        returnUser: "退货用户",
        firstNameIsRequired: "名字是必需的。",
        lastNameIsRequired: "姓氏是必需的。",
        phoneIsRequired: "电话是必需的。",
        companyNameIsRequired: "公司名是必需的。",
        emailIsNotValid: "电子邮件无效。",
        emailIsRequired: "电子邮件是必需的。",
        prefixIsRequired: "前缀是必需的。",
        genderIsRequired: "性别是必需的。",
        languageIsRequired: "语言是必需的。",
        roleIsRequired: "角色是必需的。",
        isActiveIsRequired: "状态是必需的。",
        passwordIsRequired: "密码是必需的",
        confirmPassIsRequired: "确认密码是必需的",
        firstName: "名字",
        lastName: "姓氏",
        email: "电子邮件",
        productsSale: "产品销售",
        city: "城市",
        state: "州",
        ZIP: "邮编",
        address: "地址",
        note: "备注",
        itsTooDangerous: "太危险了",
        addSupplier: "添加供应商",
        contractPerson: "联系人",
        addExpense: "添加费用",
        transectionDate: "交易日期",
        invoice: "发票",
        paymenReceived: "已收款",
        change: "更改",
        clear: "清除",
        cashier: "收银员",
        saleDetails: "销售详情",
        amountIsRequired: "金额是必需的。",
        cashInDrawer: "现金在抽屉中",
        transferDateIsRequired: "转账日期是必需的。",
        fromLocationIsRequired: "来源地点是必需的。",
        toLocationIsRequired: "目标地点是必需的。",
        outOfStock: "缺货",
        outOfStockWarning: "您无法转移此产品。因为它在您的库存中不可用。",
        stockTransfer: "库存转移",
        transferDate: "转移日期",
        locationFrom: "位置（从）",
        locationTo: "位置（到）",
        transferDetails: "转移详情",
        addTransfer: "添加转移",
        transferItems: "转移商品",
        employeeName: "员工姓名",
        totalSale: "总销售",
        voidSale: "作废销售",
        refund: "退款",
        itemCancel: "商品取消",
        paidBy: "支付人",
        paid: "已支付",
        detailsNote: "详细说明",
        expenseType: "费用类型",
        lastMonthSale: "上个月销售",
        currentMonthSale: "本月销售",
        lottery: "彩票",

        hight: "高",
        noTax: "无税",
        notes: "备注",
        low: "低",
        totalSold: "总销售",
        purchaseBy: "购买者",
        supplierName: "供应商名称",
        craditSale: "信用销售",
        cashSale: "现金销售",
        cashierName: "收银员姓名",
        closeTime: "关闭时间",
        openTime: "开放时间",
        returnBy: "退货者",
        searchByMonth: "按月搜索",
        grossProfit: "总利润",
        totalSellPrice: "总销售价",
        totalPurchasePrice: "总购买价",
        netProfit: "净利润",
        grossSell: "总销售额",
        totalPurchese: "总购买额",
        totalExpences: "总费用",
        openingInventoryValue: "期初库存价值",
        newInventoryAdded: "新库存已添加",
        inventorySold: "库存已售出",
        currentInventoryValue: "当前库存价值",
        storeDetails: "店铺详情",
        totalItemSold: "总售出商品",
        totalItemCancelledFromCart: "购物车中取消的商品总数",
        totalRefund: "总退款",
        totalVoidSale: "总作废销售",
        items: "商品",
        expenses: "费用",
        closeRegister: "关闭注册",
        inventory: "库存",
        itemSold: "售出商品",
        registerDetails: "注册详情",
        cashierDetails: "收银员详情",
        storeName: "店铺名称",
        saleDate: "销售日期",
        timeIn: "上班时间",
        expenseTypeIsRequired: "费用类型是必需的。",
        timeOut: "下班时间",
        credit: "信用",
        productsSoldByCategory: "按类别销售的商品",
        totalCustomers: "总客户数",
        averagePerCustomer: "每位客户平均",
        totalSaleRefund: "总销售退款",
        totalVoidSells: "总作废销售",
        totalItemCanceled: "总取消商品",
        saleReport: "销售报告",
        cancel: "取消",
        totalCashSale: "总现金销售",
        totalCreditSale: "总信用销售",
        otherPaymentReceived: "其他收款",
        sellDetails: "销售详情",
        totalGrossSale: "总毛销售额",
        totalTaxItemSold: "总税收商品售出",
        totalNonTaxItemSold: "总非税商品售出",
        totalTaxReceived: "总税收收入",
        money: "金额",
        shiftTransactionsBalance: "交班交易平衡",
        addUser: "添加用户",
        activeStatus: "激活状态",
        userNameIsAlreadyExist: "用户名已存在",
        user: "用户",
        userInformation: "用户信息",
        prefix: "前缀",
        language: "语言",
        rolesAndPermissions: "角色和权限",
        payTypeIsRequired: "支付类型是必需的。",
        totalIsRequired: "总额是必需的。",
        bill: "账单",
        payType: "支付类型",
        addPayBill: "添加支付账单",
        hireDate: "雇佣日期",
        totalSalary: "总工资",
        totalWorkHour: "总工时",
        payPeriod: "支付周期",
        fromDateIsRequired: "起始日期是必需的。",
        toDateIsRequired: "截止日期是必需的。",
        totalSalaryIsRequired: "总工资是必需的。",
        payDateIsRequired: "支付日期是必需的。",
        salaryPaymentForm: "工资支付表",
        errors: "错误",
        searchEmployeeByNameOrPhone: "按姓名或电话搜索员工",
        fromDate: "开始日期",
        toDate: "结束日期",
        totalHours: "总工时",
        payDate: "支付日期",
        allItems: "所有商品",
        storeNameIsRequired: "店铺名称是必需的。",
        taxPercentageIsRequired: "税率是必需的。",
        managerDetails: "经理详情",
        taxPercentage: "税率",
        logo: "标志",
        addStore: "添加店铺",
        managerName: "经理名称",
        organizationDetailsUpdatedSuccessfully: "组织详情已成功更新",
        editOrganizationDetails: "编辑组织详情",
        organizationName: "组织名称",
        localeOrgName: "本地组织名称",
        currency: "货币",
        notFound: "未找到",
        dashboard: "仪表板",
        products: "商品",
        categories: "类别",
        storeReport: "店铺报告",
        productReport: "商品报告",
        purchaseReport: "购买报告",
        registerReport: "注册报告",
        expenseReport: "费用报告",
        return: "退货",
        topSelling: "畅销商品",
        employeeReport: "员工报告",
        productsSellVolume: "商品销售量",
        HR: "人力资源",
        payBills: "支付账单",
        salaryPayment: "工资支付",
        salaryHistory: "工资历史",
        employeeHistory: "员工历史",
        reports: "报告",
        master: "主控",
        organization: "组织",
        defaultStore: "默认店铺",
        POSItem: "POS商品",
        logout: "注销",
        invoiceNo: "发票号码",
        POS: "POS",
        sellDate: "销售日期",
        verificationCodeIsRequired: "验证码是必需的。",
        enterVerificationCode: "输入验证码",
        resendCode: "重新发送验证码",

        verificationCodeSentTitle: "验证码已成功重新发送。",
        storeDltTitle:
          "您确定要删除此店铺吗？如果删除此店铺，该店铺中的所有数据将被删除。",
        successfully: "成功",
        enter: "输入",
        voidSell: "作废销售",
        printLastSale: "打印上次销售",
        home: "首页",
        successful: "成功",
        youHaveSuccessfullyVoidThisSale: "您已成功作废此销售。",
        youWantToVoidThisSell: "您要作废此销售吗？",
        thereIsNoLastSaleData: "没有上次销售数据。",
        cashRegisterReport: "现金注册报告",
        others: "其他",
        detailsOfProductsSold: "售出商品详情",
        invoiceID: "发票ID",
        refundNow: "立即退款",
        enterNameOrScanBarcode: "输入名称或扫描条形码",
        error: "错误",
        verify: "验证",
        warning: "警告",
        success: "成功",
        youHaveSuccessfullyRefund: "您已成功退款。",
        wrongAmount: "您输入的金额有误。请输入正确的金额。",
        paymentFieldRequired: "必须填写付款接收字段。",
        regCloseWarning: "您不能关闭您的注册。因为您不能打开您的注册。",
        noItemInCart: "购物车中没有商品。请先添加一些商品到购物车。",
        regClosedSuccessTitle: "您已成功关闭您的注册。",
        areYouSure: "您确定吗！",
        dltTitle: "您要删除此记录吗？",
        prodDltTitle: "您要删除此商品吗？",
        dltSuccessTitle: "删除成功",
        prodAddTitle: "商品已成功添加。",
        catDltTitle: "您要删除此类别吗？",
        invAddedTitle: "库存已成功添加。",
        invProdError: "没有可用的商品。请先找到商品。",
        productNotFound: "找不到商品。",
        splrDltTitle:
          "您确定要删除此供应商吗？如果删除此供应商，所有相关采购将被删除。",
        stockTransferDltTitle: "您要删除此转移记录吗？",
        userDltUser: "您要删除此用户吗？",
        warningMsg: "出现错误。请重试。",
        userNotSelectedError: "未选择任何用户。请先选择用户。",
        userDltTitle: "您要删除此用户吗？",
        userDltSuccessTitle: "您已成功删除用户",
        frgtCodeSentTitle: "6位验证码已发送至此电子邮件",
        refundInvIdReq: "发票ID是必需的。请输入发票ID。",

        // Dropdown List
        male: "男性",
        female: "女性",
        other: "其他",
        today: "今天",
        thisWeek: "本周",
        thisMonth: "本月",
        thisYear: "今年",
        box: "箱",
        piece: "件",
        fullPaid: "全额支付",
        due: "到期",
        cash: "现金",
        yes: "是",
        no: "否",
        dateExpire: "到期日期",
        noSale: "无销售",
        extra: "额外",
        damage: "损坏",
        badItem: "次品",
        mr: "先生",
        mrs: "女士",
        miss: "小姐",
        daily: "每日",
        monthly: "每月",
        yearly: "每年",
        admin: "管理员",
        manager: "经理",
        brands: "品牌",
      },
    },
    fr: {
      translation: {
        multPay: "Paiement Multidevises",
        multPayment: "Paiement Multidevises",
        pleaseEnterDueDate: "Veuillez entrer la date d'échéance",
        pleaseFirstSelectAmt: "Veuillez d'abord sélectionner le montant",
        amountToBePaid: "Montant à payer",
        multcurrency: "Multidevises",
        paidLocal: "Paiement local",
        addPayment: "Ajouter un paiement",
        rate: "Taux",
        currentRate: "Taux actuel",
        rateIsRequired: "Le taux est requis",
        addDateIsReq: "La date d'ajout est requise",
        thisFieldIsReq: "Ce champ est requis",
        newRate: "Nouveau taux",
        currencyIsRequired: "La devise est requise",
        symbolIsRequired: "Le symbole est requis",
        currencies: "Devises",
        addCurrency: "Ajouter une devise",
        symbol: "Symbole",
        lastUpdate: "Dernière mise à jour",
        currencyUnit: "Unité monétaire",
        currencySubunit: "Sous-unité monétaire",

        sale: "Vente",
        keepNoteIfYouWant: "Notez si vous le souhaitez...",
        addChangeAsCreditBalance: "Ajouter la monnaie comme solde créditeur",
        additionalDiscount: "Remise supplémentaire",
        amountToPay: "Montant à payer",
        enterAmount: "Entrez le montant",
        useCredit: "Utiliser le crédit",
        storeCredit: "Crédit du magasin",
        selectCustomer: "Sélectionner le client",
        dueDateIsRequired: "La date d'échéance est requise",
        cstmrNotHaveAccBal: "Le client n'a pas de solde de compte.",
        pleaseFirstSelectCustomer: "Veuillez d'abord sélectionner un client.",
        checkout: "Check-out",
        dueAmountIsRequired: "Le montant dû est requis",
        nextDueDateIsRequired: "La prochaine date d'échéance est requise",
        youHavePutedWrongAmount: "Vous avez entré un montant incorrect",
        dueAmount: "Montant dû",
        details: "Détails",
        receipt: "Reçu",
        transaction: "Transaction",
        settings: "Paramètres",
        loading: "Chargement",
        account: "Compte",
        customerId: "ID client",
        accountBalanceType: "Type de solde du compte",
        id: "ID",
        importExcel: "Importer Excel",
        exportCSV: "Exporter CSV",
        cstmrDltTitle: "Voulez-vous vraiment supprimer ce profil client ?",
        customer: "Client",
        customers: "Clients",
        addCustomer: "Ajouter un client",
        yesWithNoBalanceLimit: "Oui, sans limite de solde",
        yesButWithABalanceLimit: "Oui, mais avec une limite de solde",
        customerCode: "Code client",
        hasCard: "Possède une carte",
        contact: "Contact",
        card: "Carte de crédit/débit",
        generalFields: "Champs généraux",
        addressFields: "Champs d'adresse",
        additionalFields: "Champs supplémentaires",
        generateCustomerId: "Générer l'ID client",
        allowAnOnAccountBalance: "Autoriser un solde de compte",
        balanceLimit: "Limite de solde",
        country: "Pays",
        address1: "Adresse ligne 1",
        address2: "Adresse ligne 2",
        twitter: "Twitter",
        website: "Site web",
        profilePicture: "Photo de profil",
        nameOnCard: "Nom sur la carte",
        cardNumber: "Numéro de carte",
        expiryDate: "Date d'expiration",
        securityCode: "Code de sécurité",
        balance: "Solde",
        totalSpent: "Total dépensé",
        last12Months: "12 derniers mois",
        history: "Historique",
        customerScince: "Client depuis",
        personalDetails: "Détails personnels",
        birthday: "Anniversaire",
        yearsOld: "Ans",
        cardPayment: "Paiement par carte",
        debit: "Débit",
        accountBalance: "Solde du compte :",
        accounts: "Comptes",
        dueReceipt: "Reçu dû",
        sellType: "Type de vente",
        totalPayment: "Total des paiements",
        dueDate: "Date d'échéance",
        makePayment: "Effectuer un paiement",

        totalSales: "Ventes totales",
        sales: "Ventes",
        sells: "Vendus",
        purchase: "Achat",
        purchases: "Achats",
        purchasesReturn: "Retours d'achats",
        suppliers: "Fournisseurs",
        purchaseProducts: "Acheter des produits",
        addNewProduct: "Ajouter un nouveau produit",
        profit: "Profit",
        expense: "Dépense",
        monthlySalesReports: "Rapports mensuels de ventes",
        yearlySales: "Ventes annuelles",
        monthlySales: "Ventes mensuelles",
        weeklySales: "Ventes hebdomadaires",
        dailySales: "Ventes quotidiennes",
        numberOfItemSold: "Nombre d'articles vendus",
        sellPrice: "Prix de vente",
        purchasePrice: "Prix d'achat",
        totalExpense: "Dépense totale",
        profitBreakdown: "Répartition des profits",
        purchaseSellsReports: "Rapports d'achats et de ventes",
        salesByEmployees: "Ventes par employés",
        storeExpenses: "Dépenses de magasin",
        inStoreInventoryValue: "Valeur de l'inventaire en magasin",
        top20SellingProducts: "Top 20 des produits les plus vendus",
        productName: "Nom du produit",
        unitSell: "Vente unitaire",
        unitCost: "Coût unitaire",
        unitProfit: "Profit unitaire",
        totalOnStock: "Total en stock",
        top10ProfitMakingItems: "Top 10 des articles les plus rentables",
        inventoryValue: "Valeur de l'inventaire",
        inInventory: "Dans l'inventaire",
        newStockIsRequired: "Nouveau stock requis.",
        quantity: "Quantité",
        subtotal: "Sous-total",
        unitPrice: "Prix unitaire",
        store: "Magasin",
        updated: "Mis à jour",
        added: "Ajouté",
        printBarcode: "Imprimer le code-barres",
        edit: "Modifier",
        delete: "Supprimer",
        addProduct: "Ajouter un produit",
        generateBarcode: "Générer un code-barres",
        actions: "Actions",
        name: "Nom",
        barcode: "Code-barres",
        category: "Catégorie",
        subcategory: "Sous-catégorie",
        print: "Imprimer",
        addNew: "Ajouter",
        categoryName: "Nom de la catégorie",
        seeYouAgain: "À bientôt !",
        thanksForYourPurchase: "Merci pour votre achat",
        qty: "Qté",
        unitType: "Type d'unité",
        tax: "Taxe",
        totalPay: "Total à payer",
        pay: "Payer",
        paymentReceived: "Paiement reçu",
        now: "Maintenant",
        profitMargin: "Marge bénéficiaire",
        alertQty: "Quantité d'alerte",
        nameIsRequired: "Le nom est requis.",
        barcodeIsRequired: "Le code-barres est requis.",
        unitTypeIsTequired: "Le type d'unité est requis.",
        taxIsRequired: "La taxe est requise.",
        purchasePriceIsIequired: "Le prix d'achat est requis.",
        sellPriceIsRequired: "Le prix de vente est requis.",
        productDescription: "Description du produit",
        productImages: "Images du produit",
        categoryNameIsRequired: "Le nom de la catégorie est requis.",
        productDetails: "Détails du produit",
        companyName: "Nom de l'entreprise",
        expenseNameIsRequired: "Le nom de la dépense est requis.",
        expenseCategory: "Catégorie de dépense",
        expenseName: "Nom de la dépense",
        expenseDescription: "Description de la dépense",
        product: "Produit",
        add: "Ajouter",
        update: "Mettre à jour",
        view: "Vue",
        close: "Fermer",
        saveAndExit: "Enregistrer et quitter",
        saveAndAddAnother: "Enregistrer et ajouter un autre",
        saveChanges: "Enregistrer les modifications",
        addCategory: "Ajouter une catégorie",
        description: "Description",
        parentCategory: "Catégorie parente",
        submit: "Soumettre",
        addInventory: "Ajouter un inventaire",
        searchByBarcode: "Rechercher par code-barres",
        newStock: "Nouveau stock",
        productId: "Identifiant du produit",
        inStock: "En stock",
        addedDetails: "Détails ajoutés",
        userName: "Nom d'utilisateur",
        inventoryAdded: "Inventaire ajouté",
        date: "Date",
        addPurchases: "Ajouter des achats",
        referenceNo: "Numéro de référence",
        supplier: "Fournisseur",
        totalItem: "Total d'articles",
        netAmount: "Montant net",
        amount: "Montant",
        totalPurchase: "Achat total",
        purchaseDate: "Date d'achat",
        recept: "Reçu",
        viewRecept: "Voir le reçu",
        paymentType: "Type de paiement",
        paymentDue: "Paiement dû",
        discount: "Remise",
        uploadReceipt: "Télécharger le reçu",
        purchaseDetails: "Détails de l'achat",
        supplierIsRequired: "Le fournisseur est requis.",
        paymentTypeIsRequired: "Le type de paiement est requis.",
        emptyPurchaseList: "Liste d'achat vide",
        purchWarning:
          "Vous ne pouvez pas soumettre votre demande. Parce que votre liste d'achats est vide.",

        searchByNameOrBarcode: "Rechercher par nom ou code-barres",
        itemName: "Nom de l'article",
        price: "Prix",
        purchaseUser: "Utilisateur d'achat",
        purchaseRecept: "Reçu d'achat",
        total: "Total",
        totalPrice: "Prix total",
        dateOfBirth: "Date de naissance",
        gender: "Genre",
        phone: "Téléphone",
        userRole: "Rôle de l'utilisateur",
        inStore: "En magasin",
        role: "Rôle",
        isActive: "Est actif",
        login: "Se connecter",
        back: "Retour",
        password: "Mot de passe",
        verificationCode: "Code de vérification",
        confirmPassword: "Confirmer le mot de passe",
        forgotPassword: "Mot de passe oublié",
        userNameIsRequired: "Le nom d'utilisateur est requis",
        passwordAndConfirmPasswordNotMatch:
          "Le mot de passe et la confirmation du mot de passe ne correspondent pas",
        sendVerificationCode: "Envoyer le code de vérification",
        confirmPasswordIsRequired:
          "La confirmation du mot de passe est requise",
        salaryScale: "Échelle des salaires",
        salaryType: "Type de salaire",
        changePassword: "Changer le mot de passe",
        salary: "Salaire",
        welcome: "Bienvenue",
        SSN: "SSN",
        successfullySetAsADefaultStore:
          "Défini avec succès comme magasin par défaut",
        somethingIsWrongPleaseTryAgain:
          "Quelque chose ne va pas. Veuillez réessayer.",
        myStores: "Mes magasins",
        submitToDefaultStore: "Soumettre au magasin par défaut",
        switchToAnotherStore: "Passer à un autre magasin",
        storeDltWarning:
          "Actuellement, vous êtes dans ce magasin. Veuillez d'abord passer à un autre magasin, puis réessayez.",

        invalidReferenceNo: "Numéro de référence invalide",
        referenceNoRsRequired: "Le numéro de référence est requis.",
        emptyReturnList: "Liste de retour vide",
        returnPurchWarning:
          "Vous ne pouvez pas soumettre votre demande. Parce que votre liste de retours est vide.",
        purchaseReturn: "Retour d'achat",
        returnDate: "Date de retour",
        returnPurchases: "Retour des achats",
        returnReason: "Motif du retour",
        addPurchaseReturn: "Ajouter un retour d'achat",
        returnValue: "Valeur de retour",
        returnItemDetails: "Détails de l'article retourné",
        returnUser: "Utilisateur du retour",
        firstNameIsRequired: "Le prénom est requis.",
        lastNameIsRequired: "Le nom de famille est requis.",
        phoneIsRequired: "Le téléphone est requis.",
        companyNameIsRequired: "Le nom de l'entreprise est requis.",
        emailIsNotValid: "L'e-mail n'est pas valide.",
        emailIsRequired: "L'e-mail est requis.",
        prefixIsRequired: "Le préfixe est requis.",
        genderIsRequired: "Le genre est requis.",
        languageIsRequired: "La langue est requise.",
        roleIsRequired: "Le rôle est requis.",
        isActiveIsRequired: "Le statut est requis.",
        passwordIsRequired: "Le mot de passe est requis",
        confirmPassIsRequired: "La confirmation du mot de passe est requise",
        firstName: "Prénom",
        lastName: "Nom de famille",
        email: "E-mail",
        productsSale: "Vente de produits",
        city: "Ville",
        state: "État",
        ZIP: "Code postal",
        address: "Adresse",
        note: "Remarque",
        itsTooDangerous: "C'est trop dangereux",
        addSupplier: "Ajouter un fournisseur",
        contractPerson: "Personne de contact",
        addExpense: "Ajouter une dépense",
        transectionDate: "Date de la transaction",
        invoice: "Facture",
        paymenReceived: "Paiement reçu",
        change: "Changer",
        clear: "Effacer",
        cashier: "Caissier",
        saleDetails: "Détails de la vente",
        amountIsRequired: "Le montant est requis.",
        cashInDrawer: "Argent dans le tiroir",
        transferDateIsRequired: "La date de transfert est requise.",
        fromLocationIsRequired: "L'emplacement de départ est requis.",
        toLocationIsRequired: "L'emplacement de destination est requis.",
        outOfStock: "En rupture de stock",
        outOfStockWarning:
          "Vous ne pouvez pas transférer ce produit. Parce qu'il n'est pas disponible dans votre inventaire.",
        stockTransfer: "Transfert de stock",
        transferDate: "Date de transfert",
        locationFrom: "Emplacement (de)",
        locationTo: "Emplacement (à)",
        transferDetails: "Détails du transfert",
        addTransfer: "Ajouter un transfert",
        transferItems: "Articles transférés",
        employeeName: "Nom de l'employé",
        totalSale: "Vente totale",
        voidSale: "Vente annulée",
        refund: "Remboursement",
        itemCancel: "Annulation d'article",
        paidBy: "Payé par",
        paid: "Payé",
        detailsNote: "Note de détails",
        expenseType: "Type de dépense",
        lastMonthSale: "Vente du mois dernier",
        currentMonthSale: "Vente du mois en cours",
        lottery: "Loterie",

        hight: "hauteur",
        noTax: "Sans taxe",
        notes: "Notes",
        low: "Bas",
        totalSold: "Total vendu",
        purchaseBy: "Acheter par",
        supplierName: "Nom du fournisseur",
        craditSale: "Vente à crédit",
        cashSale: "Vente en espèces",
        cashierName: "Nom du caissier",
        closeTime: "Heure de fermeture",
        openTime: "Heure d'ouverture",
        returnBy: "Retour par",
        searchByMonth: "Rechercher par mois",
        grossProfit: "Bénéfice brut",
        totalSellPrice: "Prix de vente total",
        totalPurchasePrice: "Prix d'achat total",
        netProfit: "Bénéfice net",
        grossSell: "Vente brute",
        totalPurchese: "Total des achats",
        totalExpences: "Total des dépenses",
        openingInventoryValue: "Valeur initiale de l'inventaire",
        newInventoryAdded: "Nouvel inventaire ajouté",
        inventorySold: "Inventaire vendu",
        currentInventoryValue: "Valeur actuelle de l'inventaire",
        storeDetails: "Détails du magasin",
        totalItemSold: "Total d'articles vendus",
        totalItemCancelledFromCart: "Total d'articles annulés du panier",
        totalRefund: "Remboursement total",
        totalVoidSale: "Total des ventes annulées",
        items: "Articles",
        expenses: "Dépenses",
        closeRegister: "Fermer le registre",
        inventory: "Inventaire",
        itemSold: "Article vendu",
        registerDetails: "Détails du registre",
        cashierDetails: "Détails du caissier",
        storeName: "Nom du magasin",
        saleDate: "Date de vente",
        timeIn: "Heure d'entrée",
        expenseTypeIsRequired: "Le type de dépense est requis",
        timeOut: "Heure de sortie",
        credit: "Crédit",
        productsSoldByCategory: "Produits vendus par catégorie",
        totalCustomers: "Total des clients",
        averagePerCustomer: "Moyenne par client",
        totalSaleRefund: "Remboursement total des ventes",
        totalVoidSells: "Total des ventes annulées",
        totalItemCanceled: "Total d'articles annulés",
        saleReport: "Rapport de vente",
        cancel: "Annuler",
        totalCashSale: "Total des ventes en espèces",
        totalCreditSale: "Total des ventes à crédit",
        otherPaymentReceived: "Autres paiements reçus",
        sellDetails: "Détails de la vente",
        totalGrossSale: "Total des ventes brutes",
        totalTaxItemSold: "Total des articles taxés vendus",
        totalNonTaxItemSold: "Total des articles non taxés vendus",
        totalTaxReceived: "Total des taxes perçues",
        money: "Argent",
        shiftTransactionsBalance: "Solde des transactions de caisse",
        addUser: "Ajouter un utilisateur",
        activeStatus: "Statut actif",
        userNameIsAlreadyExist: "Le nom d'utilisateur existe déjà",
        user: "Utilisateur",
        userInformation: "Informations sur l'utilisateur",
        prefix: "Préfixe",
        language: "Langue",
        rolesAndPermissions: "Rôles et autorisations",
        payTypeIsRequired: "Le type de paiement est requis",
        totalIsRequired: "Le total est requis",
        bill: "Facture",
        payType: "Type de paiement",
        addPayBill: "Ajouter une facture de paiement",
        hireDate: "Date d'embauche",
        totalSalary: "Salaire total",
        totalWorkHour: "Total des heures de travail",
        payPeriod: "Période de paiement",
        fromDateIsRequired: "La date de début est requise",
        toDateIsRequired: "La date de fin est requise",
        totalSalaryIsRequired: "Le salaire total est requis",
        payDateIsRequired: "La date de paiement est requise",
        salaryPaymentForm: "Formulaire de paiement de salaire",
        errors: "Erreurs",
        searchEmployeeByNameOrPhone:
          "Rechercher un employé par nom ou téléphone",
        fromDate: "De la date",
        toDate: "À la date",
        totalHours: "Total des heures",
        payDate: "Date de paiement",
        allItems: "Tous les articles",
        storeNameIsRequired: "Le nom du magasin est requis",
        taxPercentageIsRequired: "Le pourcentage de taxe est requis",
        managerDetails: "Détails du responsable",
        taxPercentage: "Pourcentage de taxe",
        logo: "Logo",
        addStore: "Ajouter un magasin",
        managerName: "Nom du responsable",
        organizationDetailsUpdatedSuccessfully:
          "Détails de l'organisation mis à jour avec succès",
        editOrganizationDetails: "Modifier les détails de l'organisation",
        organizationName: "Nom de l'organisation",
        localeOrgName: "Nom local de l'organisation",
        currency: "Devise",
        notFound: "Non trouvé",
        dashboard: "Tableau de bord",
        products: "Produits",
        categories: "Catégories",
        storeReport: "Rapport de magasin",
        productReport: "Rapport de produit",
        purchaseReport: "Rapport d'achat",
        registerReport: "Rapport de registre",
        expenseReport: "Rapport de dépenses",
        return: "Retour",
        topSelling: "Top vente",
        employeeReport: "Rapport d'employé",
        productsSellVolume: "Volume de vente des produits",
        HR: "RH",
        payBills: "Payer les factures",
        salaryPayment: "Paiement de salaire",
        salaryHistory: "Historique des salaires",
        employeeHistory: "Historique des employés",
        reports: "Rapports",
        master: "Maître",
        organization: "Organisation",
        defaultStore: "Magasin par défaut",
        POSItem: "Article POS",
        logout: "Se déconnecter",
        invoiceNo: "Numéro de facture",
        POS: "POS",
        sellDate: "Date de vente",
        verificationCodeIsRequired: "Le code de vérification est requis",
        enterVerificationCode: "Entrer le code de vérification",
        resendCode: "Renvoyer le code",

        verificationCodeSentTitle:
          "Le code de vérification à 6 chiffres a été renvoyé avec succès.",
        storeDltTitle:
          "Voulez-vous vraiment supprimer ce magasin ? Si vous supprimez ce magasin, toutes les données de ce magasin seront supprimées.",
        successfully: "Avec succès",
        enter: "Entrer",
        voidSell: "Annuler la vente",
        printLastSale: "Imprimer la dernière vente",
        home: "Accueil",
        successful: "Réussi",
        youHaveSuccessfullyVoidThisSale:
          "Vous avez annulé cette vente avec succès.",
        youWantToVoidThisSell: "Vous voulez annuler cette vente ?",
        thereIsNoLastSaleData: "Il n'y a pas de données de dernière vente.",
        cashRegisterReport: "Rapport de caisse",
        others: "Autres",
        detailsOfProductsSold: "Détails des produits vendus",
        invoiceID: "ID de facture",
        refundNow: "Rembourser maintenant",
        enterNameOrScanBarcode: "Entrez le nom ou scannez le code-barres",
        error: "Erreur",
        verify: "Vérifier",
        warning: "Avertissement",
        success: "Succès",
        youHaveSuccessfullyRefund: "Vous avez remboursé avec succès.",
        wrongAmount:
          "Vous avez saisi le montant incorrect. Veuillez saisir le montant correct.",
        paymentFieldRequired: "Le champ de paiement reçu est requis",
        regCloseWarning:
          "Vous ne pouvez pas fermer votre registre. Parce que vous ne pouvez pas ouvrir votre registre.",
        noItemInCart:
          "Il n'y a pas d'articles dans le panier. Veuillez d'abord ajouter des articles au panier.",
        regClosedSuccessTitle: "Vous avez fermé votre registre avec succès.",
        areYouSure: "Êtes-vous sûr !",
        dltTitle: "Voulez-vous supprimer cet enregistrement ?",
        prodDltTitle: "Voulez-vous supprimer ce produit ?",
        dltSuccessTitle: "Supprimé avec succès",
        prodAddTitle: "Le produit a été ajouté avec succès.",
        catDltTitle: "Voulez-vous supprimer cette catégorie ?",
        invAddedTitle: "Inventaire ajouté avec succès.",
        invProdError:
          "Aucun produit disponible. Veuillez d'abord trouver un produit.",
        productNotFound: "Produit non trouvé.",
        splrDltTitle:
          "Voulez-vous vraiment supprimer ce fournisseur ? Si vous supprimez ce fournisseur, toutes les références d'achats seront supprimées.",
        stockTransferDltTitle:
          "Voulez-vous supprimer cet enregistrement de transfert ?",
        userDltUser: "Voulez-vous supprimer cet utilisateur ?",
        warningMsg: "Quelque chose ne va pas. Veuillez réessayer.",
        userNotSelectedError:
          "Aucun utilisateur n'est sélectionné. Veuillez d'abord sélectionner un utilisateur.",
        userDltTitle: "Voulez-vous supprimer cet utilisateur ?",
        userDltSuccessTitle: "Vous avez supprimé avec succès l'utilisateur",
        frgtCodeSentTitle:
          "Le code de vérification à 6 chiffres a été envoyé à cet e-mail",
        refundInvIdReq:
          "L'ID de facture est requis. Veuillez entrer l'ID de facture.",

        // Dropdown List
        male: "Homme",
        female: "Femme",
        other: "Autre",
        today: "Aujourd'hui",
        thisWeek: "Cette semaine",
        thisMonth: "Ce mois-ci",
        thisYear: "Cette année",
        box: "Boîte",
        piece: "Pièce",
        fullPaid: "Entièrement payé",
        due: "Dû",
        cash: "Espèces",
        yes: "Oui",
        no: "Non",
        dateExpire: "Date d'expiration",
        noSale: "Aucune vente",
        extra: "Supplémentaire",
        damage: "Dommage",
        badItem: "Mauvais article",
        mr: "M.",
        mrs: "Mme",
        miss: "Mlle",
        daily: "Quotidien",
        monthly: "Mensuel",
        yearly: "Annuel",
        admin: "Admin",
        manager: "Responsable",
        brands: "Marques",
      },
    },
    es: {
      translation: {
        multPay: "Pago Multimoneda",
        multPayment: "Pago Multimoneda",
        pleaseEnterDueDate: "Por favor, ingrese la fecha de vencimiento",
        pleaseFirstSelectAmt: "Por favor, seleccione primero la cantidad",
        amountToBePaid: "Monto a pagar",
        multcurrency: "Multimoneda",
        paidLocal: "Pago local",
        addPayment: "Agregar pago",
        rate: "Tasa",
        currentRate: "Tasa actual",
        rateIsRequired: "La tasa es requerida",
        addDateIsReq: "La fecha de adición es obligatoria",
        thisFieldIsReq: "Este campo es obligatorio",
        newRate: "Nueva tasa",
        currencyIsRequired: "La moneda es obligatoria",
        symbolIsRequired: "El símbolo es obligatorio",
        currencies: "Monedas",
        addCurrency: "Agregar moneda",
        symbol: "Símbolo",
        lastUpdate: "Última actualización",
        currencyUnit: "Unidad monetaria",
        currencySubunit: "Subunidad monetaria",

        sale: "Venta",
        keepNoteIfYouWant: "Tome nota si lo desea...",
        addChangeAsCreditBalance: "Agregar cambio como saldo a favor",
        additionalDiscount: "Descuento adicional",
        amountToPay: "Monto a pagar",
        enterAmount: "Ingrese la cantidad",
        useCredit: "Usar crédito",
        storeCredit: "Crédito de la tienda",
        selectCustomer: "Seleccionar cliente",
        dueDateIsRequired: "Se requiere la fecha de vencimiento",
        cstmrNotHaveAccBal: "El cliente no tiene saldo en la cuenta.",
        pleaseFirstSelectCustomer: "Por favor, primero seleccione un cliente.",
        checkout: "Pagar",
        dueAmountIsRequired: "Se requiere el monto debido",
        nextDueDateIsRequired: "Se requiere la próxima fecha de vencimiento",
        youHavePutedWrongAmount: "Ha ingresado una cantidad incorrecta",
        dueAmount: "Monto a pagar",
        details: "Detalles",
        receipt: "Recibo",
        transaction: "Transacción",
        settings: "Configuraciones",
        loading: "Cargando",
        account: "Cuenta",
        customerId: "ID de cliente",
        accountBalanceType: "Tipo de saldo de la cuenta",
        id: "ID",
        importExcel: "Importar Excel",
        exportCSV: "Exportar CSV",
        cstmrDltTitle: "¿Realmente desea eliminar este perfil de cliente?",
        customer: "Cliente",
        customers: "Clientes",
        addCustomer: "Agregar cliente",
        yesWithNoBalanceLimit: "Sí, sin límite de saldo",
        yesButWithABalanceLimit: "Sí, pero con un límite de saldo",
        customerCode: "Código de cliente",
        hasCard: "Tiene tarjeta",
        contact: "Contacto",
        card: "Tarjeta de crédito/débito",
        generalFields: "Campos generales",
        addressFields: "Campos de dirección",
        additionalFields: "Campos adicionales",
        generateCustomerId: "Generar ID de cliente",
        allowAnOnAccountBalance: "Permitir un saldo en cuenta",
        balanceLimit: "Límite de saldo",
        country: "País",
        address1: "Dirección línea 1",
        address2: "Dirección línea 2",
        twitter: "Twitter",
        website: "Sitio web",
        profilePicture: "Foto de perfil",
        nameOnCard: "Nombre en la tarjeta",
        cardNumber: "Número de tarjeta",
        expiryDate: "Fecha de vencimiento",
        securityCode: "Código de seguridad",
        balance: "Saldo",
        totalSpent: "Total gastado",
        last12Months: "Últimos 12 meses",
        history: "Historial",
        customerScince: "Cliente desde",
        personalDetails: "Detalles personales",
        birthday: "Cumpleaños",
        yearsOld: "Años",
        cardPayment: "Pago con tarjeta",
        debit: "Débito",
        accountBalance: "Saldo de la cuenta:",
        accounts: "Cuentas",
        dueReceipt: "Recibo a pagar",
        sellType: "Tipo de venta",
        totalPayment: "Total de pagos",
        dueDate: "Fecha de vencimiento",
        makePayment: "Realizar pago",
        totalSales: "Ventas totales",
        sales: "Ventas",
        sells: "Ventas",
        purchase: "Compra",
        purchases: "Compras",
        purchasesReturn: "Devoluciones de compras",
        suppliers: "Proveedores",
        purchaseProducts: "Comprar productos",
        addNewProduct: "Agregar nuevo producto",
        profit: "Ganancia",
        expense: "Gasto",
        monthlySalesReports: "Informes de ventas mensuales",
        yearlySales: "Ventas anuales",
        monthlySales: "Ventas mensuales",
        weeklySales: "Ventas semanales",
        dailySales: "Ventas diarias",
        numberOfItemSold: "Número de artículos vendidos",
        sellPrice: "Precio de venta",
        purchasePrice: "Precio de compra",
        totalExpense: "Gasto total",
        profitBreakdown: "Desglose de ganancias",
        purchaseSellsReports: "Informes de compras y ventas",
        salesByEmployees: "Ventas por empleados",
        storeExpenses: "Gastos de la tienda",
        inStoreInventoryValue: "Valor del inventario en la tienda",
        top20SellingProducts: "Top 20 de productos más vendidos",
        productName: "Nombre del producto",
        unitSell: "Venta por unidad",
        unitCost: "Costo unitario",
        unitProfit: "Ganancia unitaria",
        totalOnStock: "Total en stock",
        top10ProfitMakingItems: "Top 10 de artículos más rentables",
        inventoryValue: "Valor del inventario",
        inInventory: "En inventario",
        newStockIsRequired: "Se requiere nuevo stock.",
        quantity: "Cantidad",
        subtotal: "Subtotal",
        unitPrice: "Precio unitario",
        store: "Tienda",
        updated: "Actualizado",
        added: "Añadido",
        printBarcode: "Imprimir código de barras",
        edit: "Editar",
        delete: "Eliminar",
        addProduct: "Agregar producto",
        generateBarcode: "Generar código de barras",
        actions: "Acciones",
        name: "Nombre",
        barcode: "Código de barras",
        category: "Categoría",
        subcategory: "Subcategoría",
        print: "Imprimir",
        addNew: "Agregar nuevo",
        categoryName: "Nombre de la categoría",
        seeYouAgain: "¡Hasta luego!",
        thanksForYourPurchase: "Gracias por tu compra",
        qty: "Cant.",
        unitType: "Tipo de unidad",
        tax: "Impuesto",
        totalPay: "Total a pagar",
        pay: "Pagar",
        paymentReceived: "Pago recibido",
        now: "Ahora",
        profitMargin: "Margen de ganancia",
        alertQty: "Cantidad de alerta",
        nameIsRequired: "Se requiere nombre.",
        barcodeIsRequired: "Se requiere código de barras.",
        unitTypeIsTequired: "Se requiere tipo de unidad.",
        taxIsRequired: "Se requiere impuesto.",
        purchasePriceIsIequired: "Se requiere precio de compra.",
        sellPriceIsRequired: "Se requiere precio de venta.",
        productDescription: "Descripción del producto",
        productImages: "Imágenes del producto",
        categoryNameIsRequired: "Se requiere el nombre de la categoría.",
        productDetails: "Detalles del producto",
        companyName: "Nombre de la empresa",
        expenseNameIsRequired: "Se requiere el nombre del gasto.",
        expenseCategory: "Categoría de gastos",
        expenseName: "Nombre del gasto",
        expenseDescription: "Descripción del gasto",
        product: "Producto",
        add: "Agregar",
        update: "Actualizar",
        view: "Ver",
        close: "Cerrar",
        saveAndExit: "Guardar y salir",
        saveAndAddAnother: "Guardar y agregar otro",
        saveChanges: "Guardar cambios",
        addCategory: "Agregar categoría",
        description: "Descripción",
        parentCategory: "Categoría principal",
        submit: "Enviar",
        addInventory: "Agregar inventario",
        searchByBarcode: "Buscar por código de barras",
        newStock: "Nuevo stock",
        productId: "ID del producto",
        inStock: "En stock",
        addedDetails: "Detalles agregados",
        userName: "Nombre de usuario",
        inventoryAdded: "Inventario agregado",
        date: "Fecha",
        addPurchases: "Agregar compras",
        referenceNo: "Número de referencia",
        supplier: "Proveedor",
        totalItem: "Total de artículos",
        netAmount: "Monto neto",
        amount: "Cantidad",
        totalPurchase: "Compra total",
        purchaseDate: "Fecha de compra",
        recept: "Recibo",
        viewRecept: "Ver recibo",
        paymentType: "Tipo de pago",
        paymentDue: "Pago pendiente",
        discount: "Descuento",
        uploadReceipt: "Cargar recibo",
        purchaseDetails: "Detalles de la compra",
        supplierIsRequired: "Se requiere proveedor.",
        paymentTypeIsRequired: "Se requiere tipo de pago.",
        emptyPurchaseList: "Lista de compra vacía",
        purchWarning:
          "No puedes enviar tu solicitud. Porque tu lista de compras está vacía.",

        searchByNameOrBarcode: "Buscar por nombre o código de barras",
        itemName: "Nombre del artículo",
        price: "Precio",
        purchaseUser: "Usuario de compra",
        purchaseRecept: "Recibo de compra",
        total: "Total",
        totalPrice: "Precio total",
        dateOfBirth: "Fecha de nacimiento",
        gender: "Género",
        phone: "Teléfono",
        userRole: "Rol de usuario",
        inStore: "En tienda",
        role: "Rol",
        isActive: "Está activo",
        login: "Iniciar sesión",
        back: "Volver",
        password: "Contraseña",
        verificationCode: "Código de verificación",
        confirmPassword: "Confirmar contraseña",
        forgotPassword: "Olvidé mi contraseña",
        userNameIsRequired: "Se requiere nombre de usuario",
        passwordAndConfirmPasswordNotMatch:
          "La contraseña y la confirmación de la contraseña no coinciden",
        sendVerificationCode: "Enviar código de verificación",
        confirmPasswordIsRequired: "Se requiere confirmación de contraseña",
        salaryScale: "Escala salarial",
        salaryType: "Tipo de salario",
        changePassword: "Cambiar contraseña",
        salary: "Salario",
        welcome: "Bienvenido",
        SSN: "Número de Seguro Social",
        successfullySetAsADefaultStore:
          "Establecido como tienda predeterminada con éxito",
        somethingIsWrongPleaseTryAgain:
          "Algo está mal. Por favor, inténtalo de nuevo.",
        myStores: "Mis tiendas",
        submitToDefaultStore: "Enviar a la tienda predeterminada",
        switchToAnotherStore: "Cambiar a otra tienda",
        storeDltWarning:
          "Actualmente, estás en esta tienda. Por favor, primero cambia a otra tienda y luego inténtalo de nuevo.",

        invalidReferenceNo: "Número de referencia no válido",
        referenceNoRsRequired: "Se requiere el número de referencia.",
        emptyReturnList: "Lista de devoluciones vacía",
        returnPurchWarning:
          "No puedes enviar tu solicitud. Porque tu lista de devoluciones está vacía.",
        purchaseReturn: "Devolución de compra",
        returnDate: "Fecha de devolución",
        returnPurchases: "Devoluciones de compras",
        returnReason: "Motivo de devolución",
        addPurchaseReturn: "Agregar devolución de compra",
        returnValue: "Valor de devolución",
        returnItemDetails: "Detalles de la devolución del artículo",
        returnUser: "Usuario de devolución",
        firstNameIsRequired: "Se requiere el nombre.",
        lastNameIsRequired: "Se requiere el apellido.",
        phoneIsRequired: "Se requiere el teléfono.",
        companyNameIsRequired: "Se requiere el nombre de la empresa.",
        emailIsNotValid: "El correo electrónico no es válido.",
        emailIsRequired: "Se requiere el correo electrónico.",
        prefixIsRequired: "Se requiere el prefijo.",
        genderIsRequired: "Se requiere el género.",
        languageIsRequired: "Se requiere el idioma.",
        roleIsRequired: "Se requiere el rol.",
        isActiveIsRequired: "Se requiere el estado.",
        passwordIsRequired: "Se requiere la contraseña",
        confirmPassIsRequired: "Se requiere la confirmación de la contraseña",
        firstName: "Nombre",
        lastName: "Apellido",
        email: "Correo electrónico",
        productsSale: "Venta de productos",
        city: "Ciudad",
        state: "Estado",
        ZIP: "Código ZIP",
        address: "Dirección",
        note: "Nota",
        itsTooDangerous: "Es demasiado peligroso",
        addSupplier: "Agregar proveedor",
        contractPerson: "Persona de contacto",
        addExpense: "Agregar gasto",
        transectionDate: "Fecha de transacción",
        invoice: "Factura",
        paymenReceived: "Pago recibido",
        change: "Cambiar",
        clear: "Limpiar",
        cashier: "Cajero",
        saleDetails: "Detalles de la venta",
        amountIsRequired: "Se requiere cantidad.",
        cashInDrawer: "Dinero en caja",
        transferDateIsRequired: "Se requiere la fecha de transferencia.",
        fromLocationIsRequired: "Se requiere la ubicación de origen.",
        toLocationIsRequired: "Se requiere la ubicación de destino.",
        outOfStock: "Fuera de stock",
        outOfStockWarning:
          "No puedes transferir este producto. Porque no está disponible en tu inventario.",
        stockTransfer: "Transferencia de inventario",
        transferDate: "Fecha de transferencia",
        locationFrom: "Ubicación (De)",
        locationTo: "Ubicación (A)",
        transferDetails: "Detalles de la transferencia",
        addTransfer: "Agregar transferencia",
        transferItems: "Transferir artículos",
        employeeName: "Nombre del empleado",
        totalSale: "Venta total",
        voidSale: "Venta anulada",
        refund: "Reembolso",
        itemCancel: "Cancelar artículo",
        paidBy: "Pagado por",
        paid: "Pagado",
        detailsNote: "Nota de detalles",
        expenseType: "Tipo de gasto",
        lastMonthSale: "Venta del mes pasado",
        currentMonthSale: "Venta actual del mes",
        lottery: "Lotería",

        hight: "alto",
        noTax: "Sin impuestos",
        notes: "Notas",
        low: "Bajo",
        totalSold: "Total vendido",
        purchaseBy: "Comprado por",
        supplierName: "Nombre del proveedor",
        craditSale: "Venta a crédito",
        cashSale: "Venta en efectivo",
        cashierName: "Nombre del cajero",
        closeTime: "Hora de cierre",
        openTime: "Hora de apertura",
        returnBy: "Devuelto por",
        searchByMonth: "Buscar por mes",
        grossProfit: "Ganancia bruta",
        totalSellPrice: "Precio total de venta",
        totalPurchasePrice: "Precio total de compra",
        netProfit: "Ganancia neta",
        grossSell: "Venta bruta",
        totalPurchese: "Compra total",
        totalExpences: "Gastos totales",
        openingInventoryValue: "Valor del inventario inicial",
        newInventoryAdded: "Nuevo inventario agregado",
        inventorySold: "Inventario vendido",
        currentInventoryValue: "Valor actual del inventario",
        storeDetails: "Detalles de la tienda",
        totalItemSold: "Total de artículos vendidos",
        totalItemCancelledFromCart: "Total de artículos cancelados del carrito",
        totalRefund: "Reembolso total",
        totalVoidSale: "Total de ventas anuladas",
        items: "Artículos",
        expenses: "Gastos",
        closeRegister: "Cerrar registro",
        inventory: "Inventario",
        itemSold: "Artículo vendido",
        registerDetails: "Detalles del registro",
        cashierDetails: "Detalles del cajero",
        storeName: "Nombre de la tienda",
        saleDate: "Fecha de venta",
        timeIn: "Hora de entrada",
        expenseTypeIsRequired: "Se requiere tipo de gasto",
        timeOut: "Hora de salida",
        credit: "Crédito",
        productsSoldByCategory: "Productos vendidos por categoría",
        totalCustomers: "Total de clientes",
        averagePerCustomer: "Promedio por cliente",
        totalSaleRefund: "Reembolso total de ventas",
        totalVoidSells: "Total de ventas anuladas",
        totalItemCanceled: "Total de artículos cancelados",
        saleReport: "Informe de ventas",
        cancel: "Cancelar",
        totalCashSale: "Venta total en efectivo",
        totalCreditSale: "Venta total a crédito",
        otherPaymentReceived: "Otros pagos recibidos",
        sellDetails: "Detalles de la venta",
        totalGrossSale: "Total de ventas brutas",
        totalTaxItemSold: "Total de artículos vendidos con impuestos",
        totalNonTaxItemSold: "Total de artículos vendidos sin impuestos",
        totalTaxReceived: "Total de impuestos recibidos",
        money: "Dinero",
        shiftTransactionsBalance: "Saldo de transacciones de turno",
        addUser: "Agregar usuario",
        activeStatus: "Estado activo",
        userNameIsAlreadyExist: "El nombre de usuario ya existe",
        user: "Usuario",
        userInformation: "Información del usuario",
        prefix: "Prefijo",
        language: "Idioma",
        rolesAndPermissions: "Roles y permisos",
        payTypeIsRequired: "Se requiere tipo de pago.",
        totalIsRequired: "Se requiere total.",
        bill: "Factura",
        payType: "Tipo de pago",
        addPayBill: "Agregar factura de pago",
        hireDate: "Fecha de contratación",
        totalSalary: "Salario total",
        totalWorkHour: "Total de horas trabajadas",
        payPeriod: "Período de pago",
        fromDateIsRequired: "Se requiere fecha de inicio.",
        toDateIsRequired: "Se requiere fecha de finalización.",
        totalSalaryIsRequired: "Se requiere salario total.",
        payDateIsRequired: "Se requiere fecha de pago.",
        salaryPaymentForm: "Formulario de pago de salario",
        errors: "Errores",
        searchEmployeeByNameOrPhone: "Buscar empleado por nombre o teléfono",
        fromDate: "Desde la fecha",
        toDate: "Hasta la fecha",
        totalHours: "Total de horas",
        payDate: "Fecha de pago",
        allItems: "Todos los artículos",
        storeNameIsRequired: "Se requiere el nombre de la tienda.",
        taxPercentageIsRequired: "Se requiere porcentaje de impuestos.",
        managerDetails: "Detalles del gerente",
        taxPercentage: "Porcentaje de impuestos",
        logo: "Logotipo",
        addStore: "Agregar tienda",
        managerName: "Nombre del gerente",
        organizationDetailsUpdatedSuccessfully:
          "Detalles de la organización actualizados con éxito",
        editOrganizationDetails: "Editar detalles de la organización",
        organizationName: "Nombre de la organización",
        localeOrgName: "Nombre local de la organización",
        currency: "Moneda",
        notFound: "No encontrado",
        dashboard: "Tablero",
        products: "Productos",
        categories: "Categorías",
        storeReport: "Informe de la tienda",
        productReport: "Informe de productos",
        purchaseReport: "Informe de compras",
        registerReport: "Informe del registro",
        expenseReport: "Informe de gastos",
        return: "Devolución",
        topSelling: "Más vendidos",
        employeeReport: "Informe del empleado",
        productsSellVolume: "Volumen de venta de productos",
        HR: "Recursos Humanos",
        payBills: "Pagar facturas",
        salaryPayment: "Pago de salario",
        salaryHistory: "Historial de salarios",
        employeeHistory: "Historial del empleado",
        reports: "Informes",
        master: "Maestro",
        organization: "Organización",
        defaultStore: "Tienda predeterminada",
        POSItem: "Artículo de POS",
        logout: "Cerrar sesión",
        invoiceNo: "Número de factura",
        POS: "POS",
        sellDate: "Fecha de venta",
        verificationCodeIsRequired: "Se requiere código de verificación.",
        enterVerificationCode: "Introducir código de verificación",
        resendCode: "Reenviar código",

        verificationCodeSentTitle:
          "El código de verificación de 6 dígitos se ha enviado a este correo electrónico",
        storeDltTitle:
          "¿Realmente desea eliminar esta tienda? Si elimina esta tienda, se eliminarán todos los datos de esta tienda.",
        successfully: "Exitosamente",
        enter: "Entrar",
        voidSell: "Anular venta",
        printLastSale: "Imprimir última venta",
        home: "Inicio",
        successful: "Exitoso",
        youHaveSuccessfullyVoidThisSale: "Has anulado con éxito esta venta.",
        youWantToVoidThisSell: "¿Quieres anular esta venta?",
        thereIsNoLastSaleData: "No hay datos de la última venta.",
        cashRegisterReport: "Informe de caja registradora",
        others: "Otros",
        detailsOfProductsSold: "Detalles de los productos vendidos",
        invoiceID: "ID de factura",
        refundNow: "Reembolsar ahora",
        enterNameOrScanBarcode:
          "Ingrese el nombre o escanee el código de barras",
        error: "Error",
        verify: "Verificar",
        warning: "Advertencia",
        success: "Éxito",
        youHaveSuccessfullyRefund: "Has reembolsado con éxito.",
        wrongAmount:
          "Has ingresado la cantidad incorrecta. Por favor, ingresa la cantidad correcta.",
        paymentFieldRequired: "Se requiere el campo de pago recibido",
        regCloseWarning:
          "No puedes cerrar tu registro. Porque no puedes abrir tu registro.",
        noItemInCart:
          "No hay artículos en el carrito. Por favor, primero agrega algunos artículos al carrito.",
        regClosedSuccessTitle: "Has cerrado tu registro con éxito.",
        areYouSure: "¿Estás seguro!",
        dltTitle: "¿Quieres eliminar este registro?",
        prodDltTitle: "¿Quieres eliminar este producto?",
        dltSuccessTitle: "Eliminado exitosamente",
        prodAddTitle: "El producto se ha agregado con éxito.",
        catDltTitle: "¿Quieres eliminar esta categoría?",
        invAddedTitle: "Inventario agregado con éxito.",
        invProdError:
          "No hay productos disponibles. Por favor, encuentra primero un producto.",
        productNotFound: "Producto no encontrado.",
        splrDltTitle:
          "¿Realmente deseas eliminar a este proveedor? Si eliminas a este proveedor, se eliminarán todas las compras de referencia.",
        stockTransferDltTitle:
          "¿Quieres eliminar este registro de transferencia?",
        userDltUser: "¿Quieres eliminar a este usuario?",
        warningMsg: "Algo está mal. Por favor, inténtalo de nuevo.",
        userNotSelectedError:
          "No hay usuario seleccionado. Por favor, primero selecciona un usuario.",
        userDltTitle: "¿Quieres eliminar a este usuario?",
        userDltSuccessTitle: "Has eliminado con éxito al usuario",
        frgtCodeSentTitle:
          "El código de verificación de 6 dígitos ha sido enviado a este correo electrónico",
        refundInvIdReq:
          "Se requiere el ID de factura. Por favor, ingrese el ID de factura.",

        // Dropdown List
        male: "Masculino",
        female: "Femenino",
        other: "Otro",
        today: "Hoy",
        thisWeek: "Esta semana",
        thisMonth: "Este mes",
        thisYear: "Este año",
        box: "Caja",
        piece: "Pieza",
        fullPaid: "Pago completo",
        due: "Pendiente",
        cash: "Efectivo",
        yes: "Sí",
        no: "No",
        dateExpire: "Fecha de vencimiento",
        noSale: "Sin venta",
        extra: "Extra",
        damage: "Daño",
        badItem: "Artículo defectuoso",
        mr: "Sr.",
        mrs: "Sra.",
        miss: "Srta.",
        daily: "Diario",
        monthly: "Mensual",
        yearly: "Anual",
        admin: "Administrador",
        manager: "Gerente",
        brands: "Marcas",
      },
    },
    // Add translations for other languages as needed
  },
});

export default i18n;
