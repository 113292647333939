import {
  CancelRounded,
  CheckCircleRounded,
  CloseRounded,
  LocalPrintshopRounded,
} from "@mui/icons-material";
import {
  Divider,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../App";
import { controller, endpoints } from "../../api/actions/api";
import { connect } from "react-redux";
import * as actions from "../../api/actions/pos";
import { Button } from "../../controls";
import SellReturnReceipt from "./SellReturnReceipt";
import { PrintDiv } from "../../hooks/Method";

const ViewSellReturn = (props) => {
  const { setShowForm, id, _getById } = props;
  const { t } = useTranslation();
  const { org } = useContext(AppContext);
  const [cashier, setCashier] = useState();
  const [value, setValue] = useState({});

  useEffect(() => {
    if (id) {
      _getById(endpoints.SellReturn + controller.GetById, id, null, (res) => {
        if (res.status === 200 && res.result && res.result.id) {
          var _result = res.result;
          setValue(_result);
          setCashier(_result.user.firstName + " " + _result.user.lastName);
        }
      });
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handlePrint = () => {
    PrintDiv("printSellReturnRecept");
  };

  return (
    <>
      <div className="d-none" id="printSellReturnRecept">
        <SellReturnReceipt data={value} />
      </div>
      {org && value.id ? (
        <div>
          <div className="mb-10 d-flex j-between a-center pt-10 pl-10 pr-10">
            <div>
              <h1 className="form-title ml-10 fw-4 gray-color tx-upp border-left">
                {t("saleDetails")}
              </h1>
            </div>

            <div className="d-flex g-10">
              <div>
                <Button
                  onClick={() => handlePrint(value)}
                  startIcon={<LocalPrintshopRounded />}
                  size="small"
                  className=""
                >
                  {t("printReceipt")}
                </Button>
              </div>

              <IconButton onClick={() => setShowForm(false)}>
                <CloseRounded />
              </IconButton>
            </div>
          </div>
          <Divider />
          <Grid container>
            <Grid item xs={12}>
              <TableContainer className="mt-20">
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell>{t("itemName")}</TableCell>
                      <TableCell>{t("quantity")}</TableCell>
                      <TableCell>{t("price")}</TableCell>
                      <TableCell>{t("total")}</TableCell>
                      <TableCell>{t("isSellable")}?</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {value.items.map((item, i) => (
                      <TableRow key={i}>
                        <TableCell>{item.product.name}</TableCell>
                        <TableCell>{item.returnQty}</TableCell>
                        <TableCell>
                          {org.curSymbol +
                            " " +
                            item.price.toLocaleString("en-US", {
                              maximuFractionDigits: 2,
                            })}
                        </TableCell>
                        <TableCell>
                          {org.curSymbol +
                            " " +
                            (item.returnQty * item.price).toLocaleString(
                              "en-US",
                              {
                                maximuFractionDigits: 2,
                              }
                            )}
                        </TableCell>
                        <TableCell>
                          {item.isSellable ? (
                            <CheckCircleRounded color="success" />
                          ) : (
                            <CancelRounded color="error" />
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Divider />
              <Divider />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              className="w-100pr"
              sx={{
                borderRight: { sm: "1px solid var(--grayLight-color)" },
              }}
            >
              <TableContainer>
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell>{t("returnId")}</TableCell>
                      <TableCell align="right">{value.id}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>{t("invoiceID")}</TableCell>
                      <TableCell align="right">{value.sellId}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>{t("cashier")}</TableCell>
                      <TableCell align="right">{cashier}</TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell>{t("sellDate")}</TableCell>
                      <TableCell align="right">
                        {value.sell && value.sell.sellDate}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>{t("returnDate")}</TableCell>
                      <TableCell align="right">
                        {value.sell && value.refundDate}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={12} sm={6} className="w-100pr">
              <TableContainer>
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell>{t("totalInvoiceAmount")}</TableCell>
                      <TableCell align="right">
                        {org.curSymbol +
                          " " +
                          (
                            parseFloat(value.sell.totalPay) +
                            parseFloat(value.sell.multiPayAmt)
                          ).toLocaleString("en-US", {
                            maximumFractionDigits: 2,
                          })}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>{t("refund")}</TableCell>
                      <TableCell align="right">
                        {"(-)"}{" "}
                        {org.curSymbol +
                          " " +
                          value.refundAmt.toLocaleString("en-US", {
                            maximumFractionDigits: 2,
                          })}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>{t("discount")}</TableCell>
                      <TableCell align="right">
                        {"(-)"}{" "}
                        {org.curSymbol +
                          " " +
                          value.cutDiscount.toLocaleString("en-US", {
                            maximumFractionDigits: 2,
                          })}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>{t("tax")}</TableCell>
                      <TableCell align="right">
                        {"(+)"} {org.curSymbol + " " + value.refundTaxAmt}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="primary-color">
                        {t("payableRefund")}
                      </TableCell>
                      <TableCell align="right" className="primary-color">
                        {org.curSymbol +
                          " " +
                          value.payableRefund.toLocaleString("en-US", {
                            maximumFractionDigits: 2,
                          })}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </div>
      ) : (
        "Loading..."
      )}
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapActionToProps = {
  _getById: actions.getById,
};

export default connect(mapStateToProps, mapActionToProps)(ViewSellReturn);
