import React, { useContext, useEffect } from "react";
import { connect } from "react-redux";
import { Dialog, Table } from "../../layout";
import * as actions from "../../api/actions/pos";
import { AppContext } from "../../App";
import { useState } from "react";
import { controller, endpoints } from "../../api/actions/api";
import { Box, IconButton, Tooltip } from "@mui/material";
import { VisibilityRounded } from "@mui/icons-material";
import ViewRegister from "./ViewRegister";
import { useTranslation } from "react-i18next";
import { ChangeArrayKey } from "../../hooks/Method";
import { CSVLink } from "react-csv";

const RegReport = ({ _getById }) => {
  const { t } = useTranslation();
  const { setLoader, selStore, org } = useContext(AppContext);
  const [regs, setRegs] = useState([]);
  const [viewData, setViewData] = useState();
  const [showForm, setShowForm] = useState(false);
  const [csvHeader, setCsvHeader] = useState([]);
  const [tblData, setTblData] = useState([]);

  const onComplete = (res) => {
    setLoader(false);
    res.status === 200 && res.result.length > 0 && setRegs(res.result);
  };

  useEffect(() => {
    if (selStore) {
      setLoader(true);
      _getById(
        endpoints.Register + controller.GetRegsByStore,
        selStore,
        null,
        onComplete
      );
    }
    return () => {
      setRegs([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selStore]);

  useEffect(() => {
    setCsvHeader([
      { key: "date", label: t("date") },
      { key: "openTime", label: t("openTime") },
      { key: "closeTime", label: t("closeTime") },
      { key: "cashierName", label: t("cashierName") },
      { key: "totalSale", label: t("totalSale") },
      { key: "totalCash", label: t("cashSale") },
      { key: "totalCradit", label: t("craditSale") },
      { key: "totalRefund", label: t("refund") },
      { key: "totalVoidSale", label: t("voidSale") },
      { key: "itemCancel", label: t("itemCancel") },
    ]);

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleView = (data) => {
    setViewData(data);
    setShowForm(true);
  };

  useEffect(() => {
    setTblData([
      ...regs.map((reg) => ({
        ...reg,
        date: new Date(reg.openTime).toLocaleDateString(),
        openTime: new Date(reg.openTime).toLocaleTimeString(),
        closeTime: new Date(reg.closeTime).toLocaleTimeString(),
        cashierName: reg.user.firstName + " " + reg.user.lastName,
        averagePerCstmr:
          (org && org.curSymbol) +
          " " +
          reg.averagePerCstmr.toLocaleString("en-US", {
            maximumFractionDigits: 2,
          }),
        totalSale:
          (org && org.curSymbol) +
          " " +
          reg.totalSale.toLocaleString("en-US", { maximumFractionDigits: 2 }),
        totalCash:
          (org && org.curSymbol) +
          " " +
          reg.totalCash.toLocaleString("en-US", { maximumFractionDigits: 2 }),
        totalCradit:
          (org && org.curSymbol) +
          " " +
          reg.totalCradit.toLocaleString("en-US", { maximumFractionDigits: 2 }),
        totalRefund:
          (org && org.curSymbol) +
          " " +
          reg.totalRefund.toLocaleString("en-US", { maximumFractionDigits: 2 }),
        totalVoidSale:
          (org && org.curSymbol) +
          " " +
          reg.totalVoidSale.toLocaleString("en-US", {
            maximumFractionDigits: 2,
          }),
      })),
    ]);
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regs]);

  const ActionComp = (props) => (
    <Box>
      <Tooltip arrow title={t("view")}>
        <IconButton
          onClick={() => handleView(props)}
          size="small"
          className="btn-primary mr-5"
        >
          <VisibilityRounded className="fs-18 m-2" />
        </IconButton>
      </Tooltip>
    </Box>
  );

  const HeaderComp = () => (
    <div className="d-flex flex-wrap g-5">
      <Box
        className="d-flex"
        sx={{
          width: { xs: "100%", md: "auto" },
        }}
      >
        <CSVLink
          data={tblData}
          headers={csvHeader}
          filename={`${t("registerReport")} (${new Date().toDateString()}).csv`}
          target="_blank"
          className="d-flex a-center j-center p-15 fs-16 fw-5 tx-upp primary-color bg-primarySM br-15"
        >
          {t("exportCSV")}
        </CSVLink>
      </Box>
    </div>
  );

  return (
    <>
      <Table
        ActionComp={ActionComp}
        HeaderComp={HeaderComp}
        tblHead={[
          { id: "actions", label: t("actions") },
          ...ChangeArrayKey(csvHeader, "key", "id"),
        ]}
        tblData={tblData}
      />

      {/* Edit Dialog */}
      {viewData && (
        <Dialog show={showForm} onClose={false} maxWidth="md">
          <ViewRegister {...{ setShowForm, viewData }} />
        </Dialog>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  _store: state.pos.defStore,
});

const mapActionToProps = {
  _getById: actions.getById,
};

export default connect(mapStateToProps, mapActionToProps)(RegReport);
