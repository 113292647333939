import {
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";

const SimpleTable = ({ list = [] }) => {
  return (
    <TableContainer>
      <Table size="small" aria-label="a dense table">
        <TableHead>
          {list
            .filter((j) => !j.disable)
            .map((item, ind) => (
              <TableRow key={ind}>
                <TableCell className="fs-16 gray-color">
                  {item.label}&nbsp;:
                </TableCell>
                <TableCell className="fs-16 gray-color" align="right">
                  {item.value}
                </TableCell>
              </TableRow>
            ))}
        </TableHead>
      </Table>
    </TableContainer>
  );
};

export default SimpleTable;
