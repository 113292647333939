import { Grid } from "@mui/material";
import React from "react";

const Label = ({ name, value }) => {
  return (
    <>
      <Grid item xs={12} md={4} className="d-grid">
        <span className="tx-xs tx-upp mb-5 gray-color">{name}</span>
        <span className="fs-16 fw-4">{value}</span>
      </Grid>
    </>
  );
};

export default Label;
