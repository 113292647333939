import {
  CrisisAlertRounded,
  CurrencyExchangeRounded,
  HomeRounded,
  LocalPrintshopRounded,
} from "@mui/icons-material";
import { Paper } from "@mui/material";
import React, { useState } from "react";
import { useContext } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { controller, endpoints } from "../../api/actions/api";
import * as actions from "../../api/actions/pos";
import { AppContext } from "../../App";
import { Button } from "../../controls";
import { NestedFormData, PrintDiv, RmvEmptyProp } from "../../hooks/Method";
import { useTranslation } from "react-i18next";
import { Dialog } from "../../layout";
import ReturnDialog from "../sellReturn/ReturnDialog";

const Actions = (props) => {
  const {
    _get,
    _post,
    handleClearCart,
    _authUser,
    setReceptData,
    cartItems,
    subTotal,
    tax,
    setAlert,
  } = props;
  const { setCLoader, selStore } = useContext(AppContext);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [refundForm, setRefundForm] = useState(false);

  const handlePrintLastSale = () => {
    const onComplete = (res) => {
      if (res.status === 200 && res.result !== null) {
        setReceptData(res.result);
        setTimeout(() => {
          PrintDiv("printSellRecept");
          setCLoader(false);
        }, 1000);
      } else if (res.result === null)
        setAlert({
          isOpen: true,
          type: "warning",
          title: t("warning"),
          subTitle: t("thereIsNoLastSaleData"),
        });
    };
    _get(endpoints.Sell + controller.GetLastSell, null, onComplete);
  };

  const handleVoidSell = () => {
    const voidVal = {
      items: cartItems.map((j) =>
        j.itemType === 2 ? { ...j, productId: "" } : j
      ),
      tax: tax,
      cancelType: 1,
      subtotal: subTotal,
      userId: _authUser.id,
      storeId: selStore,
    };
    setAlert({
      isOpen: true,
      type: "confirmation",
      title: t("areYouSure"),
      subTitle: t("youWantToVoidThisSell"),
      onConfirm: () => {
        const onComplete = (res) => {
          if (res.status === 201) {
            setAlert({
              isOpen: true,
              type: "success",
              title: t("successful"),
              subTitle: t("youHaveSuccessfullyVoidThisSale"),
            });
            handleClearCart();
          } else {
            setAlert({
              isOpen: true,
              type: "warning",
              title: t("warning"),
              subTitle: res.msg,
            });
          }
        };
        _post(
          endpoints.VoidSell,
          NestedFormData(RmvEmptyProp(voidVal)),
          null,
          onComplete
        );
      },
    });
  };

  const handleRefund = () => {
    setRefundForm(!refundForm);
  };

  return (
    <>
      <Paper
        className="mt-20 shadow-xs p-10 d-flex g-10"
        sx={{
          minHeight: "50px",
          borderRadius: "20px",
          flexWrap: { xs: "wrap", md: "inherit" },
        }}
      >
        <Button
          onClick={() => navigate("/pos")}
          variant="outlined"
          fullWidth
          sx={{
            display: "block",
            "& .MuiButton-startIcon": { mr: 0, ml: 0, lineHeight: 0 },
          }}
          startIcon={<HomeRounded className="fs-26" />}
        >
          <span className="fs-12">{t("home")}</span>
        </Button>
        <Button
          onClick={handlePrintLastSale}
          variant="outlined"
          fullWidth
          sx={{
            display: "block",
            "& .MuiButton-startIcon": { mr: 0, ml: 0, lineHeight: 0 },
          }}
          startIcon={<LocalPrintshopRounded className="fs-22" />}
        >
          <span className="fs-12">{t("printLastSale")}</span>
        </Button>
        <Button
          onClick={handleVoidSell}
          variant="outlined"
          fullWidth
          sx={{
            display: "block",
            "& .MuiButton-startIcon": { mr: 0, ml: 0, lineHeight: 0 },
          }}
          startIcon={<CrisisAlertRounded className="fs-22" />}
        >
          <span className="fs-12">{t("voidSell")}</span>
        </Button>

        <Button
          onClick={handleRefund}
          variant="outlined"
          fullWidth
          sx={{
            display: "block",
            "& .MuiButton-startIcon": { mr: 0, ml: 0, lineHeight: 0 },
          }}
          startIcon={<CurrencyExchangeRounded className="fs-22" />}
        >
          <span className="fs-12">{t("sellReturn")}</span>
        </Button>
      </Paper>

      {/* Refund Form */}
      {refundForm && (
        <Dialog show={refundForm} onClose={false} maxWidth="xs">
          <ReturnDialog {...{ setShowForm: setRefundForm }} />
        </Dialog>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  _authUser: state.pos.authUser,
  _store: state.pos.defStore,
});

const mapActionToProps = {
  _get: actions.get,
  _post: actions.post,
};

export default connect(mapStateToProps, mapActionToProps)(Actions);
