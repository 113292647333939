import { Button } from "@mui/material";
import React from "react";

const ButtonGroup = ({ list, activeVal, onChange }) => {
  return (
    <div className="d-flex ml-10 bg-primarySM br-12">
      {list &&
        list.map((item, ind) => (
          <Button
            key={ind}
            variant="text"
            size="small"
            onClick={() => onChange(item.id)}
            className={`h-100pr fs-16 ${
              item.id === activeVal && "bg-primary white-color"
            }`}
            sx={{ bgcolor: "transparent" }}
          >
            {item.label}
          </Button>
        ))}
    </div>
  );
};

export default ButtonGroup;
