import React, { useContext, useEffect } from "react";
import { ClearRounded, EditRounded } from "@mui/icons-material";
import { Button, IconButton, Tooltip } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import { connect } from "react-redux";
import { Alert, Dialog, Table } from "../../layout";
import * as actions from "../../api/actions/pos";
import { controller, endpoints } from "../../api/actions/api";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../App";
import AddEditMethod from "./AddEditMethod";
import { ChangeArrayKey } from "../../hooks/Method";
import { CSVLink } from "react-csv";

const PayMethods = (props) => {
  const { _getById, _delete } = props;
  const { t } = useTranslation();
  const { org } = useContext(AppContext);
  const [csvHeader, setCsvHeader] = useState([]);
  const [tblData, setTblData] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [updData, setUpdData] = useState();
  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    title: "",
    subTitle: "",
  });

  useEffect(() => {
    setCsvHeader([
      { key: "title", label: t("title") },
      { key: "subtitle", label: t("subtitle") },
      { key: "isAddtlCharge", label: t("addtlCharge") },
      { key: "addtlChargeTitle", label: t("addtlChargeTitle") },
      { key: "addtlCharge", label: t("charge") },
      { key: "createdUser", label: t("createdUser") },
      { key: "createDate", label: t("createDate") },
      { key: "updateDate", label: t("updateDate") },
    ]);

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    org.id &&
      _getById(
        endpoints.PayMethod + controller.GetAllByOrg,
        org.id,
        null,
        (res) =>
          res.status === 200
            ? setTblData([
                ...res.result.map((j) => ({
                  ...j,
                  isAddtlCharge: `${j.isAddtlCharge}`,
                  createdUser:
                    j.user && j.user.firstName + " " + j.user.lastName,
                })),
              ])
            : console.error("Error: ", res.msg)
      );

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [org]);

  const handleAddEdit = (val) => {
    setUpdData(val);
    setShowForm(true);
  };

  const handleDlt = (id) => {
    setAlert({
      isOpen: true,
      type: "confirmation",
      title: t("areYouSure"),
      subTitle: t("methodDltTitle"),
      onConfirm: () => {
        const onComplete = (res) => {
          if (res.status === 200) {
            setTblData((x) => [...x.filter((j) => j.id !== res.result.id)]);
            setAlert({
              isOpen: true,
              type: "success",
              title: t("dltSuccessTitle"),
              subTitle: t("youHaveSuccessfullyDeletedThePayMethod"),
            });
          } else {
            setAlert({
              isOpen: true,
              type: "warning",
              title: t("warning"),
              subTitle: res.msg,
            });
          }
        };
        _delete(endpoints.PayMethod, id, null, onComplete);
      },
    });
  };

  const ActionComp = (props) => (
    <Box className="d-flex a-center g-10">
      <Tooltip arrow title={t("edit")}>
        <IconButton
          onClick={() => handleAddEdit(props)}
          size="small"
          className="primary-color"
        >
          <EditRounded className="fs-18 m-2" />
        </IconButton>
      </Tooltip>
      <Tooltip arrow title={t("delete")}>
        <IconButton
          onClick={() => handleDlt(props.id)}
          size="small"
          color="error"
        >
          <ClearRounded className="fs-18 m-2" />
        </IconButton>
      </Tooltip>
    </Box>
  );

  const HeaderComp = () => (
    <div className="d-flex flex-wrap g-5">
      <Button
        onClick={() => handleAddEdit()}
        className="btn-secondary"
        sx={{
          width: { md: "250px", xs: "100%" },
          mb: { xs: "10px", md: "0px" },
          fontSize: "16px",
          padding: "8px",
        }}
      >
        {t("addPaymentMethod")}
      </Button>
      <CSVLink
        data={tblData}
        headers={csvHeader}
        filename={`${t("paymentMethod")} (${new Date().toDateString()}).csv`}
        target="_blank"
        className="d-flex a-center j-center p-15 fs-16 fw-5 tx-upp primary-color bg-primarySM br-15"
      >
        {t("exportCSV")}
      </CSVLink>
    </div>
  );

  return (
    <>
      <Table
        ActionComp={ActionComp}
        HeaderComp={HeaderComp}
        tblHead={[
          { id: "actions", label: t("actions") },
          ...ChangeArrayKey(csvHeader, "key", "id"),
        ]}
        tblData={[...tblData]}
      />

      {/* Rate */}

      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />

      {/* Edit Dialog */}
      {showForm && (
        <Dialog show={showForm} onClose={false} maxWidth="sm">
          <AddEditMethod {...{ updData, setShowForm, setTblData, setAlert }} />
        </Dialog>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapActionToProps = {
  _getById: actions.getById,
  _delete: actions.Delete,
  _clear: actions.clear,
};

export default connect(mapStateToProps, mapActionToProps)(PayMethods);
