import React, { useContext } from "react";
import ReactApexChart from "react-apexcharts";
import { Paper, Typography } from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../App";
import { connect } from "react-redux";
import * as actions from "../../api/actions/pos";
import { controller, endpoints } from "../../api/actions/api";
import { ToFormData } from "../../hooks/Method";
import moment from "moment";

const TopTenItems = ({ _getByObj }) => {
  const { t } = useTranslation();
  const { org, selStore } = useContext(AppContext);
  const [topItmes, setTopItmes] = useState([]);

  useEffect(() => {
    selStore &&
      _getByObj(
        endpoints.HomeReport + controller.TopProfitMakingItems,
        ToFormData({
          storeId: selStore,
          value: 10,
          date: moment().format("MM/YYYY"),
        }),
        null,
        (res) => {
          res.status === 200 && setTopItmes([...res.result]);
        }
      );

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selStore]);

  const options = {
    chart: {
      type: "bar",
      height: 350,
    },
    dataLabels: {
      formatter: (val) => `${(org && org.curSymbol) + " " + val}`,
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: true,
      },
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: topItmes.map((j) => j[0].product.name),
      labels: {
        formatter: (val) =>
          `${(org && org.curSymbol) + " " + val.toLocaleString()}`,
        style: {
          fontSize: "14px",
        },
      },
    },

    colors: ["#00E396"],
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: (val) => `${(org && org.curSymbol) + " " + val}`,
      },
    },
  };
  const series = [
    {
      name: t("profit"),
      data: topItmes.map((j) =>
        j
          .map((j) =>
            Math.round(j.qty * (j.product.sellPrice - j.product.purchPrice))
          )
          .reduce((x, y) => x + y, 0)
          .toFixed(2)
      ),
    },
  ];

  return (
    <Paper className="p-15">
      {/* Chart */}
      <Typography varint="h6" className="fs-18 fw-5 gray-color">
        {t("top10ProfitMakingItems")}
      </Typography>
      <ReactApexChart
        options={options}
        series={series}
        type="bar"
        width="100%"
        height="300"
      />
    </Paper>
  );
};

const mapStateToProps = (state) => ({
  _authUser: state.pos.authUser,
  _defStore: state.pos.defStore,
});

const mapActiontoProps = { _getByObj: actions.getByObj };

export default connect(mapStateToProps, mapActiontoProps)(TopTenItems);
