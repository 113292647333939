import {
  Divider,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useContext } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { DropLi } from "../../hooks";
import { IsEmptyObj } from "../../hooks/Method";
import { CloseRounded } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../App";
import moment from "moment";
import { Dialog } from "../../layout";
import ViewMultiPayments from "../multCurrency/ViewMultiPayments";

const ViewPurch = (props) => {
  const { org } = useContext(AppContext);
  const { setShowForm, viewData } = props;
  const { t } = useTranslation();
  const { PaymentType } = DropLi();
  const [total, setTotal] = useState(0);
  const [discountA, setDiscountA] = useState(0);
  const [multiCurrency, setMultiCurrency] = useState(0);
  const [isOverdue, setIsOverdue] = useState(false);
  const [showMultiPayments, setShowMultiPayments] = useState(false);
  const [multPays, setMultPays] = useState([]);

  useEffect(() => {
    if (!IsEmptyObj(viewData)) {
      var _total = viewData.purchItems
        .map((j) => j.qty * j.purchPrice)
        .reduce((x, y) => x + y, 0)
        .toFixed(2);
      setTotal(_total);
      var discount = (
        (parseFloat(viewData.discount || 0) / 100) *
        _total
      ).toFixed(2);
      setDiscountA(discount);
      setMultiCurrency(
        viewData.multPays
          .map((item) => item.mainAmt)
          .reduce((x, y) => x + y, 0)
          .toFixed(2)
      );

      // Check overdue
      if (viewData.paymentDue > 0)
        setIsOverdue(
          moment(new Date()) > moment(viewData.payDate, "MM/DD/YYYY")
        );
    }
  }, [viewData]);

  const handleViewMultPayments = () => {
    setShowMultiPayments(!showMultiPayments);
    setMultPays([...viewData.multPays]);
  };

  return (
    <>
      <div className="mb-10 d-flex j-between a-center pt-10 pl-10 pr-10">
        <div>
          <h1 className="form-title ml-10 fw-4 gray-color tx-upp border-left">
            {t("purchaseDetails")}
          </h1>
        </div>

        <div>
          <IconButton onClick={() => setShowForm(false)}>
            <CloseRounded />
          </IconButton>
        </div>
      </div>
      <Divider />
      <Grid container>
        <Grid item xs={12}>
          <TableContainer className="mt-20">
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell>{t("itemName")}</TableCell>
                  <TableCell>{t("barcode")}</TableCell>
                  <TableCell>{t("quantity")}</TableCell>
                  <TableCell>{t("price")}</TableCell>
                  <TableCell>{t("total")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {viewData.purchItems.map((item, i) => (
                  <TableRow key={i}>
                    <TableCell>{item.product && item.product.name}</TableCell>
                    <TableCell>
                      {item.product && item.product.barcode}
                    </TableCell>
                    <TableCell>{item.qty}</TableCell>
                    <TableCell>
                      {(org && org.curSymbol) + " " + item.purchPrice}
                    </TableCell>
                    <TableCell>
                      {(org && org.curSymbol) +
                        " " +
                        item.qty * item.purchPrice}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Divider />
          <Divider />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          className="w-100pr"
          sx={{
            borderRight: { sm: "1px solid var(--grayLight-color)" },
          }}
        >
          <TableContainer>
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell>{t("referenceNo")} :</TableCell>
                  <TableCell align="right">{viewData.id}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{t("supplier")} :</TableCell>
                  <TableCell align="right">{viewData.supplier}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{t("purchaseUser")} :</TableCell>
                  <TableCell align="right">
                    {viewData.user &&
                      viewData.user.firstName + " " + viewData.user.lastName}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    {t("paymentType")} / {t("status")}:
                  </TableCell>
                  <TableCell align="right">
                    <div className="d-flex g-10 j-end">
                      <span>
                        {viewData.paymentType &&
                          PaymentType.find((j) => j.id === viewData.paymentType)
                            .label}
                      </span>
                      |{" "}
                      <div className="fw-5">
                        {viewData.paymentDue > 0 ? (
                          <span className="bg-errorSM error-color pr-10 pl-10 pt-5 pb-5 br-25 fs-12 tx-upp">
                            {t("due")}
                          </span>
                        ) : (
                          <span className="bg-successXS success-color pr-10 pl-10 pt-5 pb-5 br-25 fs-12 tx-upp">
                            {t("paid")}
                          </span>
                        )}
                      </div>
                    </div>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>{t("purchaseDate")}: </TableCell>
                  <TableCell align="right">{viewData.purchDate}</TableCell>
                </TableRow>
                <TableRow className="error-color">
                  <TableCell className={isOverdue ? "error-color" : ""}>
                    {t("dueDate")}:{" "}
                  </TableCell>
                  <TableCell
                    align="right"
                    className={isOverdue ? "error-color" : ""}
                  >
                    {viewData.payDate}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{t("paidDate")}: </TableCell>
                  <TableCell align="right">{viewData.paidDate}</TableCell>
                </TableRow>
                {viewData.recept && (
                  <TableRow>
                    <TableCell>{t("purchaseRecept")} :</TableCell>
                    <TableCell align="right">
                      <a
                        href={viewData.recept}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {t("viewRecept")}
                      </a>
                    </TableCell>
                  </TableRow>
                )}
              </TableHead>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={12} sm={6} className="w-100pr">
          <TableContainer>
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell>{t("totalItem")}:</TableCell>
                  <TableCell align="right">
                    {viewData.purchItems
                      .map((j) => j.qty)
                      .reduce((x, y) => x + y, 0)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{t("paid")}: </TableCell>
                  <TableCell align="right">
                    {org && org.curSymbol + " " + viewData.paidAmt}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{t("discount")}: </TableCell>
                  <TableCell align="right">
                    {viewData.discount || 0}% &nbsp;&nbsp; (-
                    {org && org.curSymbol + " "} {discountA})
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{t("totalInvAmt")}: </TableCell>
                  <TableCell align="right">
                    {" "}
                    {org && org.curSymbol + " " + total}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="primary-color">
                    {t("totalPayableAmt")}:{" "}
                  </TableCell>
                  <TableCell align="right" className="primary-color">
                    {" "}
                    {org &&
                      org.curSymbol +
                        " " +
                        (parseFloat(total) - parseFloat(discountA))}
                  </TableCell>
                </TableRow>
                {org.enableMultCurr && viewData.multiPayAmt > 0 && (
                  <TableRow className="link" onClick={handleViewMultPayments}>
                    <TableCell className="hover tx-und success-color">
                      {t("multiCurrency")}
                    </TableCell>
                    <TableCell align="right" className="success-color tx-und">
                      {org.curSymbol + " " + viewData.multiPayAmt}
                    </TableCell>
                  </TableRow>
                )}
                <TableRow>
                  <TableCell className="success-color">
                    {t("totalPaid")}:{" "}
                  </TableCell>
                  <TableCell align="right" className="success-color">
                    {" "}
                    {org &&
                      org.curSymbol +
                        " " +
                        (parseFloat(multiCurrency) +
                          parseFloat(viewData.paidAmt))}
                  </TableCell>
                </TableRow>
                {viewData.paymentDue > 0 && (
                  <TableRow>
                    <TableCell className="error-color">
                      {t("paymentDue")}:
                    </TableCell>
                    <TableCell align="right" className="error-color">
                      -&nbsp;
                      {(org && org.curSymbol) +
                        " " +
                        (viewData.paymentDue || 0)}
                    </TableCell>
                  </TableRow>
                )}
              </TableHead>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>

      {showMultiPayments && (
        <Dialog show={showMultiPayments} onClose={false} maxWidth="lg">
          <ViewMultiPayments {...{ setShow: setShowMultiPayments, multPays }} />
        </Dialog>
      )}
    </>
  );
};

export default ViewPurch;
