import { Grid, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import { Button, Input, InputDate } from "../../../controls";
import { Alert, Form } from "../../../layout";
import { useEffect } from "react";
import { connect } from "react-redux";
import * as actions from "../../../api/actions/pos";
import { controller, endpoints } from "../../../api/actions/api";
import { NestedFormData, RmvEmptyProp } from "../../../hooks/Method";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../../App";
import { Model, UseForm } from "../../../hooks";
import MultPayments from "../../multCurrency/MultPayments";

const MakePayment = (props) => {
  const { paymentData, setShowForm, _post, setSells } = props;
  const { MakePayMdl } = Model();
  const { org, selStore } = useContext(AppContext);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [multPays, setMultPays] = useState([]);
  const [multPayAmt, setMultPayAmt] = useState();
  const [leftDue, setLeftDue] = useState();
  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    title: "",
    subTitle: "",
  });
  const { values, errors, setErrors, handleInpChg, setValues, handleInpDate } =
    UseForm(MakePayMdl);

  useEffect(() => {
    setValues((j) => ({
      ...j,
      sellId: paymentData.id,
    }));
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentData]);

  const validateForm = () => {
    let temp = {};
    if (!multPayAmt) {
      temp.amount =
        values.amount !== ""
          ? paymentData.dueAmt < parseFloat(values.amount)
            ? t("youHavePutedWrongAmount")
            : ""
          : t("dueAmountIsRequired");
    }
    if (paymentData.dueAmt > parseFloat(values.amount) + parseFloat(multPayAmt))
      temp.nextDueDate =
        values.nextDueDate !== "" ? "" : t("nextDueDateIsRequired");

    setErrors({ ...temp });
    return Object.values(temp).every((x) => x === "");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);
      const onComplete = (res) => {
        if (res.status === 200) {
          setAlert({
            isOpen: true,
            type: "success",
            title: t("success"),
            subTitle: res.msg,
          });
        }
        setSells((x) => [
          ...x.map((j) => (j.id === res.result.id ? res.result : j)),
        ]);
        setLoading(false);
        setShowForm(false);
      };

      _post(
        endpoints.Sell + controller.MakePayment,
        NestedFormData(
          RmvEmptyProp({
            ...values,
            storeId: selStore,
            multPays: multPays,
            multiPayAmt: multPayAmt,
          })
        ),
        null,
        onComplete
      );
    }
  };

  useEffect(() => {
    setMultPayAmt(
      multPays
        .map((j) => parseFloat(j.mainAmt))
        .reduce((x, y) => x + y, 0)
        .toFixed(2)
    );
    return () => {};
  }, [multPays]);

  /// Need to create payment table;

  useEffect(() => {
    if (paymentData) {
      var leftDue = (
        parseFloat(paymentData.dueAmt) -
        (parseFloat(values.amount || 0) + parseFloat(multPayAmt)).toFixed(2)
      ).toFixed(2);
      setLeftDue(leftDue);
      setValues((j) => ({ ...j, nextDueDate: "" }));
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.amount, multPayAmt, paymentData]);

  return (
    <>
      {values.sellId && paymentData.id ? (
        <Form noValidate onSubmit={handleSubmit}>
          <Grid container spacing={3} className="p-20 pt-40 bg-grayXS">
            <Grid item xs={12} className="tx-center">
              <Typography variant="h5" className="fs-22 gray-color fw-5 tx-upp">
                {t("makePayment")}
              </Typography>
              <div className="bs-bottom mx-w-80 m-auto mt-10 mb-30" />
            </Grid>
            <Grid item xs={12} md={4}>
              <div className="d-grid g-5 a-center gray-color">
                <span className="fs-12 tx-upp">{t("invoice")}</span>
                <span className="fs-20 fw-5">{values.sellId}</span>
              </div>
            </Grid>
            <Grid item xs={12} md={4}>
              <div className="d-grid g-5 a-center gray-color">
                <span className="fs-12 tx-upp">{t("totalBill")}</span>
                <span className="fs-20 fw-5">
                  {org && org.curSymbol + " " + paymentData.totalPay}
                </span>
              </div>
            </Grid>
            <Grid item xs={12} md={4}>
              <div
                className={`d-grid g-5 a-center ${
                  leftDue > 0 ? "error-color" : "success-color"
                }`}
              >
                <span className="fs-12 tx-upp">{t("due")}</span>
                <span className="fs-20 fw-5">
                  {org && org.curSymbol + " " + leftDue}
                </span>
              </div>
            </Grid>
            <Grid item xs={12}>
              <Input
                label={t("payAmount")}
                type="number"
                name="amount"
                value={values.amount}
                error={errors.amount}
                onChange={(e) => {
                  if (
                    parseFloat(e.target.value) <=
                      (
                        parseFloat(paymentData.dueAmt) - parseFloat(multPayAmt)
                      ).toFixed(2) ||
                    values.amount.length > e.target.value.length
                  )
                    handleInpChg(e);
                }}
                fullWidth
              />
              {org.enableMultCurr && (
                <MultPayments
                  {...{
                    multPays,
                    setMultPays,
                    totalAmt: leftDue,
                    isSpecificAmt: true,
                  }}
                />
              )}
            </Grid>
            {(parseFloat(values.amount) > 0 || multPayAmt > 0) &&
              leftDue > 0 && (
                <Grid item xs={12}>
                  <InputDate
                    label="Next Due Date"
                    name="nextDueDate"
                    value={values.nextDueDate}
                    error={errors.nextDueDate}
                    minDate={new Date()}
                    onChange={(date) =>
                      handleInpDate(
                        "nextDueDate",
                        new Date(date).toLocaleDateString()
                      )
                    }
                  />
                </Grid>
              )}
            <Grid
              item
              xs={12}
              className="tx-right d-flex mt-10"
              sx={{ flexWrap: { xs: "wrap", md: "initial" } }}
            >
              <Button
                loading={loading}
                type="submit"
                className="w-100pr primary-color bg-primarySM"
                size="large"
              >
                {t("payment")}
              </Button>
              <Button
                onClick={() => setShowForm(false)}
                className="error-color bg-errorSM w-100pr"
                size="large"
                sx={{ ml: { md: "10px" }, mt: { xs: "15px", md: "0px" } }}
              >
                {t("close")}
              </Button>
            </Grid>
          </Grid>
        </Form>
      ) : (
        "Loading..."
      )}

      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapActionToProps = {
  _post: actions.post,
};

export default connect(mapStateToProps, mapActionToProps)(MakePayment);
