import { CloseRounded } from "@mui/icons-material";
import { Grid, IconButton } from "@mui/material";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../App";
import { Table } from "../../layout";

const ViewExpense = ({ setOpen, values }) => {
  const { t } = useTranslation();
  const { org } = useContext(AppContext);

  return (
    <>
      <div className="tx-center d-flex j-between a-center p-10">
        <span className="fs-20 gray-color tx-upp border-left">
          {t("expenseDetails")}
        </span>
        <IconButton onClick={() => setOpen(false)}>
          <CloseRounded />
        </IconButton>
      </div>

      {values && org ? (
        <Grid
          container
          spacing={3}
          sx={{ width: { xs: "auto", md: "700px" }, padding: "10px" }}
        >
          <Grid item xs={12} md={4}>
            <div className="d-grid g-5 ">
              <span className="fs-12 tx-upp gray-color">{t("payDate")}</span>
              <span>{values.addDate}</span>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className="d-grid g-5">
              <span className="fs-12 gray-color tx-upp">
                {t("expenseType")}
              </span>
              <span>{values.expenseType}</span>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className="d-grid g-5">
              <span className="fs-12 gray-color tx-upp">{t("paidBy")}</span>
              <span>{values.paidBy}</span>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className="d-grid g-5">
              <span className="fs-12 gray-color tx-upp">
                {t("localAmount")}
              </span>
              <span>{values.amount}</span>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className="d-grid g-5">
              <span className="fs-12 gray-color tx-upp">
                {t("inMultCurrency")}
              </span>
              <span>{values.multiPayAmt}</span>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className="d-grid g-5">
              <span className="fs-12 gray-color tx-upp">{t("totalPaid")}</span>
              <span>{values.total}</span>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className="mb-10 mt-20">
              <span className="border-left tx-upp gray-color fw-5">
                {t("multiCurrencies")}
              </span>
            </div>
            {/* <Divider className="mb-10" /> */}
            <Table
              showSearch={false}
              showHeader={false}
              ActionComp={() => <></>}
              HeaderComp={() => <> </>}
              tblHead={[
                { id: "summary", label: t("summary") },
                { id: "amt", label: t("paidLocal") },
                {
                  id: "mainCurr",
                  label: `${org.curName} (${org.curSymbol} ${values.multPays
                    .map((j) => parseFloat(j.mainAmt))
                    .reduce((x, y) => x + y, 0)
                    .toFixed(2)})`,
                },
              ]}
              tblData={[
                ...values.multPays.map((j) => ({
                  ...j,
                  amt: j.curr && j.curr.symbol + " " + j.amount,
                  mainCurr: org.curSymbol + " " + j.mainAmt,
                })),
              ]}
            />
          </Grid>
        </Grid>
      ) : (
        "Loading..."
      )}
    </>
  );
};

export default ViewExpense;
