import {
  Divider,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { controller, endpoints } from "../../api/actions/api";
import * as actions from "../../api/actions/pos";
import { AppContext } from "../../App";
import { Button, Input } from "../../controls";
import {
  IsEmptyObj,
  NestedFormData,
  PrintDiv,
  RmvEmptyProp,
} from "../../hooks/Method";
import { Alert, Dialog } from "../../layout";
import { useTranslation } from "react-i18next";
import { ArrowBackRounded } from "@mui/icons-material";
import PrintRegReport from "./PrintRegReport";
import ViewMultiPayments from "../multCurrency/ViewMultiPayments";

const CloseReg = (props) => {
  const { setShowForm, _authUser, _post, _getByObj } = props;
  const { t } = useTranslation();
  const { setLoader, org } = useContext(AppContext);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [sells, setSells] = useState([]);
  const [cancelCarts, setCancelCarts] = useState([]);
  const [voidSells, setVoidSells] = useState([]);
  const [openData, setOpenData] = useState({});
  const [totalSells, setTotalSells] = useState(0);
  const [average, setAverage] = useState(0);
  const [refund, setRefund] = useState(0);
  const [voidAmount, setVoidAmount] = useState(0);
  const [cashSale, setCashSale] = useState(0);
  const [craditSale, setCraditSale] = useState(0);
  const [taxItem, setTaxItem] = useState(0);
  const [nonTaxItem, setNonTaxItem] = useState(0);
  const [taxReceived, setTaxReceived] = useState(0);
  const [otherPayment, setOtherPayment] = useState(0);
  const [totalDiffAmt, setTotalDiffAmt] = useState(0);
  const [expense, setExpense] = useState([]);
  const [soldByCats, setSoldByCats] = useState([]);
  const [notes, setNotes] = useState("");
  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    title: "",
    subTitle: "",
  });
  const { selStore, stores } = useContext(AppContext);
  const [store, setStore] = useState({});
  const [showMultiPayments, setShowMultiPayments] = useState(false);

  useEffect(() => {
    setStore(stores.find((j) => j.id === selStore));
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selStore]);

  useEffect(() => {
    if ("posOpen" in localStorage) {
      const posOpen = JSON.parse(localStorage.getItem("posOpen"));
      setOpenData(posOpen);
      setLoader(true);
      const onComplete = (res) => {
        if (res.status === 200) {
          setSells(res.result.sells);
          setVoidSells(res.result.voidSells);
          setCancelCarts(res.result.cancelCarts);
          setExpense(res.result.expense);
        }
        setLoader(false);
      };
      _getByObj(
        endpoints.Sell + controller.CloseRegSells,
        {
          userId: _authUser.id,
          storeId: selStore,
          value: posOpen.timeIn,
        },
        null,
        onComplete
      );
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (sells.length > 0) {
      var _total = sells
        .map((j) => j.subtotal + j.tax)
        .reduce((x, y) => x + y, 0);
      setTotalSells(
        _total.toLocaleString("en-US", { maximumFractionDigits: 2 })
      );
      setAverage(
        (_total / sells.length).toLocaleString("en-US", {
          maximumFractionDigits: 2,
        })
      );
      setTotalDiffAmt(sells.map((j) => j.diffAmt).reduce((x, y) => x + y, 0));

      var _taxItem = sells
        .map(
          (j) =>
            j.sellItems.filter((x) => x.itemType === 1 && x.product.tax === 1)
              .length
        )
        .reduce((x, y) => x + y, 0);
      setTaxItem(
        _taxItem.toLocaleString("en-US", { maximumFractionDigits: 2 })
      );

      var _nonTaxItem = sells
        .map(
          (j) =>
            j.sellItems.filter((x) => x.itemType === 2 || x.product.tax === 2)
              .length
        )
        .reduce((x, y) => x + y, 0);
      setNonTaxItem(
        _nonTaxItem.toLocaleString("en-US", { maximumFractionDigits: 2 })
      );

      var _taxReceived = sells.map((j) => j.tax).reduce((x, y) => x + y, 0);
      setTaxReceived(
        _taxReceived.toLocaleString("en-US", { maximumFractionDigits: 2 })
      );

      var _totalCashSale = sells
        .filter(
          (j) => j.payMethod && j.payMethod.title.toLowerCase().includes("cash")
        )
        .map((j) => j.received - j.change)
        .reduce((x, y) => x + y, 0);
      setCashSale(
        _totalCashSale.toLocaleString("en-US", { maximumFractionDigits: 2 })
      );

      var _totalCraditSale = sells
        .map((j) => j.creditAmt)
        .reduce((x, y) => x + y, 0);
      setCraditSale(
        _totalCraditSale.toLocaleString("en-US", { maximumFractionDigits: 2 })
      );

      setOtherPayment(
        (_total - (_totalCashSale + _totalCraditSale)).toLocaleString("en-US", {
          maximumFractionDigits: 2,
        })
      );

      var _refunds = voidSells
        .filter((j) => j.cancelType === 2)
        .map((j) => j.subtotal + j.tax)
        .reduce((x, y) => x + y, 0);
      setRefund(_refunds.toLocaleString("en-US", { maximumFractionDigits: 2 }));

      var _voidAmount = voidSells
        .filter((j) => j.cancelType === 1)
        .map((j) => j.subtotal + j.tax)
        .reduce((x, y) => x + y, 0);
      setVoidAmount(
        _voidAmount.toLocaleString("en-US", { maximumFractionDigits: 2 })
      );

      // Sold By Categories
      const _soldByCats = [];
      // Lottery
      const lottery = sells
        .map((j) => j.sellItems.filter((x) => x.itemType === 2))
        .flat();
      lottery.length > 0 && _soldByCats.push(lottery);
      // Product
      const prod = sells
        .map((j) => j.sellItems.filter((x) => x.itemType === 1))
        .flat();

      prod.forEach((j) => {
        var item = prod.filter(
          (x) => x.product.categoryId === j.product.categoryId
        );
        !_soldByCats.some((x) => JSON.stringify(x) === JSON.stringify(item)) &&
          _soldByCats.push(item);
      });
      setSoldByCats(_soldByCats);
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sells]);

  const handleViewMultPayments = () => {
    setShowMultiPayments(true);
  };

  const onComplete = (res) => {
    setLoading(false);
    if (res.status === 201)
      setAlert({
        isOpen: true,
        type: "success",
        title: t("success"),
        subTitle: t("regClosedSuccessTitle"),
        onClick: () => {
          PrintDiv("PrintRegReport");
          localStorage.removeItem("posOpen");
          setShowForm(false);
          navigate("/");
        },
      });
    else
      setAlert({
        isOpen: true,
        type: "error",
        title: t("error"),
        subTitle: res.msg,
      });
  };

  const handleClose = () => {
    if (!IsEmptyObj(openData)) {
      setLoading(true);
      const regData = {
        id: 0,
        storeId: store.id,
        userId: _authUser.id,
        openTime: openData.timeIn,
        totalSale: totalSells,
        totalCash: cashSale,
        totalCradit: craditSale,
        totalRefund: refund,
        totalVoidSell: voidAmount,
        itemCancel: cancelCarts.length,
        notes: notes,
        otherPayment: otherPayment,
        taxItemSold: taxItem,
        nonTaxItemSold: nonTaxItem,
        taxReceived: taxReceived,
        averagePerCstmr: average,
        totalCstmr: sells.length,
        shiftTransBalanace: openData.cashInDrawer,
        multiPayAmt: openData.multiPayAmt,
        multPays: openData.multPays,
        totalDiffAmt: totalDiffAmt,
      };
      _post(
        endpoints.Register,
        NestedFormData(RmvEmptyProp(regData)),
        null,
        onComplete
      );
    } else {
      setAlert({
        isOpen: true,
        type: "warning",
        title: t("warning"),
        subTitle: t("regCloseWarning"),
      });
    }
  };

  return (
    <>
      <Box className="d-none" id="PrintRegReport">
        <PrintRegReport
          {...{
            totalSells,
            average,
            refund,
            voidAmount,
            cashSale,
            craditSale,
            taxItem,
            nonTaxItem,
            taxReceived,
            store,
            _authUser,
            expense,
            soldByCats,
            sells,
            openData,
            cancelCarts,
            otherPayment,
          }}
        />
      </Box>

      <div className="tx-center d-flex j-between a-center p-10">
        <div className="">
          <div className="d-flex a-center g-15">
            <IconButton onClick={() => setShowForm(false)}>
              <ArrowBackRounded />
            </IconButton>
            <span className="fs-22 gray-color tx-upp">
              {t("cashRegisterReport")}
            </span>
          </div>
        </div>

        <Button
          onClick={handleClose}
          variant="contained"
          color="error"
          loading={loading}
        >
          {t("closeReg")}
        </Button>
      </div>
      <Divider />
      {org ? (
        <Grid container spacing={3} className="p-10">
          <Grid item xs={12}>
            <Box
              className="d-flex j-between"
              sx={{ flexWrap: { xs: "wrap", md: "inherit" } }}
            >
              {/* Cashier Details */}
              <Box className="mt-20 mb-30 w-100pr" sx={{ mr: { md: "20px" } }}>
                <Typography
                  variant="h6"
                  sx={{
                    fontSize: { xs: "16px", md: "18px" },
                    borderLeft: "0.4rem double var(--primary-color)",
                    paddingLeft: "10px",
                  }}
                  className="tx-upp gray-color"
                >
                  {t("cashierDetails")}
                </Typography>
                <Divider className="pt-10 mb-5" />
                <Box className="d-flex">
                  <Typography
                    variant="subtitle1"
                    className="fw-5"
                    sx={{ minWidth: { xs: "140px", md: "180px" } }}
                  >
                    {t("storeName")}:
                  </Typography>
                  <Typography variant="subtitle1">{store.storeName}</Typography>
                </Box>
                <Divider />
                <Box className="d-flex">
                  <Typography
                    variant="subtitle1"
                    className="fw-5"
                    sx={{ minWidth: { xs: "140px", md: "180px" } }}
                  >
                    {t("address")}:
                  </Typography>
                  <Typography variant="subtitle1">{store.address}</Typography>
                </Box>
                <Divider />
                <Box className="d-flex">
                  <Typography
                    variant="subtitle1"
                    className="fw-5"
                    sx={{ minWidth: { xs: "140px", md: "180px" } }}
                  >
                    {t("cashierName")}:
                  </Typography>
                  <Typography variant="subtitle1">
                    {_authUser.firstName + " " + _authUser.lastName}
                  </Typography>
                </Box>
                <Divider />
                <Box className="d-flex">
                  <Typography
                    variant="subtitle1"
                    className="fw-5"
                    sx={{ minWidth: { xs: "140px", md: "180px" } }}
                  >
                    {t("timeIn")}:
                  </Typography>
                  <Typography variant="subtitle1">
                    {new Date(openData.timeIn).toLocaleTimeString()}
                  </Typography>
                </Box>
                <Divider />
                <Box className="d-flex">
                  <Typography
                    variant="subtitle1"
                    className="fw-5"
                    sx={{ minWidth: { xs: "140px", md: "180px" } }}
                  >
                    {t("timeOut")}:
                  </Typography>
                  <Typography variant="subtitle1">
                    {new Date().toLocaleTimeString()}
                  </Typography>
                </Box>
                <Divider />
              </Box>
              {/* Register Details */}
              <Box className="mt-20 mb-30 w-100pr">
                <Typography
                  variant="h6"
                  sx={{
                    fontSize: { xs: "16px", md: "18px" },
                    borderLeft: "0.4rem double var(--primary-color)",
                    paddingLeft: "10px",
                  }}
                  className="tx-upp gray-color"
                >
                  {t("registerDetails")}
                </Typography>
                <Divider className="pt-10 mb-5" />
                <Box className="d-flex">
                  <Typography
                    variant="subtitle1"
                    className="fw-5"
                    sx={{ minWidth: { xs: "180px", md: "200px" } }}
                  >
                    {t("totalCustomers")}:
                  </Typography>
                  <Typography variant="subtitle1">{sells.length}</Typography>
                </Box>
                <Divider />
                <Box className="d-flex">
                  <Typography
                    variant="subtitle1"
                    className="fw-5"
                    sx={{ minWidth: { xs: "180px", md: "200px" } }}
                  >
                    {t("averagePerCustomer")}:
                  </Typography>
                  <Typography variant="subtitle1">
                    {org && org.curSymbol} {average}
                  </Typography>
                </Box>
                <Divider />
                <Box className="d-flex">
                  <Typography
                    variant="subtitle1"
                    className="fw-5"
                    sx={{ minWidth: { xs: "180px", md: "200px" } }}
                  >
                    {t("totalSaleRefund")}:
                  </Typography>
                  <Typography variant="subtitle1">
                    {org && org.curSymbol} {refund}
                  </Typography>
                </Box>
                <Divider />
                <Box className="d-flex">
                  <Typography
                    variant="subtitle1"
                    className="fw-5"
                    sx={{ minWidth: { xs: "180px", md: "200px" } }}
                  >
                    {t("totalVoidSells")}:
                  </Typography>
                  <Typography variant="subtitle1">
                    {org.curSymbol + " " + voidAmount}
                  </Typography>
                </Box>
                <Divider />
                <Box className="d-flex">
                  <Typography
                    variant="subtitle1"
                    className="fw-5"
                    sx={{ minWidth: { xs: "180px", md: "200px" } }}
                  >
                    {t("totalItemCanceled")}:
                  </Typography>
                  <Typography variant="subtitle1">
                    {cancelCarts.length}
                  </Typography>
                </Box>
                <Divider />
              </Box>
            </Box>

            <Box
              className="d-flex j-between"
              sx={{ flexWrap: { xs: "wrap", md: "inherit" } }}
            >
              {/* Sale Report */}
              <Box className="mt-20 mb-30 w-100pr" sx={{ mr: { md: "20px" } }}>
                <Typography
                  variant="h6"
                  sx={{
                    fontSize: { xs: "16px", md: "18px" },
                    borderLeft: "0.4rem double var(--primary-color)",
                    paddingLeft: "10px",
                  }}
                  className="tx-upp gray-color"
                >
                  {t("saleReport")}
                </Typography>
                <Divider className="pt-10 mb-5" />
                <Box className="d-flex">
                  <Typography
                    variant="subtitle1"
                    className="fw-5"
                    sx={{ minWidth: { xs: "180px", md: "200px" } }}
                  >
                    {t("totalSale")}:
                  </Typography>
                  <Typography variant="subtitle1">
                    {org.curSymbol + " " + totalSells}
                  </Typography>
                </Box>
                <Divider />
                <Box className="d-flex">
                  <Typography
                    variant="subtitle1"
                    className="fw-5"
                    sx={{ minWidth: { xs: "180px", md: "200px" } }}
                  >
                    {t("totalCashSale")}:
                  </Typography>
                  <Typography variant="subtitle1">
                    {org.curSymbol + " " + cashSale}
                  </Typography>
                </Box>
                <Divider />
                <Box className="d-flex">
                  <Typography
                    variant="subtitle1"
                    className="fw-5"
                    sx={{ minWidth: { xs: "180px", md: "200px" } }}
                  >
                    {t("totalCreditSale")}:
                  </Typography>
                  <Typography variant="subtitle1">
                    {org.curSymbol + " " + craditSale}
                  </Typography>
                </Box>
                <Divider />
                <Box className="d-flex">
                  <Typography
                    variant="subtitle1"
                    className="fw-5"
                    sx={{ minWidth: { xs: "180px", md: "200px" } }}
                  >
                    {t("otherPaymentReceived")}:
                  </Typography>
                  <Typography variant="subtitle1">
                    {" "}
                    {org.curSymbol + " " + otherPayment}
                  </Typography>
                </Box>
              </Box>
              {/* Sell Details */}
              <Box className="mt-20 mb-30 w-100pr">
                <Typography
                  variant="h6"
                  sx={{
                    fontSize: { xs: "16px", md: "18px" },
                    borderLeft: "0.4rem double var(--primary-color)",
                    paddingLeft: "10px",
                  }}
                  className="tx-upp gray-color"
                >
                  {t("sellDetails")}
                </Typography>
                <Divider className="pt-10 mb-5" />

                <Box className="d-flex">
                  <Typography
                    variant="subtitle1"
                    className="fw-5"
                    sx={{ minWidth: { xs: "180px", md: "200px" } }}
                  >
                    {t("totalGrossSale")}:
                  </Typography>
                  <Typography variant="subtitle1">
                    {org.curSymbol + " " + totalSells}
                  </Typography>
                </Box>
                <Divider />

                <Box className="d-flex">
                  <Typography
                    variant="subtitle1"
                    className="fw-5"
                    sx={{ minWidth: { xs: "180px", md: "200px" } }}
                  >
                    {t("totalTaxItemSold")}:
                  </Typography>
                  <Typography variant="subtitle1">{taxItem}</Typography>
                </Box>

                <Divider />

                <Box className="d-flex">
                  <Typography
                    variant="subtitle1"
                    className="fw-5"
                    sx={{ minWidth: { xs: "180px", md: "200px" } }}
                  >
                    {t("totalNonTaxItemSold")}:
                  </Typography>
                  <Typography variant="subtitle1">{nonTaxItem}</Typography>
                </Box>

                <Divider />

                <Box className="d-flex">
                  <Typography
                    variant="subtitle1"
                    className="fw-5"
                    sx={{ minWidth: { xs: "180px", md: "200px" } }}
                  >
                    {t("totalTaxReceived")}:
                  </Typography>
                  <Typography variant="subtitle1">
                    {org.curSymbol + " " + taxReceived}
                  </Typography>
                </Box>
              </Box>
              <Divider />
            </Box>
            <Box
              className="d-flex j-between"
              sx={{ flexWrap: { xs: "wrap", md: "inherit" } }}
            >
              {/* Money */}
              <Box className="mt-20 mb-30 w-100pr" sx={{ mr: { md: "20px" } }}>
                <Typography
                  variant="h6"
                  sx={{
                    fontSize: { xs: "16px", md: "18px" },
                    borderLeft: "0.4rem double var(--primary-color)",
                    paddingLeft: "10px",
                  }}
                  className="tx-upp gray-color"
                >
                  {t("money")}
                </Typography>
                <Divider className="pt-10 mb-5" />
                <Box className="d-flex">
                  <Typography
                    variant="subtitle1"
                    className="fw-5"
                    sx={{ minWidth: { xs: "220px", md: "250px" } }}
                  >
                    {t("totalCashSale")}:
                  </Typography>
                  <Typography variant="subtitle1">
                    {org.curSymbol + " " + totalSells}
                  </Typography>
                </Box>
                <Divider />
                {expense.map((exp, i) => (
                  <Box key={i}>
                    <Box className="d-flex">
                      <Typography
                        variant="subtitle1"
                        className="fw-5"
                        sx={{ minWidth: { xs: "220px", md: "250px" } }}
                      >
                        {exp.expenseCat.name}:
                      </Typography>
                      <Typography variant="subtitle1">
                        {org.curSymbol + " " + exp.amount}
                      </Typography>
                    </Box>
                    <Divider />
                  </Box>
                ))}
                <Box className="d-flex">
                  <Typography
                    variant="subtitle1"
                    className="fw-5"
                    sx={{ minWidth: { xs: "220px", md: "250px" } }}
                  >
                    {t("totalShiftTransactionsBalance")}:
                  </Typography>
                  <div className="d-flex a-center g-10">
                    <Typography variant="subtitle1">
                      {" "}
                      -{org.curSymbol + " " + openData.cashInDrawer}
                    </Typography>{" "}
                    {openData.multiPayAmt && (
                      <span
                        className="link d-flex tx-center fs-14 fw-5"
                        onClick={handleViewMultPayments}
                      >
                        (
                        <span className="tx-und">
                          {t("multiPayments")}: &nbsp;{org && org.curSymbol}{" "}
                          {openData.multiPayAmt}
                        </span>
                        )
                      </span>
                    )}
                  </div>
                </Box>
                <Divider />
                <Box className="d-flex">
                  <Typography
                    variant="subtitle1"
                    className="fw-5"
                    sx={{ minWidth: { xs: "220px", md: "250px" } }}
                  >
                    {t("totalDiffAmt")}:
                  </Typography>
                  <div className="d-flex a-center g-10">
                    <Typography variant="subtitle1">
                      {" "}
                      -
                      {org.curSymbol +
                        " " +
                        totalDiffAmt.toLocaleString("en-US", {
                          maximumFractionDigits: 2,
                        })}
                    </Typography>{" "}
                  </div>
                </Box>
                <Divider />
              </Box>
            </Box>

            {/* Products Sold by Category */}
            <TableContainer className="mt-20">
              <Typography
                variant="h6"
                sx={{
                  fontSize: { xs: "16px", md: "18px" },
                  borderLeft: "0.4rem double var(--primary-color)",
                  paddingLeft: "10px",
                  marginBottom: "10px",
                }}
                className="gray-color tx-upp"
              >
                {t("productsSoldByCategory")}
              </Typography>
              <Table size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow
                    sx={{
                      "&:last-child td, &:last-child th": { padding: "0px" },
                    }}
                  >
                    <TableCell>{t("categoryName")}</TableCell>
                    <TableCell>{t("quantity")}</TableCell>
                    <TableCell>{t("price")}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {soldByCats.map((item, i) => (
                    <TableRow key={i} sx={{ "td, th": { padding: "5px 0px" } }}>
                      <TableCell>
                        {item[0].itemType === 1
                          ? item[0].product.category !== null
                            ? item[0].product.category.name
                            : t("others")
                          : t("lottery")}
                      </TableCell>
                      <TableCell>
                        {item.map((j) => j.qty).reduce((x, y) => x + y, 0)}
                      </TableCell>
                      <TableCell>
                        {org.curSymbol +
                          " " +
                          item
                            .map((j) => j.qty * j.price)
                            .reduce((x, y) => x + y, 0)
                            .toLocaleString("en-US", {
                              maximumFractionDigits: 2,
                            })}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            {/* Details of products sold */}
            <TableContainer className="mt-20">
              <Typography
                variant="h6"
                sx={{
                  fontSize: { xs: "16px", md: "18px" },
                  borderLeft: "0.4rem double var(--primary-color)",
                  paddingLeft: "10px",
                  marginBottom: "10px",
                }}
                className="gray-color tx-upp"
              >
                {t("detailsOfProductsSold")}
              </Typography>
              <Box sx={{ maxHeight: "300px !important", overflow: "auto" }}>
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow
                      sx={{
                        "&:last-child td, &:last-child th": { padding: "0px" },
                      }}
                    >
                      <TableCell>{t("itemName")}</TableCell>
                      <TableCell className="w-70">{t("quantity")}</TableCell>
                      <TableCell className="w-90">{t("tax")}</TableCell>
                      <TableCell>{t("price")}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {sells
                      .map((j) => j.sellItems)
                      .flat()
                      .map((item, i) => (
                        <TableRow
                          sx={{ "td, th": { padding: "5px 0px" } }}
                          key={i}
                        >
                          <TableCell>
                            {item.itemType === 1
                              ? item.product.name
                              : t("lottery")}
                          </TableCell>
                          <TableCell>{item.qty}</TableCell>
                          <TableCell>
                            {item.itemType === 1 && item.product.tax === 1
                              ? `${
                                  org.curSymbol +
                                  " " +
                                  (
                                    (parseFloat(store.tax) / 100) *
                                    (item.price * item.qty)
                                  ).toLocaleString("en-US", {
                                    maximumFractionDigits: 2,
                                  })
                                }`
                              : t("noTax")}
                          </TableCell>
                          <TableCell>
                            {org.curSymbol +
                              " " +
                              (item.qty * item.price).toLocaleString("en-US", {
                                maximumFractionDigits: 2,
                              })}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </Box>
            </TableContainer>
          </Grid>

          <Grid item xs={12}>
            <Input
              variant="outlined"
              label={t("leaveNotes")}
              name="notes"
              value={notes}
              multiline
              rows={3}
              inputProps={{ maxLength: 399 }}
              onChange={(e) => setNotes(e.target.value)}
              fullWidth
            />
          </Grid>
        </Grid>
      ) : (
        "Loading..."
      )}

      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />

      {showMultiPayments && (
        <Dialog show={showMultiPayments} onClose={false} maxWidth="lg">
          <ViewMultiPayments
            {...{ setShow: setShowMultiPayments, multPays: openData.multPays }}
          />
        </Dialog>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  _store: state.pos.defStore,
  _authUser: state.pos.authUser,
});

const mapActionToProps = {
  _post: actions.post,
  _getByObj: actions.getByObj,
  _clear: actions.clear,
};

export default connect(mapStateToProps, mapActionToProps)(CloseReg);
