import React, { useContext, useEffect, useState } from "react";
import { Dialog } from "../../layout";
import MultCurr from "./MultCurr";
import { AppContext } from "../../App";
import { useTranslation } from "react-i18next";

const MultPayments = ({
  className,
  multPays,
  setMultPays,
  totalAmt,
  isSpecificAmt,
}) => {
  const { org } = useContext(AppContext);
  const { t } = useTranslation();
  const [openMultiPay, setOpenMultiPay] = useState(false);
  const [totalPaid, setTotalPaid] = useState();

  const handleOpen = () => {
    setOpenMultiPay(!openMultiPay);
  };

  useEffect(() => {
    setTotalPaid(
      multPays
        .map((j) => parseFloat(j.mainAmt))
        .reduce((x, y) => x + y, 0)
        .toFixed(2)
    );
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [multPays.length]);

  return (
    <>
      <div
        onClick={handleOpen}
        className={`tx-upp gray-color fs-14 mt-5 link tx-und ` + className}
      >
        {!multPays.length && t("apply")} {t("multiPayments")}
        {totalPaid > 0 && (
          <span className="fw-5">
            {" :"}&nbsp;&nbsp;&nbsp;
            {org && org.curSymbol} {totalPaid}
          </span>
        )}
      </div>

      {openMultiPay && (
        <Dialog show={openMultiPay} onClose={false} maxWidth="lg">
          <MultCurr
            {...{
              multPays,
              setMultPays,
              totalAmt,
              setOpenMultiPay,
              isSpecificAmt,
            }}
          />
        </Dialog>
      )}
    </>
  );
};

export default MultPayments;
