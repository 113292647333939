import {
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Tooltip,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Input,
  InputAuto,
  InputDate,
  InputImg,
  Select,
} from "../../controls";
import { DropLi, Model, UseForm } from "../../hooks";
import { Alert, Form, Tabs } from "../../layout";
import {
  ArrowBackRounded,
  PercentRounded,
  ShuffleRounded,
} from "@mui/icons-material";
import {
  MaxLength,
  RandomCode,
  RmvEmptyProp,
  ToFormData,
} from "../../hooks/Method";
import { Countries } from "../../hooks/Countries";
import { AppContext } from "../../App";
import { connect } from "react-redux";
import * as actions from "../../api/actions/pos";
import { controller, endpoints } from "../../api/actions/api";
import { useTranslation } from "react-i18next";
import moment from "moment";
import InputSwitch from "../../controls/InputSwitch";

const AddEditCstmr = (props) => {
  const {
    _post,
    _put,
    _getById,
    openOnDialog = false,
    setCstmrs,
    setOpen,
    setSelCstmr,
    setInvVal,
  } = props;
  const { id } = useParams();
  const { t } = useTranslation();
  const { CstmrMdl } = Model();
  const navigate = useNavigate();
  const { Gender, AccBalTypes } = DropLi();
  const { selStore } = useContext(AppContext);
  const {
    values,
    errors,
    setErrors,
    handleInpChg,
    setValues,
    handleFile,
    handleInpDate,
    handleInpCheck,
  } = UseForm(CstmrMdl);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    title: "",
    subTitle: "",
  });

  useEffect(() => {
    id &&
      _getById(
        endpoints.Customer + controller.GetById,
        id,
        null,
        (res) =>
          res.status === 200 &&
          setValues((j) => ({ ...j, ...RmvEmptyProp(res.result) }))
      );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (!values.specialTaxPayer) {
      setValues((j) => ({
        ...j,
        vatTax: "",
      }));
    } else {
      setValues((j) => ({
        ...j,
        taxExempt: false,
      }));
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.specialTaxPayer]);

  useEffect(() => {
    if (values.taxExempt) {
      setValues((j) => ({
        ...j,
        vatTax: "",
        specialTaxPayer: false,
      }));
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.taxExempt]);

  const validateForm = () => {
    let temp = {};
    temp.firstName = values.firstName !== "" ? "" : t("firstNameIsRequired");
    temp.lastName = values.lastName !== "" ? "" : t("lastNameIsRequired");
    temp.phone = values.phone !== "" ? "" : t("phoneIsRequired");
    temp.cstmrCode =
      values.cstmrCode !== ""
        ? errors.cstmrCode || ""
        : t("customerCodeIsRequired");

    setErrors({ ...temp });
    return Object.values(temp).every((x) => x === "");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm() && selStore) {
      setLoading(true);
      const onComplete = (res) => {
        setLoading(false);
        if (res) {
          if ((res.status === 201 || res.status === 200) && res.result) {
            setAlert({
              isOpen: true,
              type: "success",
              title: t("success"),
              subTitle: `${t("customer")} ${id ? t("updated") : t("added")} ${t(
                "successfully"
              )}`,
              onClick: () => {
                if (openOnDialog) {
                  var cstmr = res.result;
                  setCstmrs((j) => [
                    ...j,
                    {
                      ...cstmr,
                      label: cstmr.firstName + " " + cstmr.lastName,
                      subtitle: cstmr.cstmrCode + " | " + cstmr.phone,
                    },
                  ]);
                  setSelCstmr(cstmr);
                  setInvVal((j) => ({ ...j, cstmrId: cstmr.id }));
                  setOpen(false);
                } else navigate("/customers");
              },
            });
          } else
            setAlert({
              isOpen: true,
              type: "error",
              title: t("error"),
              subTitle: res.msg,
            });
        }
      };

      if (values.id === 0) {
        values.storeId = selStore;
        _post(
          endpoints.Customer + controller.Post,
          ToFormData(RmvEmptyProp(values)),
          null,
          onComplete
        );
      } else {
        _put(
          endpoints.Customer + controller.Put,
          ToFormData(RmvEmptyProp(values)),
          null,
          onComplete
        );
      }
    }
  };

  const handleGenerateCode = (name) => {
    var code = RandomCode(5);
    setValues({
      ...values,
      [name]: code,
    });

    // check the code is exist or not
    _getById(
      endpoints.Customer + controller.CheckCstmrCode,
      code,
      null,
      (res) =>
        res.result
          ? handleGenerateCode(name)
          : setErrors((x) => ({
              ...x,
              cstmrCode: "",
            }))
    );
  };

  const checkCstmrCode = (val) => {
    val &&
      _getById(
        endpoints.Customer + controller.CheckCstmrCode,
        val,
        null,
        (res) =>
          res &&
          setErrors((x) => ({
            ...x,
            cstmrCode: res.msg,
          }))
      );
  };

  return (
    <>
      <Form onSubmit={handleSubmit} noValidate>
        <Paper>
          <div className="mb-10 d-flex j-between a-center pt-15 pl-15 pr-15">
            <div className="d-flex a-center g-10">
              <div>
                <IconButton
                  size="small"
                  onClick={() => (openOnDialog ? setOpen(false) : navigate(-1))}
                >
                  <ArrowBackRounded />
                </IconButton>
              </div>
              <div>
                <h1 className="form-title fw-4 gray-color tx-upp">
                  {id ? t("edit") : t("add")} {t("customer")}
                </h1>
              </div>
            </div>

            <div>
              <Button type="submit" loading={loading}>
                {id ? t("saveChanges") : t("submit")}
              </Button>
            </div>
          </div>
          <Divider />
          <div className="pl-15 pr-15 pb-15">
            <Tabs
              className="mt-30"
              header={[
                { label: t("generalFields"), icon: "" },
                { label: t("addressFields"), icon: "" },
                { label: t("additionalFields"), icon: "" },
                { label: t("card"), icon: "" },
              ]}
            >
              {/* General Info */}
              <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                  <Input
                    label={t("firstName")}
                    name="firstName"
                    required={true}
                    value={values.firstName}
                    error={errors.firstName}
                    inputProps={{ maxLength: 255 }}
                    onChange={handleInpChg}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Input
                    label={t("lastName")}
                    name="lastName"
                    required={true}
                    value={values.lastName}
                    error={errors.lastName}
                    inputProps={{ maxLength: 255 }}
                    onChange={handleInpChg}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Input
                    label={t("customerCode")}
                    name="cstmrCode"
                    required={true}
                    value={values.cstmrCode}
                    error={errors.cstmrCode}
                    inputProps={{ maxLength: 200 }}
                    onChange={handleInpChg}
                    onBlur={(e) => checkCstmrCode(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Tooltip arrow title={t("generateCustomerId")}>
                            <IconButton
                              onClick={() => handleGenerateCode("cstmrCode")}
                            >
                              <ShuffleRounded />
                            </IconButton>
                          </Tooltip>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Input
                    label={t("phone")}
                    name="phone"
                    required={true}
                    value={values.phone}
                    error={errors.phone}
                    inputProps={{ maxLength: 50 }}
                    onChange={handleInpChg}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Input
                    label={t("email")}
                    name="email"
                    value={values.email}
                    error={errors.email}
                    inputProps={{ maxLength: 200 }}
                    onChange={handleInpChg}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Select
                    label={t("gender")}
                    name="gender"
                    value={values.gender}
                    onChange={handleInpChg}
                    options={Gender}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <InputDate
                    label={t("dateOfBirth")}
                    name="dob"
                    value={values.dob}
                    minDate={moment(new Date()).subtract(110, "year")}
                    maxDate={new Date()}
                    onChange={(date) =>
                      handleInpDate("dob", new Date(date).toLocaleDateString())
                    }
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Select
                    label={t("allowAnOnAccountBalance")}
                    name="accBalType"
                    value={values.accBalType}
                    onChange={handleInpChg}
                    options={AccBalTypes}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Input
                    label={t("balanceLimit")}
                    name="balLimit"
                    value={values.balLimit}
                    placeholder="$ 0.00"
                    type="number"
                    onChange={handleInpChg}
                  />
                </Grid>
                <Grid item xs={12} md={4} className="d-flex">
                  <Input
                    label={t("cedulaRIFCode")}
                    name="cedulaRIFCode"
                    value={values.cedulaRIFCode}
                    inputProps={{ maxLength: 50 }}
                    onChange={handleInpChg}
                  />
                </Grid>

                <Grid item xs={12} md={4} className="d-flex a-center">
                  <InputSwitch
                    label={t("specialTaxpayer")}
                    name="specialTaxPayer"
                    value={values.specialTaxPayer}
                    checked={values.specialTaxPayer}
                    onChange={handleInpCheck}
                  />
                </Grid>

                {!values.specialTaxPayer && (
                  <Grid item xs={12} md={4} className="d-flex a-center">
                    <InputSwitch
                      label={t("taxExempt")}
                      name="taxExempt"
                      value={values.taxExempt}
                      checked={values.taxExempt}
                      onChange={handleInpCheck}
                    />
                  </Grid>
                )}
                {values.specialTaxPayer && (
                  <Grid item xs={12} md={4}>
                    <Input
                      label={t("vatTax")}
                      name="vatTax"
                      value={values.vatTax}
                      placeholder="0.00"
                      onChange={handleInpChg}
                      onInput={(e) =>
                        (e.target.value = MaxLength(e.target.value, 3))
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <PercentRounded />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                )}
              </Grid>

              {/* Addresses */}
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <InputAuto
                    label={t("country")}
                    name="country"
                    value={values.country}
                    error={errors.country}
                    onChange={handleInpChg}
                    options={Countries}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Input
                    label={t("city")}
                    name="city"
                    value={values.city}
                    error={errors.city}
                    inputProps={{ maxLength: 150 }}
                    onChange={handleInpChg}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Input
                    label={t("state")}
                    name="state"
                    value={values.state}
                    error={errors.state}
                    inputProps={{ maxLength: 150 }}
                    onChange={handleInpChg}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Input
                    label={t("ZIP")}
                    name="zip"
                    value={values.zip}
                    error={errors.zip}
                    inputProps={{ maxLength: 50 }}
                    onChange={handleInpChg}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Input
                    label={t("address1")}
                    name="address1"
                    value={values.address1}
                    error={errors.address1}
                    inputProps={{ maxLength: 300 }}
                    multiline
                    rows={2}
                    onChange={handleInpChg}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Input
                    label={t("address2")}
                    name="address2"
                    value={values.address2}
                    error={errors.address2}
                    inputProps={{ maxLength: 300 }}
                    multiline
                    rows={2}
                    onChange={handleInpChg}
                  />
                </Grid>
              </Grid>

              {/* Additional informations */}
              <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                  <Input
                    label={t("companyName")}
                    name="company"
                    value={values.company}
                    inputProps={{ maxLength: 200 }}
                    onChange={handleInpChg}
                  />
                </Grid>

                <Grid item xs={12} md={4}>
                  <Input
                    label={t("twitter")}
                    name="twitter"
                    value={values.twitter}
                    inputProps={{ maxLength: 500 }}
                    onChange={handleInpChg}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Input
                    label={t("website")}
                    name="website"
                    value={values.website}
                    inputProps={{ maxLength: 1000 }}
                    onChange={handleInpChg}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Input
                    label={t("note")}
                    name="note"
                    value={values.note}
                    multiline
                    rows={3}
                    inputProps={{ maxLength: 1000 }}
                    onChange={handleInpChg}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <InputImg
                    label={t("profilePicture")}
                    name="photoFile"
                    src={values.photo}
                    value={values.photoFile}
                    rmv={() => {
                      setValues({ ...values, photoFile: "", photo: "" });
                    }}
                    onChange={handleFile}
                    error={errors.photoFile}
                  />
                </Grid>
              </Grid>

              {/*  Credit/Debit Card  */}
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <Input
                    label={t("nameOnCard")}
                    name="nameOnCard"
                    inputProps={{ maxLength: 26 }}
                    value={values.nameOnCard}
                    onChange={handleInpChg}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Input
                    label={t("cardNumber")}
                    name="cardNumber"
                    value={values.cardNumber}
                    type="number"
                    onInput={(e) =>
                      (e.target.value = MaxLength(e.target.value, 19))
                    }
                    onChange={handleInpChg}
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <InputDate
                    label={t("expiryDate")}
                    name="expiryDate"
                    views={["year", "month"]}
                    format="MM/YYYY"
                    openTo="year"
                    minDate={new Date()}
                    value={values.expiryDate}
                    onChange={(date) =>
                      handleInpDate(
                        "expiryDate",
                        new Date(date).toLocaleDateString()
                      )
                    }
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <Input
                    label={t("securityCode")}
                    name="securityCode"
                    value={values.securityCode}
                    type="number"
                    onInput={(e) =>
                      (e.target.value = MaxLength(e.target.value, 4))
                    }
                    onChange={handleInpChg}
                  />
                </Grid>
              </Grid>
            </Tabs>
          </div>
        </Paper>
      </Form>
      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapActionToProps = {
  _post: actions.post,
  _put: actions.put,
  _getById: actions.getById,
  _getByObj: actions.getByObj,
  _get: actions.get,
};

export default connect(mapStateToProps, mapActionToProps)(AddEditCstmr);
