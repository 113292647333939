import React, { useContext, useState } from "react";
import { Dashboard } from "../../layout";
import { Navigate, Route, Routes } from "react-router-dom";
import {
  Home,
  Header,
  NotFound,
  NavMenu,
  AddEditProd,
  Products,
  Categories,
  AddEditCat,
  Pos,
  Purchases,
  PurchReturn,
  AddEditPurchReturn,
  ExpsCat,
  Suppliers,
  Users,
  AddEditUser,
  Sells,
  StockTransfers,
  AddEditTransfer,
  Inventory,
  AddEditInventory,
  SalaryPayment,
  SalaryHistory,
  AddEditSplr,
  EmployeeHistory,
  PayBills,
  StoreReports,
  ProductReport,
  PurchaseReport,
  RegReport,
  ExpenseReport,
  ProdSellVol,
  EmployeeReport,
  TopSelling,
  ReturnProd,
  Stores,
  AddEditStore,
  PosSettings,
  DefaultStore,
  AddMultPurch,
  Customers,
  AddEditCstmr,
  ViewCstmr,
} from "../";
import { connect } from "react-redux";
import { IsEmptyObj } from "../../hooks/Method";
import OrgForm from "../Org/OrgForm";
import { AppContext } from "../../App";
import { useEffect } from "react";
import { no_img } from "../../assets";
import Currencies from "../currencies/Currencies";
import CurrRates from "../currRate/CurrRates";
import ViewSplr from "../suppliers/ViewSupplier/ViewSplr";
import PayMethods from "../payMethod/PayMethods";
import ReturnForm from "../sellReturn/ReturnForm";
import SellReturns from "../sellReturn/SellReturns";

const DashRoute = ({ _authUser }) => {
  const { selStore, stores } = useContext(AppContext);
  const [storeDtl, setStoreDtl] = useState();

  useEffect(() => {
    setStoreDtl({ ...stores.find((j) => j.id === selStore) });

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selStore]);

  return (
    <Dashboard
      logo={(storeDtl && storeDtl.logo) || no_img}
      menu={<NavMenu />}
      header={<Header />}
    >
      <Routes>
        <Route
          path="/"
          element={
            <Navigate
              to={
                !IsEmptyObj(_authUser) && _authUser.role === 1
                  ? "/dashboard"
                  : _authUser.role === 2
                  ? "/sells"
                  : "/pos"
              }
            />
          }
        />
        <Route path="pos/*" element={<Pos />} />
        <Route path="users/user-form/">
          <Route path=":id" element={<AddEditUser />} />
          <Route path="" element={<AddEditUser />} />
        </Route>
        <Route path="sell_return/:id" element={<ReturnForm />} />
        {_authUser.role !== 3 && (
          <>
            <Route path="currencies" element={<Currencies />} />
            <Route path="view_currency/:id" element={<CurrRates />} />
            <Route path="products" element={<Products />} />
            <Route path="products/product-form/">
              <Route path=":id" element={<AddEditProd />} />
              <Route path="" element={<AddEditProd />} />
            </Route>
            <Route path="categories" element={<Categories />} />
            <Route path="expenses" element={<ExpsCat />} />
            <Route path="categories/cat-form/:id" element={<AddEditCat />} />
            <Route path="purchases" element={<Purchases />} />
            <Route path="purchases/multi-purchase-form">
              <Route path=":id" element={<AddMultPurch />} />
              <Route path="" element={<AddMultPurch />} />
            </Route>
            <Route path="purchases-return" element={<PurchReturn />} />
            <Route path="purchases-return/purchase-return-form">
              <Route path=":id" element={<AddEditPurchReturn />} />
              <Route path="" element={<AddEditPurchReturn />} />
            </Route>

            <Route path="suppliers" element={<Suppliers />} />
            <Route path="/view-supplier/:id" element={<ViewSplr />} />
            <Route path="suppliers/supplier-form">
              <Route path=":id" element={<AddEditSplr />} />
              <Route path="" element={<AddEditSplr />} />
            </Route>

            <Route path="customers" element={<Customers />} />
            <Route path="/view-customer/:id" element={<ViewCstmr />} />
            <Route path="customers/customer-form">
              <Route path=":id" element={<AddEditCstmr />} />
              <Route path="" element={<AddEditCstmr />} />
            </Route>

            <Route path="sells" element={<Sells />} />

            <Route path="stock-transfer" element={<StockTransfers />} />
            <Route
              path="stock-transfer/stock-transfer-form/:id"
              element={<AddEditTransfer />}
            />

            <Route path="inventory" element={<Inventory />} />
            <Route
              path="inventory/inventory-form/:id"
              element={<AddEditInventory />}
            />

            <Route path="payment_methods" element={<PayMethods />} />
          </>
        )}
        {_authUser.role === 1 && (
          <>
            <Route path="/dashboard" element={<Home />} />
            <Route path="users" element={<Users />} />
            <Route path="sell-returns" element={<SellReturns />} />
            <Route path="salary-payment" element={<SalaryPayment />} />
            <Route path="salary-history" element={<SalaryHistory />} />
            <Route path="employee-history" element={<EmployeeHistory />} />
            <Route path="paybills" element={<PayBills />} />
            <Route path="store-report" element={<StoreReports />} />
            <Route path="product-report" element={<ProductReport />} />
            <Route path="purchase-report" element={<PurchaseReport />} />
            <Route path="register-report" element={<RegReport />} />
            <Route path="expense-report" element={<ExpenseReport />} />
            <Route path="products-sell-volume" element={<ProdSellVol />} />
            <Route path="employee-report" element={<EmployeeReport />} />
            <Route path="top-selling" element={<TopSelling />} />
            <Route path="purchase-return-report" element={<ReturnProd />} />
            <Route path="stores" element={<Stores />} />
            <Route path="settings_form" element={<Stores />} />
            <Route path="stores/add-store-form">
              <Route path=":id" element={<AddEditStore />} />
              <Route path="" element={<AddEditStore />} />
            </Route>
            <Route path="pos_items" element={<PosSettings />} />
            <Route path="default_store" element={<DefaultStore />} />
            <Route path="setting_form" element={<OrgForm />} />
          </>
        )}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Dashboard>
  );
};

const mapStateToProps = (state) => ({
  _authUser: state.pos.authUser,
});

const mapActiontoProps = {};

export default connect(mapStateToProps, mapActiontoProps)(DashRoute);
