import React, { useContext } from "react";
import {
  AttachMoneyRounded,
  RequestQuoteRounded,
  ShoppingBasketRounded,
  ShoppingCartCheckoutRounded,
} from "@mui/icons-material";
import { Grid, IconButton, Paper } from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../App";
import { controller, endpoints } from "../../api/actions/api";
import * as actions from "../../api/actions/pos";
import { connect } from "react-redux";

export const Widget = (props) => {
  const { title, value, icon } = props;
  const { org } = useContext(AppContext);
  return (
    <Paper className="p-20">
      <div className="d-flex j-between a-center">
        <span className="gray-color fs-16 fw-5">{title}</span>
        <IconButton className="bg-primaryXS primary-color">{icon}</IconButton>
      </div>
      <div className="mt-20 mb-5">
        <span className="fs-16">{org && org.curSymbol} </span>
        <span className=" fw-5 fs-26">{value}</span>
      </div>
    </Paper>
  );
};

const SalesAccWidget = ({ _getById }) => {
  const { t } = useTranslation();
  const { selStore } = useContext(AppContext);
  const [acc, setAcc] = useState();

  useEffect(() => {
    selStore &&
      _getById(
        endpoints.HomeReport + controller.MonthlyAcc,
        selStore,
        null,
        (res) => res.status === 200 && setAcc(res.result)
      );
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selStore]);

  return acc ? (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Widget
            title={t("totalSales")}
            icon={<ShoppingCartCheckoutRounded />}
            value={acc.totalSell.toLocaleString()}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Widget
            title={t("profit")}
            icon={<AttachMoneyRounded />}
            value={acc.totalProfit.toLocaleString()}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Widget
            title={t("purchases")}
            icon={<ShoppingBasketRounded />}
            value={acc.totalPurch.toLocaleString()}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Widget
            title={t("expense")}
            icon={<RequestQuoteRounded />}
            value={acc.totalExpenses.toLocaleString()}
          />
        </Grid>
      </Grid>
    </div>
  ) : (
    <span>Loading...</span>
  );
};

const mapStateToProps = (state) => ({
  _authUser: state.pos.authUser,
});

const mapActionToProps = {
  _getById: actions.getById,
};

export default connect(mapStateToProps, mapActionToProps)(SalesAccWidget);
