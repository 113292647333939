import React, { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import { AppContext } from "../../App";
import { useTranslation } from "react-i18next";

const PrintRecept = ({ data }) => {
  const { selStore, stores, org } = useContext(AppContext);
  const [store, setStore] = useState({});
  const { t } = useTranslation();

  useEffect(() => {
    setStore({ ...stores.find((j) => j.id === selStore) });
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selStore]);

  const priceLabelCls = {
    display: "flex",
    justifyContent: "space-between",
    padding: "3px 0px",
    borderBottom: "1px solid #e0e0e0",
    fontSize: "12px",
  };

  return org && org.id && data && data.id ? (
    <div style={{ width: "88mm", fontFamily: "arial" }}>
      <div
        style={{
          justifyContent: "center",
          display: "grid",
          alignItem: "center",
          textAlign: "center",
        }}
      >
        <span style={{ fontSize: "24px", fontWeight: "500" }}>
          {store.storeName}
        </span>
        <span
          style={{
            fontSize: "14px",
            maxWidth: "400px",
            marginTop: "10px",
            lineHeight: "15pt",
          }}
        >
          {store.address}
        </span>
        <span
          style={{
            fontSize: "14px",
            maxWidth: "400px",
            marginTop: "10px",
          }}
        >
          {store.phone}
        </span>
      </div>
      <div
        style={{
          marginTop: "25px",
          fontSize: "14px",
          display: "grid",
          gap: "4px",
        }}
      >
        <span>
          {t("invoiceNo")} : {data.sellId}
        </span>
        <span style={{ marginBottom: "12px" }}>
          {t("sellReturnId")} : {data.id}
        </span>

        <span>
          {t("sellDate")}: {data.sell && data.sell.sellDate}
        </span>
        <span>
          {t("refundDate")}: {data.refundDate}
        </span>
      </div>
      <div style={{ borderBottom: "3px dotted #e0e0e0" }} />

      {/* Product List */}
      <div style={{ marginTop: "20px" }}>
        {/* Header */}
        <div
          style={{
            display: "flex",
            fontWeight: "500",
            borderBottom: "1px solid #e0e0e0",
            fontSize: "14px",
          }}
        >
          <span style={{ width: "60%" }}>{t("itemName")}</span>
          <span style={{ width: "20%", textAlign: "center" }}>{t("qty")}</span>
          <span style={{ width: "20%" }}>{t("price")}</span>
        </div>
        {/* Body */}
        {data.items &&
          data.items.map((item, i) => (
            <div
              key={i}
              style={{
                display: "flex",
                fontSize: "12px",
                padding: "5px 5px 5px 0px",
                borderBottom: "1px solid #e0e0e0",
              }}
            >
              <span style={{ width: "60%" }}>{item.product.name}</span>
              <span style={{ width: "20%", textAlign: "center" }}>
                {item.returnQty}
              </span>
              <span style={{ width: "20%" }}>
                {org.curSymbol +
                  " " +
                  item.price.toLocaleString("en-US", {
                    maximumFractionDigits: 2,
                  })}
              </span>
            </div>
          ))}
      </div>
      <div style={{ borderBottom: "3px dotted var(--graySM-color)" }} />
      <div style={{ display: "flex", fontWeight: "500", fontSize: "12px" }}>
        <div style={{ width: "40%", marginTop: "20px" }}>
          <span>
            {t("thanksForYourPurchase")} <br /> {t("seeYouAgain")}
          </span>
        </div>
        <div style={{ width: "60%" }}>
          <div style={priceLabelCls}>
            <span>{t("refund")}:</span>
            <span>
              {org.curSymbol +
                " " +
                data.refundAmt.toLocaleString("en-US", {
                  maximumFractionDigits: 2,
                })}
            </span>
          </div>
          <div style={priceLabelCls}>
            <span>{t("discount")}:</span>
            <span>
              {"(-) " +
                org.curSymbol +
                " " +
                data.cutDiscount.toLocaleString("en-US", {
                  maximumFractionDigits: 2,
                })}
            </span>
          </div>
          <div style={priceLabelCls}>
            <span>{t("tax")}:</span>
            <span>
              {"(+) " +
                org.curSymbol +
                " " +
                data.refundTaxAmt.toLocaleString("en-US", {
                  maximumFractionDigits: 2,
                })}
            </span>
          </div>
          <div style={priceLabelCls}>
            <span>{t("payableRefund")}:</span>
            <span>
              {org.curSymbol +
                " " +
                data.payableRefund.toLocaleString("en-US", {
                  maximumFractionDigits: 2,
                })}
            </span>
          </div>
        </div>
      </div>
    </div>
  ) : (
    "Loading..."
  );
};

const mapStateToProps = (state) => ({
  store: state.pos.defStore,
});

const mapActionToProps = {};

export default connect(mapStateToProps, mapActionToProps)(PrintRecept);
