import React, { useContext, useState, useEffect } from "react";
import { Divider, Grid, Typography } from "@mui/material";
import { Input, Button, InputAuto } from "../../controls";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../App";
import { connect } from "react-redux";
import * as actions from "../../api/actions/pos";
import { endpoints, controller } from "../../api/actions/api";
import { Model, UseForm } from "../../hooks";

const AddMultPayment = (props) => {
  const {
    setShowForm,
    setMultPays,
    totalAmt,
    _getById,
    isSpecificAmt = true,
  } = props;
  const { t } = useTranslation();
  const { org } = useContext(AppContext);
  const { MultPayMdl } = Model();
  const { values, errors, setErrors, handleInpChg, setValues } =
    UseForm(MultPayMdl);
  const [currs, setCurrs] = useState([]);
  const [methods, setMethods] = useState([]);
  const [selMethodDtl, setSelMethodDtl] = useState({});
  const [remainingAmt, setRemainingAmt] = useState({
    main: 0,
    locale: 0,
  });

  useEffect(() => {
    if (org && org.id) {
      _getById(
        endpoints.Currency + controller.GetAllByOrg,
        org.id,
        null,
        (res) =>
          setCurrs([
            ...res.result
              .filter((j) => j.id !== org.currId)
              .map((j) => ({ ...j, label: `${j.currency} (${j.symbol})` })),
          ])
      );

      _getById(
        endpoints.PayMethod + controller.GetAllByOrg,
        org.id,
        null,
        (res) =>
          setMethods([...res.result.map((j) => ({ ...j, label: j.title }))])
      );
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [org]);

  useEffect(() => {
    var _methodDtls = methods.find((j) => j.id === values.payMethodId);
    if (_methodDtls) {
      setSelMethodDtl(_methodDtls);
      setValues((j) => ({
        ...j,
        addtlCharge: _methodDtls.addtlCharge,
      }));
    } else setSelMethodDtl({});
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.payMethodId]);

  useEffect(() => {
    setValues((j) => ({
      ...j,
      curr: currs.find((x) => x.id === j.currId),
      amount: "",
    }));

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.currId]);

  useEffect(() => {
    if (isSpecificAmt) {
      setValues((j) => ({
        ...j,
        amtToPayLocal: values.curr
          ? (totalAmt / values.curr.currentRate).toFixed(2)
          : "",
      }));
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.curr]);

  useEffect(() => {
    // Calculate Additional Charge
    var _addtlChargeAmt = 0;
    if (selMethodDtl && selMethodDtl.isAddtlCharge) {
      _addtlChargeAmt = (
        (selMethodDtl.addtlCharge / 100) *
        parseFloat(values.amount || 0)
      ).toFixed(2);
      setValues((j) => ({
        ...j,
        addtlChargeAmt: _addtlChargeAmt,
      }));
    } else {
      _addtlChargeAmt = 0;
      setValues((j) => ({
        ...j,
        addtlCharge: "",
        addtlChargeAmt: "",
      }));
    }

    var _amt = parseFloat(values.amount) - _addtlChargeAmt;
    var _mainAmt = (_amt * (values.curr ? values.curr.currentRate : 0)).toFixed(
      2
    );
    setValues((j) => ({
      ...j,
      mainAmt: _mainAmt,
    }));

    if (isSpecificAmt) {
      setRemainingAmt({
        main: totalAmt - _mainAmt,
        locale: parseFloat(values.amtToPayLocal || 0) - _amt,
      });
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.amount, selMethodDtl]);

  const validateForm = () => {
    let temp = {};
    temp.currId = values.currId !== "" ? "" : t("currencyIsRequired");
    temp.amount = values.amount !== "" ? "" : t("amountIsRequired");
    temp.payMethodId = values.payMethodId !== "" ? "" : t("payMethodIsReq");

    setErrors({ ...temp });
    return Object.values(temp).every((x) => x === "");
  };

  const handleSubmit = () => {
    if (validateForm()) {
      var _methodDtls = methods.find((j) => j.id === values.payMethodId);
      if (_methodDtls) {
        setMultPays((j) => [
          ...j,
          {
            ...values,
            payMethod: _methodDtls,
            amount:
              parseFloat(values.amount || 0) -
              parseFloat(values.addtlChargeAmt || 0),
          },
        ]);
        setShowForm(false);
      } else
        setErrors((j) => ({
          ...j,
          payMethodId: "Payment method details not found. Select again",
        }));
    }
  };

  const handleAmt = (e) => {
    if (values.currId) {
      handleInpChg(e);
      setErrors((j) => ({ ...j, currId: "" }));
    } else
      setErrors((j) => ({
        ...j,
        currId: t("pleaseFirstSelectAmt"),
        amount: "",
      }));
  };

  return (
    <div className="bg-grayXS">
      <Grid container spacing={2} className="p-20 pt-40">
        <Grid item xs={12} className="tx-center">
          <Typography variant="h5" className="fs-22 gray-color fw-5 tx-upp">
            {t("multcurrency")}
          </Typography>
          <div className="bs-bottom mx-w-80 m-auto mt-10 mb-30" />
        </Grid>
        {isSpecificAmt && (
          <>
            <Grid item xs={12} className="mb-30">
              <div className="d-flex flex-wrap g-10 a-center j-between">
                <span className="tx-upp gray-color border-left">
                  {t("amountToBePaid")}:{" "}
                </span>
                <span className="fw-5 gray-color d-grid">
                  <span>
                    {org &&
                      org.curSymbol +
                        " " +
                        parseFloat(totalAmt).toLocaleString("en-US", {
                          maximumFractionDigits: 2,
                        })}
                  </span>
                  {values.amtToPayLocal && (
                    <>
                      <Divider />
                      <span>
                        {values.curr &&
                          values.curr.symbol +
                            " " +
                            parseFloat(values.amtToPayLocal).toLocaleString(
                              "en-US",
                              {
                                maximumFractionDigits: 2,
                              }
                            )}
                      </span>
                    </>
                  )}
                </span>
              </div>
              <Divider className="mt-5 mb-5" />
              {values.curr && parseFloat(values.amount || 0) > 0 && (
                <div className="d-flex flex-wrap g-10 a-center j-between">
                  <span className="tx-upp gray-color border-left">
                    {t("remainingAmt")}:{" "}
                  </span>
                  <span className="fw-5 gray-color d-grid">
                    <span>
                      {org &&
                        org.curSymbol +
                          " " +
                          remainingAmt.main.toLocaleString()}
                    </span>
                    {remainingAmt.locale > 0 && (
                      <>
                        <Divider />
                        <span>
                          {values.curr.symbol +
                            " " +
                            parseFloat(remainingAmt.locale).toLocaleString()}
                        </span>
                      </>
                    )}
                  </span>
                </div>
              )}
            </Grid>
          </>
        )}

        <Grid item xs={12} md={6}>
          <InputAuto
            label={t("currency")}
            name="currId"
            value={values.currId}
            error={errors.currId}
            onChange={handleInpChg}
            options={currs}
            required
          />
          {values.curr && (
            <>
              <div className="d-flex g-10 pt-5">
                <span className="fs-12 tx-upp gray-color">
                  {t("currentRate")}:{" "}
                </span>
                <span className="fs-14 fw-5 gray-color">
                  {org && org.curSymbol + " " + values.curr.currentRate}
                </span>
              </div>
            </>
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          <InputAuto
            label={t("paymentMethod")}
            name="payMethodId"
            value={values.payMethodId}
            error={errors.payMethodId}
            onChange={handleInpChg}
            options={methods}
            required
          />
          {values.addtlCharge > 0 && (
            <div className="d-flex a-center g-5 fs-12 gray-color fw-5 ml-5 mt-5">
              <div className="d-flex">
                <span>{selMethodDtl.addtlChargeTitle}: &nbsp;</span>
                <span className="fw-5">{values.addtlCharge}%</span>
              </div>
              {values.addtlChargeAmt > 0 && (
                <>
                  |
                  <div className="d-flex">
                    <span>{t("amount")}: &nbsp;</span>
                    <span className="fw-5">
                      -{" "}
                      {values.curr.symbol +
                        " " +
                        parseFloat(values.addtlChargeAmt).toLocaleString(
                          "en-US",
                          {
                            maximumFractionDigits: 2,
                          }
                        )}
                    </span>
                  </div>
                </>
              )}
            </div>
          )}
        </Grid>
        <Grid item xs={12}>
          <Input
            fullWidth
            label={t("amount")}
            required={true}
            type="number"
            name="amount"
            onInput={(e) => (e.target.value > 0 ? e : (e.target.value = ""))}
            value={values.amount}
            error={errors.amount}
            onChange={(e) => {
              if (
                !isSpecificAmt ||
                (values.curr &&
                  ((
                    parseFloat(e.target.value) * values.curr.currentRate
                  ).toFixed(2) <= parseFloat(totalAmt) ||
                    values.amount.length > e.target.value.length))
              )
                handleAmt(e);
            }}
          />
          {parseFloat(values.mainAmt) > 0 && (
            <div className="d-flex fs-12 mt-5 a-center gray-color tx-upp">
              <span>{t("localAmount")}: &nbsp;</span>
              <span className="fw-5 fs-14">
                {org.curSymbol +
                  " " +
                  parseFloat(values.mainAmt).toLocaleString()}
              </span>
            </div>
          )}
        </Grid>

        <Grid
          item
          xs={12}
          className="tx-right d-flex mt-20"
          sx={{ flexWrap: { xs: "wrap", md: "initial" } }}
        >
          <Button
            onClick={handleSubmit}
            className="w-100pr btn-primary"
            size="large"
          >
            {t("add")}
          </Button>
          <Button
            onClick={() => setShowForm(false)}
            className="error-color bg-errorSM w-100pr"
            size="large"
            sx={{ ml: { md: "10px" }, mt: { xs: "15px", md: "0px" } }}
          >
            {t("close")}
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapActionToProps = {
  _getById: actions.getById,
};

export default connect(mapStateToProps, mapActionToProps)(AddMultPayment);
