import { RandomCode } from "./Method";

export default function Model() {
  const LoginMdl = () => ({
    userName: "",
    password: "",
  });

  const CurrMdl = () => ({
    id: "",
    symbol: "",
    currency: "",
    name: "",
    currUnit: "",
    currSubunit: "",
    isActive: true,

    storeId: "",
  });

  const ReturnProdMdl = () => ({
    id: "",
    sellId: "",
    items: [],
    refundAmt: 0,
    refundTaxAmt: 0,
    cutDiscount: 0,
    payableRefund: 0,
  });

  const MultPayMdl = () => ({
    uid: RandomCode(15),
    currId: "",
    payMethodId: 1,
    curr: "",
    amount: "",
    mainAmt: "",
    amtToPayLocal: "",
    addtlCharge: "",
    addtlChargeAmt: "",
  });

  const PayMethodMdl = () => ({
    id: "",
    title: "",
    subtitle: "",
    storeId: "",
    isAddtlCharge: false,
    addtlChargeTitle: "",
    addtlCharge: "",
  });

  const SupplierPaymentMdl = () => ({
    id: 0,
    purchaseId: "",
    amount: "",
    storeId: "",
    multiPayAmt: "",
    multPays: [],

    nextDueDate: "",
  });

  const CurrRateMdl = () => ({
    id: "",
    currId: "",
    rate: "",

    storeId: "",
    orgId: "",
  });

  const ForgotMdl = () => ({
    userName: "",
    email: "",
    forgotStep: "",
    verCode: "",
    password: "",
    confirmPass: "",
  });

  const CancelCartMdl = () => ({
    id: "",
    cancelItem: "",
    userId: "",
    storeId: "",
  });

  const CstmrMdl = () => ({
    id: 0,
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    gender: "",
    dob: "",

    specialTaxPayer: false,
    vatTax: "",
    cedulaRIFCode: "",
    taxExempt: false,

    country: "",
    city: "",
    state: "",
    zip: "",
    address1: "",
    address2: "",

    cstmrCode: "",
    company: "",

    website: "",
    twitter: "",
    note: "",
    photo: "",
    photoFile: "",
    accBalType: 2,
    balLimit: "",

    // card details
    nameOnCard: "",
    cardNumber: "",
    expiryDate: "",
    securityCode: "",
  });

  const OrgMdl = () => ({
    id: "",
    name: "",
    nameLocale: "",
    email: "",
    phone: "",
    currId: "",
    enableMultCurr: false,
    country: "",
    city: "",
    state: "",
    zip: "",
    address: "",
    userId: "",
    logo: "",
    logoFile: "",
  });

  const MakePayMdl = () => ({
    id: 0,
    sellId: "",
    amount: "",
    nextDueDate: "",

    userId: "",
    storeId: "",

    multPayAmt: "",
    multPays: [],
  });

  const PaySplrMdl = () => ({
    supplierId: "",
    purchaseId: "",
    amount: "",
    nextPayDate: "",
  });

  const SellMdl = () => ({
    id: 0,
    sellItems: [],
    payMethodId: 1,
    taxPct: "",
    discount: "",
    discountType: 1,
    subtotal: "",
    received: "",
    change: "",
    note: "",
    sellType: 1,
    dueAmt: "",
    totalPay: "",
    dueDate: "",
    creditAmt: "",
    changeAddedCredit: false,
    multiPayAmt: "",
    multPays: [],
    diffAmt: "",
    taxExempt: false,
    wasSpecialTaxpayer: false,

    cstmrId: "",
    userId: "",
    storeId: "",
  });

  const VoidSellMdl = () => ({
    id: 0,
    items: "",
    subtotal: "",
    userId: "",
    storeId: "",
  });

  const ProdMdl = () => ({
    id: 0,
    name: "",
    barcode: "",
    categoryId: "",
    parentCatId: "",
    unit: "",
    tax: "",
    descr: "",
    purchPrice: "",
    profitMargin: "",
    sellPrice: "",
    alertQty: "",
    imgs: [],
    imgsFile: [],
  });

  const CatMdl = () => ({
    id: 0,
    name: "",
    descr: "",
    parentCatId: "",
  });

  const ExpsMdl = () => ({
    id: 0,
    amount: "",
    note: "",
    expenseCatId: "",
    addDate: "",
    addUser: "",
    storeId: "",
  });

  const PurchaseMdl = () => ({
    id: 0,
    supplierId: "",
    purchDate: new Date().toLocaleString(),
    payDate: "",
    paymentType: "",
    paymentDue: "",
    paidAmt: "",
    discount: "",
    purchItems: [],
    recept: "",
    receptFile: "",
    userId: "",
    storeId: "",
  });

  const PurchReturnMdl = () => ({
    id: 0,
    purchaseId: "",
    purchReturnItems: [],
    returnDate: new Date().toLocaleString(),
    userId: "",
  });

  const ExpensesMdl = () => ({
    id: 0,
    expsType: "",
    paybleTo: "",
    date: "",
    payment: "",
    note: "",
    doc: "",
    docFile: "",
  });

  const ExpsCatMdl = () => ({
    id: 0,
    name: "",
    descr: "",
  });

  const SuppliersMdl = () => ({
    id: 0,
    company: "",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    city: "",
    state: "",
    zip: "",
    address: "",
    productSale: "",
    note: "",
  });

  const UserMdl = () => ({
    id: 0,
    prefix: "",
    firstName: "",
    lastName: "",
    dob: "",
    ssn: "",
    gender: "",
    phone: "",
    email: "",
    address: "",
    city: "",
    state: "",
    zip: "",
    language: "",

    storeId: "",
    role: "",
    isActive: "",
    userName: "",
    password: "",
    confirmPass: "",

    salaryType: "",
    salary: "",
  });

  const StockTransMdl = () => ({
    id: 0,
    date: "",
    fromStoreId: "",
    toStoreId: "",
    note: "",
    items: "",
  });

  const InventoryMdl = () => ({
    id: 0,
    productId: "",
    storeId: "",
    inInventory: "",
  });

  const PaymentMdl = () => ({
    id: 0,
    fromDate: "",
    toDate: "",
    totalHours: "",
    totalSalary: "",
    payDate: "",
    userId: "",
  });

  const StoreMdl = () => ({
    id: 0,
    storeName: "",
    address: "",
    city: "",
    state: "",
    zip: "",
    phone: "",
    tax: "",
    currency: "",

    firstName: "",
    lastName: "",
    dob: "",
    ssn: "",
    gender: "",
    mngAddress: "",
    mngCity: "",
    mngState: "",
    mngZip: "",
    mngPhone: "",

    logo: "",
    logoFile: "",
  });

  return {
    LoginMdl,
    ForgotMdl,
    ProdMdl,
    CatMdl,
    ExpsMdl,
    PurchaseMdl,
    PurchReturnMdl,
    ExpensesMdl,
    ExpsCatMdl,
    SuppliersMdl,
    UserMdl,
    StockTransMdl,
    InventoryMdl,
    PaymentMdl,
    StoreMdl,
    SellMdl,
    VoidSellMdl,
    CancelCartMdl,
    OrgMdl,
    CstmrMdl,
    MakePayMdl,
    CurrMdl,
    CurrRateMdl,
    PaySplrMdl,
    SupplierPaymentMdl,
    PayMethodMdl,
    MultPayMdl,
    ReturnProdMdl,
  };
}
