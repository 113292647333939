import { Button, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { controller, endpoints } from "../../api/actions/api";
import { AppContext } from "../../App";
import { Input } from "../../controls";
import * as actions from "../../api/actions/pos";
import { useTranslation } from "react-i18next";
import MultPayments from "../multCurrency/MultPayments";

const CashInDrawer = (props) => {
  const { _get, setShow, _authUser } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setCLoader, org } = useContext(AppContext);
  const [multPays, setMultPays] = useState([]);
  const [value, setValue] = useState("");
  const [error, setError] = useState("");
  const [totalInDrawer, setTotalInDrawer] = useState(0);
  const [totalMultPay, setTotalMultPay] = useState(0);

  useEffect(() => {
    setTotalInDrawer(parseFloat(value | 0) + parseFloat(totalMultPay));
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, totalMultPay]);

  useEffect(() => {
    setTotalMultPay(
      multPays.map((j) => parseFloat(j.mainAmt)).reduce((x, y) => x + y, 0)
    );

    return () => {};
  }, [multPays]);

  const handleSubmit = () => {
    if (value !== "") {
      localStorage.setItem(
        "posOpen",
        JSON.stringify({
          userId: _authUser.id,
          cashInDrawer: totalInDrawer.toLocaleString("en-US", {
            maximumFractionDigits: 2,
          }),
          amount: value.toLocaleString("en-US", { maximumFractionDigits: 2 }),
          timeIn: new Date().toLocaleString(),
          multPays: multPays,
          multiPayAmt: totalMultPay.toLocaleString("en-US", {
            maximumFractionDigits: 2,
          }),
        })
      );
      setShow(false);
    } else setError(t("amountIsRequired"));
  };

  const handleLogout = () => {
    setCLoader(true);
    const onComplete = (res) => {
      setCLoader(false);
      navigate("/");
    };
    _get(
      endpoints.Auth + controller.Logout,
      actions.ActionType.Auth,
      onComplete
    );
  };

  return (
    <Grid container spacing={3} className="p-15 pt-30 bg-grayXS">
      <Grid item xs={12} className="tx-center">
        <Typography variant="h5" className="fs-22 gray-color fw-5 tx-upp">
          {t("cashInDrawer")}
        </Typography>
        <div className="bs-bottom mx-w-80 m-auto mt-5 mb-20" />
      </Grid>
      {org.enableMultCurr && (
        <Grid item xs={12}>
          <div className="d-flex gray-color a-center">
            <span className="tx-upp">
              Total Amount: &nbsp;&nbsp;&nbsp;{org && org.curSymbol}
            </span>
            <span className="fw-5 fs-18">
              &nbsp;{parseFloat(totalInDrawer).toLocaleString()}
            </span>
          </div>
        </Grid>
      )}
      <Grid item xs={12}>
        <Input
          onChange={(e) => setValue(e.target.value)}
          label={t("amount")}
          name="amount"
          type="number"
          error={error}
          fullWidth
        />
        {org.enableMultCurr && (
          <MultPayments
            {...{
              multPays,
              setMultPays,
              totalAmt: 0,
              isSpecificAmt: false,
            }}
          />
        )}
      </Grid>
      <Grid item xs={12} className="d-flex g-10">
        <Button
          onClick={handleSubmit}
          className="btn-secondary w-100pr"
          size="large"
        >
          {t("Enter")}
        </Button>
        <Button
          onClick={() => navigate("/")}
          className="bg-graySM dark-color w-100pr"
          size="large"
        >
          {t("back")}
        </Button>
        <Button
          onClick={handleLogout}
          className="error-color bg-errorSM w-100pr"
          size="large"
        >
          {t("logout")}
        </Button>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  _authUser: state.pos.authUser,
});

const mapActionToProps = {
  _get: actions.get,
};

export default connect(mapStateToProps, mapActionToProps)(CashInDrawer);
