import React, { useContext } from "react";
import { AppContext } from "../../App";
import { useTranslation } from "react-i18next";
import { Box, IconButton } from "@mui/material";
import { CloseRounded } from "@mui/icons-material";
import { Table } from "../../layout";

const ViewMultiPayments = ({ multPays = [], setShow }) => {
  const { org } = useContext(AppContext);
  const { t } = useTranslation();

  const handleClose = () => {
    setShow(false);
  };

  return (
    <Box sx={{ minWidth: { md: "800px", sm: "auto" } }}>
      <div className="d-flex j-between a-center g-10 mt-5 mb-10">
        <span className="fs-18 fw-5 gray-color tx-upp ml-10 border-left ">
          {t("multiCurrencyPayment")}
        </span>
        <IconButton onClick={handleClose}>
          <CloseRounded />
        </IconButton>
      </div>

      {
        <Table
          showSearch={false}
          showHeader={false}
          ActionComp={() => <> </>}
          HeaderComp={() => <> </>}
          tblHead={[
            { id: "method", label: t("method") },
            { id: "amt", label: t("paidLocal") },
            {
              id: "mainCurr",
              label: `${org.curName} (${org.curSymbol} ${multPays
                .map((j) => parseFloat(j.mainAmt))
                .reduce((x, y) => x + y, 0)
                .toLocaleString("en-US", {
                  maximumFractionDigits: 2,
                })})`,
            },
          ]}
          tblData={[
            ...multPays.map((j) => ({
              ...j,
              method: j.payMethod && j.payMethod.title,
              amt:
                j.curr.symbol +
                " " +
                j.amount.toLocaleString("en-US", { maximuFractionDigits: 2 }),
              mainCurr:
                org &&
                org.curSymbol +
                  " " +
                  parseFloat(j.mainAmt).toLocaleString("en-US", {
                    maximumFractionDigits: 2,
                  }),
            })),
          ]}
        />
      }
    </Box>
  );
};

export default ViewMultiPayments;
