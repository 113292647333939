import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import { controller, endpoints } from "../../api/actions/api";
import { AppContext } from "../../App";
import { Dialog, Table } from "../../layout";
import * as actions from "../../api/actions/pos";
import { useTranslation } from "react-i18next";
import { Box, IconButton, Tooltip } from "@mui/material";
import { CSVLink } from "react-csv";
import { ChangeArrayKey } from "../../hooks/Method";
import ViewExpense from "./ViewExpense";
import { VisibilityRounded } from "@mui/icons-material";

const ExpenseReport = (props) => {
  const { _getById } = props;
  const { setLoader, selStore, org } = useContext(AppContext);
  const { t } = useTranslation();
  const [csvHeader, setCsvHeader] = useState([]);
  const [tblData, setTblData] = useState([]);
  const [open, setOpen] = useState(false);
  const [viewData, setViewData] = useState();

  const onComplete = (res) => {
    var currSymbol = org && org.curSymbol;
    setLoader(false);
    res.status === 200 &&
      setTblData([
        ...res.result.map((obj) => ({
          ...obj,
          expenseType: obj.expenseCat.name,
          amount: currSymbol + " " + obj.amount,
          multiPayAmt: currSymbol + " " + obj.multiPayAmt,
          total:
            currSymbol +
            " " +
            (parseFloat(obj.amount) + parseFloat(obj.multiPayAmt)).toFixed(2),
          paidBy: obj.user.firstName + " " + obj.user.lastName,
        })),
      ]);
  };

  useEffect(() => {
    if (selStore && org) {
      setLoader(true);
      _getById(
        endpoints.Expense + controller.GetByStoreId,
        selStore,
        null,
        onComplete
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selStore, org]);

  useEffect(() => {
    setCsvHeader([
      { key: "addDate", label: t("payDate") },
      { key: "expenseType", label: t("expenseType") },
      { key: "amount", label: t("localAmount") },
      { key: "multiPayAmt", label: t("inMultCurrency") },
      { key: "total", label: t("total") },
      { key: "paidBy", label: t("paidBy") },
    ]);

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleView = (val) => {
    setViewData(val);
    setOpen(!open);
  };

  const HeaderComp = () => (
    <div className="d-flex flex-wrap g-5">
      <Box
        className="d-flex"
        sx={{
          width: { xs: "100%", md: "auto" },
        }}
      >
        <CSVLink
          data={tblData}
          headers={csvHeader}
          filename={`${t("expenseReport")} (${new Date().toDateString()}).csv`}
          target="_blank"
          className="d-flex a-center j-center p-15 fs-16 fw-5 tx-upp primary-color bg-primarySM br-15"
        >
          {t("exportCSV")}
        </CSVLink>
      </Box>
    </div>
  );

  const ActionComp = (props) => (
    <Box>
      <Tooltip arrow title={t("view")}>
        <IconButton
          onClick={() => handleView(props)}
          size="small"
          className="btn-primary mr-5"
        >
          <VisibilityRounded className="fs-18 m-2" />
        </IconButton>
      </Tooltip>
    </Box>
  );

  return (
    <>
      <Table
        ActionComp={ActionComp}
        HeaderComp={HeaderComp}
        tblHead={[
          { id: "actions", label: t("actions") },
          ...ChangeArrayKey(csvHeader, "key", "id"),
        ]}
        tblData={tblData}
      />

      {open && (
        <Dialog show={open} onClose={false} maxWidth="lg">
          <ViewExpense {...{ setOpen, values: viewData }} />
        </Dialog>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  _store: state.pos.defStore,
});

const mapActionToProps = {
  _getById: actions.getById,
};

export default connect(mapStateToProps, mapActionToProps)(ExpenseReport);
