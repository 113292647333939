import { Box, IconButton, Tooltip } from "@mui/material";
import React, { useContext, useState } from "react";
import { Dialog, Table } from "../../layout";
import { ArrowBackRounded, ClearRounded } from "@mui/icons-material";
import { AppContext } from "../../App";
import { useTranslation } from "react-i18next";
import { Button } from "../../controls";
import AddMultPayment from "./AddMultPayment";

const MultCurr = (props) => {
  const {
    multPays,
    setMultPays,
    totalAmt,
    setOpenMultiPay,
    isSpecificAmt,
    Header,
    isCustomeHeader = false,
  } = props;
  const { org } = useContext(AppContext);
  const [showForm, setShowForm] = useState(false);
  const { t } = useTranslation();

  const handleDlt = (uid) => {
    setMultPays((x) => [...x.filter((j) => j.uid !== uid)]);
  };

  const handleAdd = () => {
    setShowForm(true);
  };

  const handleClose = () => {
    setOpenMultiPay(false);
  };

  const ActionComp = (props) => (
    <Box className="d-flex a-center g-10">
      <Tooltip arrow title={t("delete")}>
        <IconButton
          onClick={() => handleDlt(props.uid)}
          size="small"
          color="error"
        >
          <ClearRounded className="fs-18 m-2" />
        </IconButton>
      </Tooltip>
    </Box>
  );

  return (
    <>
      <Box>
        <div className="d-flex j-between br-15 a-center flex-wrap mb-15 bg-white p-5 g-10">
          {isCustomeHeader ? (
            <Header />
          ) : (
            <div className="d-flex j-center a-center g-10">
              <IconButton onClick={handleClose}>
                <ArrowBackRounded />
              </IconButton>
              <span className="fs-18 fw-5 gray-color tx-upp ml-10">
                {t("multPayment")}
              </span>
            </div>
          )}
          <Button
            onClick={() => handleAdd()}
            variant="text"
            sx={{
              width: { md: "180px", xs: "100%" },
              mb: { xs: "10px", md: "0px" },
            }}
          >
            {t("addPayment")}
          </Button>
        </div>

        <Table
          showSearch={false}
          showHeader={false}
          ActionComp={ActionComp}
          HeaderComp={() => <> </>}
          tblHead={[
            { id: "actions", label: t("actions") },
            { id: "method", label: t("paymentMethod") },
            { id: "amt", label: t("paidLocal") },
            {
              id: "mainCurr",
              label: `${org.curName} (${org.curSymbol} ${multPays
                .map((j) => parseFloat(j.mainAmt))
                .reduce((x, y) => x + y, 0)
                .toLocaleString("en-US", { maximuFractionDigits: 2 })})`,
            },
          ]}
          tblData={[
            ...multPays.map((j) => ({
              ...j,
              method: j.payMethod
                ? `${j.payMethod.title} ${
                    j.payMethod.subtitle && `(${j.payMethod.subtitle})`
                  }`
                : "",
              amt: j.curr.symbol + " " + parseFloat(j.amount).toLocaleString(),
              mainCurr:
                org &&
                org.curSymbol +
                  " " +
                  parseFloat(j.mainAmt).toLocaleString("en-US", {
                    maximumFractionDigits: 2,
                  }),
            })),
          ]}
        />
      </Box>

      {showForm && (
        <Dialog show={showForm} onClose={false} maxWidth="sm">
          <AddMultPayment
            {...{ multPays, setMultPays, setShowForm, totalAmt, isSpecificAmt }}
          />
        </Dialog>
      )}
    </>
  );
};

export default MultCurr;
