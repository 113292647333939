import {
  ClearRounded,
  EditRounded,
  VisibilityRounded,
} from "@mui/icons-material";
import { Button, IconButton, Tooltip } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../App";
import { Alert, Dialog, Table } from "../../layout";
import * as actions from "../../api/actions/pos";
import { controller, endpoints } from "../../api/actions/api";
import ViewPurch from "./ViewPurch";
import { IsEmptyObj } from "../../hooks/Method";
import { useTranslation } from "react-i18next";
import { CSVLink } from "react-csv";

const Purchases = (props) => {
  const { _getById, _delete } = props;
  const { t } = useTranslation();
  const { setLoader, selStore, org } = useContext(AppContext);
  const navigate = useNavigate();
  const [showForm, setShowForm] = useState(false);
  const [viewData, setViewData] = useState({});
  const [csvHeader, setCsvHeader] = useState([]);
  const [tblData, setTblData] = useState([]);
  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    title: "",
    subTitle: "",
  });

  const handleMultPurch = () => {
    navigate("multi-purchase-form");
  };

  const handleDlt = (id) => {
    setAlert({
      isOpen: true,
      type: "confirmation",
      title: t("areYouSure"),
      subTitle: t("dltTitle"),
      onConfirm: () => {
        const onComplete = (res) => {
          if (res.status === 200) {
            setAlert({
              isOpen: true,
              type: "success",
              title: t("dltSuccessTitle"),
              subTitle: res.msg,
            });
            setTblData((x) => [...x.filter((j) => j.id !== res.result.id)]);
          } else
            setAlert({
              isOpen: true,
              type: "error",
              title: t("error"),
              subTitle: res.msg,
            });
        };
        _delete(endpoints.Purchase, id, actions.ActionType.RmvList, onComplete);
      },
    });
  };

  const handleView = (data) => {
    setViewData(data);
    setShowForm(!showForm);
  };

  useEffect(() => {
    if (selStore && org) {
      const onComplete = (res) => {
        setLoader(false);
        res.status === 200 &&
          setTblData(
            res.result.map((obj) => ({
              ...obj,
              supplier: obj.supplier.company,
              totalItem: obj.purchItems
                .map((j) => j.qty)
                .reduce((x, y) => x + y, 0),
              totalPurch:
                (org && org.curSymbol) +
                " " +
                obj.purchItems
                  .map((j) => {
                    var total = j.qty * j.purchPrice;
                    return total - (obj.discount / 100) * total;
                  })
                  .reduce((x, y) => x + y, 0)
                  .toFixed(2),
              dueAmt: org.curSymbol + " " + obj.paymentDue,
              viewRecept: (
                <a href={obj.recept} target="_blank" rel="noreferrer" className="tx-und tx-upp fs-14 fw-5 gray-color">
                  {obj.recept && t("viewRecept")}
                </a>
              ),
            }))
          );
      };

      setLoader(true);
      _getById(
        endpoints.Purchase + controller.GetByStore,
        selStore,
        null,
        onComplete
      );
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selStore, org]);

  useEffect(() => {
    setCsvHeader([
      { key: "id", label: t("referenceNo") },
      { key: "supplier", label: t("supplier") },
      { key: "totalItem", label: t("totalItem") },
      { key: "dueAmt", label: t("dueAmount") },
      { key: "totalPurch", label: t("totalPurchase") },
      { key: "purchDate", label: t("purchaseDate") },
    ]);

    return () => {
      setCsvHeader();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const ActionComp = (props) => (
    <Box>
      <Tooltip arrow title={t("view")}>
        <IconButton
          onClick={() => handleView(props)}
          size="small"
          className="mr-5"
          color="primary"
        >
          <VisibilityRounded className="fs-18 m-2" />
        </IconButton>
      </Tooltip>
      <Tooltip arrow title={t("edit")}>
        <IconButton
          onClick={() => navigate(`multi-purchase-form/${props.id}`)}
          size="small"
          color="primary"
        >
          <EditRounded className="fs-18 m-2" />
        </IconButton>
      </Tooltip>
      <Tooltip arrow title={t("delete")}>
        <IconButton
          onClick={() => handleDlt(props.id)}
          size="small"
          color="error"
        >
          <ClearRounded className="fs-18 m-2" />
        </IconButton>
      </Tooltip>
    </Box>
  );

  const HeaderComp = () => (
    <div className="d-flex flex-wrap g-5">
      <Button
        onClick={handleMultPurch}
        className="btn-secondary"
        sx={{
          width: { md: "180px", xs: "100%" },
          fontSize: "17px",
          padding: "8px",
        }}
      >
        {t("addPurchases")}
      </Button>
      <Box
        className="d-flex"
        sx={{
          width: { xs: "100%", md: "auto" },
        }}
      >
        <CSVLink
          data={tblData}
          headers={csvHeader}
          filename={`${t("purchases")} (${new Date().toDateString()}).csv`}
          target="_blank"
          className="d-flex a-center j-center p-15 fs-16 fw-5 tx-upp primary-color bg-primarySM br-15"
        >
          {t("exportCSV")}
        </CSVLink>
      </Box>
    </div>
  );

  return (
    <>
      <Table
        ActionComp={ActionComp}
        HeaderComp={HeaderComp}
        tblHead={[
          { id: "actions", label: t("actions") },
          { id: "id", label: t("referenceNo") },
          { id: "supplier", label: t("supplier") },
          { id: "totalItem", label: t("totalItem") },
          { id: "dueAmt", label: t("dueAmount") },
          { id: "totalPurch", label: t("totalPurchase") },
          { id: "purchDate", label: t("purchaseDate") },
          { id: "viewRecept", label: t("recept") },
        ]}
        tblData={tblData}
      />
      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />
      {/* Edit Dialog */}
      <Dialog show={showForm} maxWidth="lg" onClose={false}>
        {!IsEmptyObj(viewData) && <ViewPurch {...{ setShowForm, viewData }} />}
      </Dialog>
    </>
  );
};

const mapStateToProps = (state) => ({
  _list: state.pos.list,
  _type: state.pos.type,
  _store: state.pos.defStore,
});

const mapActionToProps = {
  _getById: actions.getById,
  _delete: actions.Delete,
  _clear: actions.clear,
};

export default connect(mapStateToProps, mapActionToProps)(Purchases);
